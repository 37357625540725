import moment from "moment";
import { OrderStatus, EmptyOrder } from "../../utils/Consts";
import { authHeader } from "../../utils/AuthHeader";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { getShiftInfo } from "../../utils/Consts";

/**
 * @param {*} line Línea a explorar.
 * @param {*} leftTime Comienzo de la franja de tiempo.
 * @param {*} rightTime Fin de la franja de tiempo.
 * @deprecated Use getOrdersAndStops instead
 */
export async function getStopsAndOrdersInRange(
  line,
  leftTime,
  rightTime,
  includeOrder = true
) {
  console.warn("Use of deprecated function: getStopsAndOrdersInRange");

  let prevRequest = getPreviousStop(line, leftTime);
  let inRangeRequest = getStopsInRange(line, leftTime, rightTime);

  let prev = await prevRequest;
  let inRange = await inRangeRequest;

  let unproccessed = [...(prev ? [prev] : []), ...inRange];

  let orders = [];
  //Determina si incluye o no datos de las ordenes de esas paradas.
  if (includeOrder) {
    orders = await getOrdersOf(unproccessed);
  }

  //Crea una copia de las fechas originales
  if (typeof prev != "undefined") {
    prev.originalStartDate = prev.startDate;
    prev.originalEndDate = prev.endDate;
  }

  inRange = inRange.map(el => {
    return {
      ...el,
      originalStartDate: el.startDate,
      originalEndDate: el.endDate
    };
  });

  //Preparación:
  // A. Si hay parada sin finalizar dentro del rango, finaliza.
  // │   rango x───O │
  // B. Si está fuera del rango, trunca en right time.
  // │   rango x─┼─x
  let range = inRange.map(stop => {
    stop.endDate = stop.endDate || moment().format("YYYY-MM-DD HH:mm:ss");
    if (moment(stop.endDate).isAfter(moment(rightTime))) {
      stop.endDate = rightTime;
    }
    return stop;
  });

  if (prev == null) {
    return { orders: orders, stops: [...range] };
  }

  //Si no había finalizado aún, la finalizamos y recordamos esa operación.
  let finalized = prev.endDate == null;
  prev.endDate = prev.endDate || moment().format("YYYY-MM-DD HH:mm:ss");

  // Si finaliza fuera del rango: parada coincide con el rango.
  //  ──┼───rango───┼─x
  if (moment(prev.endDate).isAfter(moment(rightTime))) {
    prev.startDate = leftTime;
    prev.endDate = rightTime;
    return { orders: orders, stops: [prev] };
  }
  //Si finaliza dentro del rango, devuelvo parte coincidente.
  //  ──┼──O rango  │
  else if (
    moment(prev.endDate).isBetween(moment(leftTime), moment(rightTime), "[]")
  ) {
    prev.startDate = leftTime;
    return finalized
      ? //Si la habíamos finalizado nosotros, es la ultima parada.
        { orders: orders, stops: [prev] }
      : //Sino, agrego las del rango
        { orders: orders, stops: [prev, ...range] };
  }
  //Si finaliza antes del rango
  // ──O│   rango   │
  else if (moment(prev.endDate).isBefore(moment(leftTime))) {
    //Si era en curso, no hay paradas que ver
    return finalized
      ? { orders: orders, stops: [] }
      : { orders: orders, stops: [...range] };
  }
}

export async function getOrdersAndStopsAsync(
  line,
  leftTime,
  rightTime,
  includeOrder = false
) {
  let [prev, stops] = await Promise.all([
    getPreviousStop(line, leftTime),
    getStopsInRange(line, leftTime, rightTime)
  ])
    .then(data => data)
    .catch(err => console.log("Error in getOrdersAndStopsAsync", err));

  // Determina si incluye o no datos de las ordenes de esas paradas.
  // Por alguna razón esta función toma una lista de lista de paradas...
  // Después de estar corrigiendo el StopsManager durante 2 días
  // Y romper el StackedBar, no tengo ganas de mejorar esto. Emi <3
  // PD: Tampoco sé qué significa "unproccessed", pero funciona
  let unproccessed = [...(prev ? [prev] : []), ...stops];
  let orders = includeOrder ? await getOrdersOf(unproccessed) : [];

  if (!stops) return [];

  if (prev && moment(prev.startDate).isAfter(moment(leftTime)))
    stops.append(prev);

  stops = stops.map(stop => {
    stop.endDate = stop.endDate || moment().format("YYYY-MM-DD HH:mm:ss");

    if (moment(stop.endDate).isAfter(moment(rightTime)))
      stop.endDate = rightTime;

    return stop;
  });

  return { stops, orders };
}

/**
 * Obtiene las paradas de la orden activa de la línea
 * Incluye paradas huerfanas.
 * @param {*} line Línea a explorar.
 */
export async function getStopsCurrentOrder(line) {
  let order = await getCurrentOrder(line);

  if (!order) return { stops: [] };

  let stops = await getStopsInOrder(line, order && order.orderID);

  //Crea una copia de las fechas originales, compatibilidad con range.
  stops = stops.map(s => {
    return {
      ...s,
      originalStartDate: s.startDate,
      originalEndDate: s.endDate
        ? s.endDate
        : moment().format("YYYY-MM-DD HH:mm:ss")
    };
  });

  return { stops: stops };
}

/**
 * @sumary Obtiene las paradas que comiencen en el rango definido.
 */
function getStopsInRange(line, leftTime, rightTime) {
  let filter = [
    ["lineID", "=", line],
    "and",
    ["startDate", ">=", leftTime],
    "and",
    ["startDate", "<", rightTime]
  ];
  let formData = new FormData();
  formData.append("filter", JSON.stringify(filter));
  formData.append(
    "sort",
    JSON.stringify([
      {
        selector: "startDate",
        desc: false
      }
    ])
  );

  return fetch(`/api/stops/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data);
}

/**
 * @summary Obtiene la parada previa al rango.
 */
function getPreviousStop(line, leftTime) {
  let formData = new FormData();
  formData.append(
    "filter",
    JSON.stringify([["startDate", "<", leftTime], "and", ["lineID", "=", line]])
  );
  formData.append(
    "sort",
    JSON.stringify([{ selector: "startDate", desc: true }])
  );
  formData.append("take", 1);

  return fetch(`/api/stops/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data[0]);
}

/**
 * @sumary Obtiene las ordenes que comiencen en el rango definido.
 */
function getOrdersOf(stops) {
  let uniqueOrders = [...new Set(stops.map(s => s.order))];

  //Filtra las ordenes que aparecen en esas paradas.
  let filter = [].concat(
    ...uniqueOrders.map((order, index) => {
      if (index === 0) {
        //El primer elemento no lleva OR
        return [["orderID", "=", order]];
      } else {
        return ["or", ["orderID", "=", order]];
      }
    })
  );

  let formData = new FormData();
  formData.append("filter", JSON.stringify(filter));
  formData.append(
    "sort",
    JSON.stringify([{ selector: "startDate", desc: false }])
  );

  return fetch(`/api/orders?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data);
}

/**
 * @sumary Obtiene las paradas que comiencen en el rango definido.
 */
function getStopsInOrder(line, order) {
  let filter = [["lineID", "=", line], "and", [["order", "=", order]]];

  let formData = new FormData();
  formData.append("filter", JSON.stringify(filter));
  formData.append(
    "sort",
    JSON.stringify([
      {
        selector: "startDate",
        desc: false
      }
    ])
  );

  return fetch(`/api/stops/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data);
}

/**
 * @sumary Obtiene la orden actual de la línea.
 */
function getCurrentOrder(line) {
  let formData = new FormData();
  formData.append(
    "filter",
    JSON.stringify([
      ["lineID", "=", line],
      "and",
      ["status", "=", OrderStatus.Ready]
    ])
  );

  return fetch(`/api/orders?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data[0]);
}

/**
 * Funcion que actua como middleware de getStopsAndOrdersInRange y permite
 * obtener informacion en forma de pareto para las paradas.
 */
export function getParetoFromStopsInRange(stopsAndOrders) {
  let { stops } = stopsAndOrders;

  //Calcula duracion entre dos time strings.
  let getDuration = (start, end) => {
    let mEnd = end == null ? moment() : moment(end);
    return Math.abs(moment.duration(mEnd.diff(moment(start))).asMinutes());
  };

  let totalMinutes = 0;

  //Utiliza solo las marcadas para pareto.
  stops = stops.filter(s => s.pareto);

  // Calcula suma, count y agrupa datos.
  const reduced = stops.reduce((prev, next) => {
    let calculatedDuration = getDuration(
      next.originalStartDate,
      next.originalEndDate
    );
    totalMinutes += calculatedDuration;

    if (!prev[next.reason]) {
      prev[next.reason] = {
        ...next,
        quantity: 1,
        duration: calculatedDuration
      };
      return prev;
    }
    prev[next.reason].duration += calculatedDuration;
    prev[next.reason].quantity += 1;
    return prev;
  }, {});

  // Crea nuevo array y computa los porcentajes
  const result = Object.keys(reduced).map(k => {
    const item = reduced[k];
    return {
      reason: item.reason,
      quantity: item.quantity,
      duration: item.duration,
      percent: (item.duration * 100) / totalMinutes,
      color: item.color
    };
  });

  return result.sort((a, b) => b.duration - a.duration);
}

/**
 * Funcion para reconocimiento de paradas.
 */
export function recognizeStop(
  stop,
  reason,
  observation,
  username,
  password,
  onSuccess,
  onError
) {
  let formData = new FormData();
  formData.append("stopValues", JSON.stringify(stop));
  formData.append("reasonValues", JSON.stringify(reason));
  formData.append("username", username);
  formData.append("password", password);

  if (observation) formData.append("observation", observation);

  return fetch(`/api/stops/recognize?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "POST"
  })
    .then(r =>
      r.ok
        ? onSuccess(r.json())
        : r.text().then(text => {
            throw text;
          })
    )
    .catch(r => onError(r));
}

export function getRootPercent(stops, rootReasons, startDate, endDate) {
  if (!Array.isArray(stops) || !Array.isArray(rootReasons)) return 0;

  // Functions
  let getDuration = (start, end) => {
    let momentEnd = moment(end);
    momentEnd = momentEnd.isAfter(moment()) ? moment() : momentEnd;
    let momentStart = moment(start);
    momentStart = momentStart.isBefore(moment(startDate))
      ? moment(startDate)
      : momentStart;
    return Math.abs(moment.duration(momentEnd.diff(momentStart)).asMinutes());
  };

  // Initial values
  let totalRange = getDuration(startDate, endDate);
  let rootPercent = [];

  // Add top times
  stops.forEach(stop => {
    if (stop.recognized && stop.root) {
      let root = rootReasons.filter(x => x.reason === stop.root)[0];
      rootPercent[stop.root] = rootPercent[stop.root] || {
        reasonID: (root && root.reasonID) || 0,
        color: (root && root.color) || "red"
      };
      rootPercent[stop.root].time = rootPercent[stop.root].time
        ? rootPercent[stop.root].time +
          getDuration(stop.startDate, stop.endDate)
        : getDuration(stop.startDate, stop.endDate);
    } else {
      rootPercent["No reconocida"] = rootPercent["No reconocida"] || {
        reasonID: "unrecognized",
        color: "red"
      };
      rootPercent["No reconocida"].time = rootPercent["No reconocida"].time
        ? rootPercent["No reconocida"].time +
          getDuration(stop.startDate, stop.endDate)
        : getDuration(stop.startDate, stop.endDate);
    }
  });

  // Rest is runtime
  rootPercent["En marcha"] = {
    reasonID: "runtime",
    color: "#66D18A",
    time: Math.abs(
      totalRange -
        Object.values(rootPercent).reduce((acc, cur) => acc + cur.time, 0)
    )
  };

  // Calculate %
  for (let root in rootPercent) {
    rootPercent[root].value = (rootPercent[root].time * 100) / totalRange;
  }

  // Format before return
  return Object.keys(rootPercent).map(key => {
    const item = rootPercent[key];
    return {
      valueField: item.reasonID,
      name: key,
      value: item.value,
      color: item.color
    };
  });
}

export function reduceStopsIntoRootObject(
  stops,
  rootReasons,
  startDate,
  endDate
) {
  if (!Array.isArray(stops) || !Array.isArray(rootReasons)) return {};

  const now = moment();

  let getDuration = (start, end) => {
    let momentEnd = end ? moment(end) : now;
    let momentStart = moment(start);

    if (momentEnd.isAfter(now)) momentEnd = now;

    if (momentStart.isBefore(moment(startDate)))
      momentStart = moment(startDate);

    const duration = moment.duration(momentEnd.diff(momentStart)).asMinutes();

    return Math.abs(duration);
  };

  let values = {};

  for (let i in rootReasons) {
    const reason = rootReasons[i];
    const reasonStops = stops.filter(s => s.root === reason.valueField);

    values[reason.valueField] = reasonStops.reduce(
      (ac, stop) => ac + getDuration(stop.startDate, stop.endDate),
      0
    );
  }

  values["unrecognized"] = stops
    .filter(s => s.root == null)
    .reduce((ac, stop) => ac + getDuration(stop.startDate, stop.endDate), 0);

  const totalPeriod = getDuration(startDate, endDate);
  const totalStopTime = Object.entries(values).reduce(
    (ac, [_, value]) => ac + value,
    0
  );
  values["runtime"] = totalPeriod - totalStopTime;

  return values;
}

export async function splitStopAsync(
  { order, lineID, stopID, split },
  user,
  newStart,
  newEnd,
  onSuccess,
  onError
) {
  let formData = new FormData();
  formData.append("order", order);
  formData.append("lineID", lineID);
  formData.append("stopID", stopID);
  formData.append("split", split);
  formData.append("user", user);
  formData.append("newStart", newStart);
  formData.append("newEnd", newEnd);

  return fetch(`/api/stops/split?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "POST"
  })
    .then(r =>
      r.ok
        ? onSuccess(r.json())
        : r.text().then(text => {
            throw Error(text);
          })
    )
    .catch(r => onError(r));
}

export async function getRootStopReasons() {
  let filter = ["parentID", "=", "1"];
  let formData = new FormData();
  formData.append("filter", JSON.stringify(filter));

  return fetch(`/api/stopReasons/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data);
}

function getHeaders() {
  let headers = authHeader();
  headers["Content-Type"] = "application/x-www-form-urlencoded";
  return headers;
}

export function sectorsDxStore() {
  return createStore({
    key: "Value",
    loadUrl: `/api/sectors/lookup`,
    onBeforeSend: (_, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  });
}

export function linesDxStore(sector) {
  if (sector === null || typeof sector === "undefined") return null;

  return {
    store: createStore({
      key: "Value",
      loadUrl: `/api/resources/LookupResources`,
      onBeforeSend: (_, ajaxOptions) => {
        ajaxOptions.headers = authHeader();
      }
    }),
    filter: ["sectorID", "=", sector]
  };
}

export function productsDxStore(
  line,
  startDate,
  endDate,
  shift,
  includeOptionForAll = false
) {
  if (line == null || startDate == null || endDate == null || shift == null)
    return null;

  let shiftInfo = getShiftInfo(shift);
  startDate = moment(startDate)
    .hour(shiftInfo.start.hour)
    .minute(shiftInfo.start.minute)
    .second(0)
    .millisecond(0);
  endDate = moment(endDate)
    .hour(shiftInfo.end.hour)
    .minute(shiftInfo.end.minute)
    .second(0)
    .millisecond(0);
  if (
    shiftInfo.end.hour < shiftInfo.start.hour ||
    (shiftInfo.end.hour === shiftInfo.start.hour &&
      shiftInfo.end.minute <= shiftInfo.start.minute)
  )
    endDate.add(1, "day");

  let formData = new FormData();
  formData.append("line", line);
  formData.append("startDate", startDate.format());
  formData.append("endDate", endDate.format());
  formData.append("includeOptionForAll", includeOptionForAll);

  return createStore({
    key: "Value",
    loadUrl: `/api/products/lookupByLineAndDate?${new URLSearchParams(
      formData
    )}`,
    onBeforeSend: (_, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  });
}

export async function stopTypesDxStoreAsync(includeOptionForAll = false) {
  let formData = new FormData();
  formData.append("includeOptionForAll", includeOptionForAll);

  return fetch(`/api/stopreasons/rootlookup?${new URLSearchParams(formData)}`, {
    headers: authHeader(),
    method: "GET"
  }).then(response => response.json());
}
