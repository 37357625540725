import React from "react";

export function resolveNavigationPages(user, modules) {
  if (!user) return [];

  let navigationPages = [];
  /*Esperamos carga de modulos*/
  let loadedModules = Object.keys(modules).filter(
    key => modules[key].status === "loaded"
  );

  loadedModules.forEach(key => {
    let Component = window.modules[key];

    if (Component && Component.oee_navigation_pages) {
      Component = Component.oee_navigation_pages;
      var hasPermissions = Component.getPermissions(user.roleID);

      if (hasPermissions) {
        navigationPages.push({
          path: Component.getPath(),
          component: Component
        });
      }
    }
  });
  return navigationPages;
}

export function resolveNavigationItems(user, modules, props) {
  let navigationItems = [],
    stopItems = [];

  if (!user) return { navigationItems, stopItems };

  /*Esperamos carga de modulos*/
  let loadedModules = Object.keys(modules).filter(
    key => modules[key].status === "loaded"
  );

  loadedModules.forEach(key => {
    let Component = window.modules[key];

    //Navigation item
    if (Component && Component.oee_navigation_item) {
      Component = Component.oee_navigation_item;
      var hasPermissions = Component.getPermissions(user.roleID);

      if (hasPermissions) {
        navigationItems.push(<Component key={key} {...props} />);
      }
    }

    //Stop Navigation item
    if (Component && Component.oee_stop_navigation_item) {
      Component = Component.oee_stop_navigation_item;
      hasPermissions = Component.getPermissions(user.roleID);

      if (hasPermissions) {
        stopItems.push(<Component key={key} {...props} />);
      }
    }
  });
  return { navigationItems, stopItems };
}
