import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Input,
  Step,
  Icon,
  Button,
  Select,
  Segment,
  Message,
  Header
} from "semantic-ui-react";

const FirstStep = props => (
  <div>
    <Header
      as="h2"
      content="Selección de base de datos"
      subheader="Por favor, elija un provedor de base de datos."
    />
    <Select
      placeholder="Seleccionar"
      options={props.providers.map(e => {
        return { key: e, value: e, text: e };
      })}
      onChange={(e, select) => {
        props.onChange(select.value);
      }}
    />
  </div>
);

const SecondStep = props => (
  <div>
    <Header
      as="h2"
      content="Configuración"
      subheader="Por favor, ingrese la cadena de conexión."
    />
    <Input
      fluid
      placeholder="Cadena de conexión"
      onChange={(e, el) => {
        props.onChange(el.value);
      }}
    />
  </div>
);

const ThirdStep = props => (
  <div>
    <Header
      as="h2"
      content="Listo"
      subheader="La configuración fue completada con éxito. El sistema está reiniciando."
    />

    <Message>
      <Message.Header>Próximos pasos</Message.Header>
      <Message.List>
        <Message.Item>
          Utilice el usuario <b>setup</b> y contraseña
          <b> {props.password}</b> para identificarse (
          <Link to={"/login"}>Login.</Link>).
        </Message.Item>
        <Message.Item>
          Cree un usuario administrador y elimíne el usuario <b>setup.</b>{" "}
        </Message.Item>
      </Message.List>
    </Message>
    <Message negative hidden={!props.errores}>
      <Message.Header>
        Se produjeron errores durante la configuración
      </Message.Header>
      <p>{props.errors}</p>
    </Message>
  </div>
);

const StepIndex = props => (
  <Step.Group fluid attached="top">
    <Step
      active={props.step === Steps.SELECT_STEP}
      completed={
        props.step === Steps.CONFIG_STEP || props.step === Steps.FINISH_STEP
      }
    >
      <Icon name="database" />
      <Step.Content>
        <Step.Title>Base de datos</Step.Title>
        <Step.Description>Seleccione un proveedor de datos</Step.Description>
      </Step.Content>
    </Step>

    <Step
      active={props.step === Steps.CONFIG_STEP}
      completed={props.step === Steps.FINISH_STEP}
    >
      <Icon name="cogs" />
      <Step.Content>
        <Step.Title>Configuración</Step.Title>
        <Step.Description>Configure la base de datos</Step.Description>
      </Step.Content>
    </Step>
    <Step
      active={props.step === Steps.FINISH_STEP}
      completed={props.step === Steps.FINISH_STEP}
    >
      <Icon name="chart pie" />
      <Step.Content>
        <Step.Title>Finalizado</Step.Title>
        <Step.Description>Comienze a utilizar OEE</Step.Description>
      </Step.Content>
    </Step>
  </Step.Group>
);

const Steps = {
  SELECT_STEP: "select",
  CONFIG_STEP: "config",
  FINISH_STEP: "finish"
};

class Setup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      databaseSelected: "",
      connectionString: "",
      validationError: "",
      setupPending: true,
      loading: true,
      error: undefined,
      step: Steps.SELECT_STEP,
      information: { dataProviders: [] }
    };
  }

  onChangeDatabase(value) {
    this.setState({ databaseSelected: value });
  }
  onChangeConnectionString(value) {
    this.setState({ connectionString: value });
  }

  continue() {
    //First step
    if (this.state.step === Steps.SELECT_STEP) {
      //Required select provider
      if (!this.state.databaseSelected) return;
      //Next
      this.setState({ step: Steps.CONFIG_STEP });
      return;
    }

    //Second step
    if (this.state.step === Steps.CONFIG_STEP) {
      //Validate connection string
      this.setState({ loading: true });
      fetch("/api/setup/configure", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          databaseSelected: this.state.databaseSelected,
          connectionString: this.state.connectionString
        })
      })
        .then(response => response.json())
        .then(res => {
          if (res.valid)
            this.setState({
              loading: false,
              validationError: false,
              step: Steps.FINISH_STEP,
              backendErrors: res.error,
              dbCreated: res.created,
              temporalPassword: res.password
            });
          else this.setState({ loading: false, validationError: true });
        })
        .catch(error => this.setState({ loading: false, error: error }));
    }
  }

  componentDidMount() {
    fetch("/api/setup/information")
      .then(response => response.json())
      .then(data => this.setState({ loading: false, information: data }))
      .catch(error => this.setState({ loading: false, error: error }));
  }

  render() {
    //Prevent multiple configurations
    if (this.state.information.setupPending === false) {
      return (
        <Message info>
          <Message.Header>El sistema ya fue configurado.</Message.Header>
          <p>Contacte un administrador para realizar cambios.</p>
        </Message>
      );
    }
    return (
      <div style={{ padding: 30 }}>
        <Message negative hidden={!this.state.error}>
          <Message.Header>
            Tuvimos un problema para obtener información.
          </Message.Header>
          <p>Por favor, intentalo de nuevo más tarde.</p>
        </Message>
        <Message warning hidden={!this.state.validationError}>
          <Message.Header>
            No se pudo conectar a la base de datos.
          </Message.Header>
          <p>Por favor, verifique la cadena de conexión.</p>
        </Message>
        <StepIndex step={this.state.step} />

        <Segment
          padded
          attached
          style={{ minHeight: 400 }}
          loading={this.state.loading || this.state.error}
        >
          {/*Configuration steps*/}
          {this.state.step === Steps.SELECT_STEP && (
            <FirstStep
              providers={this.state.information.dataProviders}
              onChange={value => this.onChangeDatabase(value)}
            />
          )}
          {this.state.step === Steps.CONFIG_STEP && (
            <SecondStep
              providers={this.state.information.dataProviders}
              onChange={value => this.onChangeConnectionString(value)}
            />
          )}
          {this.state.step === Steps.FINISH_STEP && (
            <ThirdStep
              providers={this.state.information.dataProviders}
              errors={this.state.backendErrors}
              password={this.state.temporalPassword}
              created={this.state.created}
              onChange={value => this.onChangeDatabase(value)}
            />
          )}
        </Segment>
        {this.state.step !== Steps.FINISH_STEP && (
          <Segment clearing attached="bottom">
            <Button primary floated="right" onClick={this.continue.bind(this)}>
              Continuar
            </Button>
          </Segment>
        )}
      </div>
    );
  }
}

export default Setup;
