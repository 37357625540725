import { authHeader } from "../../utils/AuthHeader";

const oeeIndexUri = "/api/oeeindex";

/**
 * @sumary Obtiene resumen de producción de ordenes
 */
export function getProductionRange(line, start, end, product = null) {
  let formData = new FormData();

  formData.append("line", line);
  formData.append("start", start);
  formData.append("end", end);
  if (product != null) formData.append("product", product);

  return fetch(`/api/production/summary?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  }).then(response => response.json());
}

/**
 * @sumary Obtiene resumen de producción de la orden en curso en la línea.
 */
export async function getProductionCurrentOrder(line) {
  let formData = new FormData();

  //Where
  formData.append("line", line);

  return fetch(
    `/api/production/summary/order?${new URLSearchParams(formData)}`,
    {
      headers: getHeaders(),
      method: "GET"
    }
  ).then(response => response.json());
}

/**
 * @sumary Obtiene targets de resumen
 */
export async function getLineTargets() {
  return fetch(`/api/targets/get`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data);
}

export function getHeaders() {
  //Common values
  let headers = authHeader();
  headers["Content-Type"] = "application/x-www-form-urlencoded";

  return headers;
}

export async function adjustDiscountTimeAsync(
  order,
  discountTime,
  user,
  password,
  onSuccess,
  onError
) {
  let formData = new FormData();
  formData.append("order", order);
  formData.append("discountTime", discountTime);
  formData.append("user", user);
  formData.append("password", password);
  return fetch(`${oeeIndexUri}/adjust?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "PUT"
  })
    .then(r =>
      r.status === 200
        ? onSuccess(r.json())
        : r.text().then(text => {
            throw Error(text);
          })
    )
    .catch(r => onError(r));
}

export async function validateOrderAsync(
  order,
  validation,
  user,
  onSuccess,
  onError
) {
  let formData = new FormData();
  formData.append("order", order);
  formData.append("validation", validation);
  formData.append("user", user);
  return fetch(`${oeeIndexUri}/validate?${new URLSearchParams(formData)}`, {
    method: "PUT",
    headers: getHeaders()
  })
    .then(r =>
      r.status === 200
        ? onSuccess(r.json())
        : r.text().then(t => {
            throw Error(t);
          })
    )
    .catch(r => onError(r));
}

export function getProduct(productCode) {
  let formData = new FormData();

  formData.append("filter", JSON.stringify(["Code", "=", productCode]));

  return fetch(`/api/products/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  }).then(r => r.json());
}

export function getAlerts(lineid) {
  let formData = new FormData();
  formData.append("lineid", lineid);

  return fetch(`/api/alerts/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  }).then(r => r.json());
}

export function getHighPriorityAlert(lineid) {
  let formData = new FormData();
  formData.append("lineid", lineid);

  return fetch(`/api/alerts/first?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  }).then(r => r.json());
}

export async function recalculateAllOrders(user, password) {
  let formData = new FormData();
  formData.append("user", user);
  formData.append("password", password);
  return fetch(
    `/api/OeeIndex/RecalculateAllOrders?${new URLSearchParams(formData)}`,
    {
      headers: getHeaders(),
      method: "GET"
    }
  ).then(r =>
    r.status === 200
      ? r.json()
      : r.text().then(text => {
          throw Error(
            r.status === 401
              ? "Firma no válida"
              : "Could not recalculate orders"
          );
        })
  );
}
