import React, { Component } from "react";
import { Segment, Header } from "semantic-ui-react";
import {
  Column,
  FormItem,
  DataGrid,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  Lookup,
  RequiredRule,
  StringLengthRule
} from "devextreme-react/data-grid";
import { UserRoles } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

/**
 * OPC Server Types
 */
const types = ["UA", "DA", "API"];

export default class OPCServersConfig extends Component {
  render() {
    return (
      <Segment basic padded>
        <Header as="h2">
          <Trans>Configuración de servidores OPC</Trans>
          <Header.Subheader>
            <Trans>Actualiza los servidores OPC.</Trans>
          </Header.Subheader>
        </Header>
        <ServersDataGrid />
      </Segment>
    );
  }
}

OPCServersConfig.getMenuItem = () => <Trans>Servidores OPC</Trans>;
OPCServersConfig.getPermissions = userLevel =>
  userLevel === UserRoles.ADMINISTRATOR;

const url = "/api/opcservers";

const dataSource = createStore({
  key: "opcServerID",
  loadUrl: `${url}/get`,
  insertUrl: `${url}/post`,
  updateUrl: `${url}/put`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

class ServersDataGrid extends React.Component {
  render() {
    return (
      <DataGrid
        key={"opcServerID"}
        dataSource={dataSource}
        showBorders={true}
        style={{ maxHeight: 500, fontSize: "1rem" }}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        remoteOperations={true}
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={false} />
        <GroupPanel visible={true} />
        <Scrolling mode={"virtual"} />
        <Editing
          mode={"form"}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
          useIcons={true}
        />
        <Column
          dataField={"opcServerID"}
          caption={i18n()._(t`OPC Server ID`)}
          visible={false}
        >
          <FormItem visible={false} />
        </Column>
        <Column dataField={"name"} caption={i18n()._(t`Nombre`)}>
          <RequiredRule
            message={i18n()._(t`Debe definir un nombre del servidor`)}
          />
          <StringLengthRule
            max={100}
            message={i18n()._(
              t`El nombre debe tener como máximo 80 caracteres.`
            )}
          />
        </Column>
        <Column dataField={"description"} caption={i18n()._(t`Descripción`)}>
          <StringLengthRule
            max={80}
            message={i18n()._(
              t`La descripción debe tener como máximo 80 caracteres.`
            )}
          />
        </Column>
        <Column dataField={"hostName"} caption={i18n()._(t`Host Name`)}>
          <RequiredRule
            message={i18n()._(t`Debe definir un host name del servidor`)}
          />
          <StringLengthRule
            max={100}
            message={i18n()._(
              t`El host name debe tener como máximo 80 caracteres.`
            )}
          />
        </Column>
        <Column dataField={"serverType"} caption={i18n()._(t`Tipo`)}>
          <RequiredRule />
          <Lookup dataSource={types} />
        </Column>
        <Column
          dataField={"enabled"}
          caption={i18n()._(t`Habilitar OPC Server`)}
          dataType={"boolean"}
        ></Column>
        <Column dataField={"updatedBy"} caption={i18n()._(t`Actualizado por`)}>
          <FormItem visible={false} />
        </Column>
        <Column
          dataField={"updateDate"}
          dataType={"date"}
          format={"dd/MM/yyyy HH:mm:ss"}
          caption={i18n()._(t`Ultima actualizacion `)}
        >
          <FormItem visible={false} />
        </Column>
      </DataGrid>
    );
  }
}
