import React, { useState, useRef } from "react";
import { Segment, Header } from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import { StopReasonsTree } from "./Tree";
import { StopReasonsGrid } from "./Grid";
import { UserRoles } from "../../../utils/Consts";

//Stop reasons tab
const StopReasonsConfig = props => {
  const [treeVisible, setTreeVisible] = useState(true);
  const [lastChanges, setUpdate] = useState(null);

  var gridRef = useRef(null);

  // Contecto tree con grid
  const crudActions = {
    addNode: parent => gridRef.current.addNode(parent),
    addCategory: parent => gridRef.current.addCategory(parent),
    editNode: node => gridRef.current.editNode(node),
    deleteNode: node => gridRef.current.deleteNode(node)
  };

  //Grid update event
  const rowUpdated = timeStamp => setUpdate(timeStamp);

  return (
    <Segment basic>
      <Header as="h2">
        <Trans>Motivos de parada</Trans>
        <Header.Subheader>
          <Trans>Actualiza los motivos de parada disponibles.</Trans>
        </Header.Subheader>
      </Header>
      <br />
      <StopReasonsTree
        visible={treeVisible}
        lastChanges={lastChanges}
        crudActions={crudActions}
        changeMode={() => {
          // Reset filter on mode change
          gridRef.current.gridRef.current.instance.clearFilter();
          setTreeVisible(!treeVisible);
        }}
      />
      <StopReasonsGrid
        ref={gridRef}
        visible={!treeVisible}
        rowUpdated={rowUpdated}
      />
    </Segment>
  );
};

// Config pane information
StopReasonsConfig.getMenuItem = () => <Trans>Motivos de parada</Trans>;
StopReasonsConfig.getPermissions = userLevel =>
  userLevel === UserRoles.SUPERVISOR || userLevel === UserRoles.ADMINISTRATOR;

export default StopReasonsConfig;
