import React, { useState, Fragment } from "react";
import "devextreme-react/text-area";
import DataGrid, {
  Column,
  Export,
  FilterRow,
  FormItem,
  Lookup
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { Editing, HeaderFilter, Selection } from "devextreme-react/data-grid";
import { getValidationStage } from "../../utils/Consts";
import { RequiredRule } from "devextreme-react/form";
import { Segment, Button, Header, Icon, Confirm } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";
import { UserRoles } from "../../utils/Consts";
import { authHeader } from "../../utils/AuthHeader";
import { connect } from "react-redux";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { i18n } from "../i18n/components/ConnectProvider";

const productsSource = createStore({
  key: "Value",
  loadUrl: `/api/products/lookup`,
  onBeforeSend: (_, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const validationOrdersSource = createStore({
  key: "orderID",
  loadUrl: "/api/validatedOrder",
  deleteUrl: "/api/validatedOrder",
  insertUrl: "/api/validatedOrder",
  updateUrl: "/api/validatedOrder",
  onBeforeSend: (_, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const addRefreshButton = ({ component, toolbarOptions }) => {
  toolbarOptions.items.unshift({
    location: "after",
    widget: "dxButton",
    options: {
      icon: "refresh",
      onClick: () => component.refresh()
    }
  });
};

export default function OrdersValidate() {
  return (
    <Segment padded="very" style={{ margin: 20 }}>
      <Header as="h2">
        <Trans>Listado de Ordenes de validación</Trans>
        <Header.Subheader>
          <Trans>
            Visualiza las órdenes futuras que serán para validación.
          </Trans>
        </Header.Subheader>
      </Header>

      <ValidateOrdersGrid />
    </Segment>
  );
}

OrdersValidate.getPermissions = userLevel =>
  userLevel === UserRoles.SUPERVISOR ||
  userLevel === UserRoles.ADMINISTRATOR ||
  userLevel === UserRoles.CONSULTANT;

function mapStateToProps(state) {
  return { user: state.authentication.user };
}

const ValidateOrdersGrid = connect(mapStateToProps)(({ user }) => {
  const [selection, setSelection] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dataGrid, setDatagrid] = useState(null);
  const [isEdition, setIsEdition] = useState(false);

  const deleteRecords = () => {
    try {
      for (var i in selection) validationOrdersSource.remove(selection[i]);
      setSelection([]);
      dataGrid.instance.refresh();
      notify(i18n()._(t`Ordenes eliminadas exitosamente`), "success", 1500);
    } catch {
      notify(
        i18n()._(t`Ocurrió un problema al eliminar las ordenes`),
        "error",
        5000
      );
    }
  };

  const SelectionButtons = () => {
    if (user.roleID !== UserRoles.SUPERVISOR) return null;

    return (
      <div>
        <Button
          basic
          color="red"
          onClick={() => setConfirmOpen(true)}
          disabled={selection.length === 0}
        >
          <Icon name="trash alternate" />
          <Trans>Eliminar selección</Trans>
        </Button>

        <Button
          basic
          color="grey"
          disabled={selection.length === 0}
          onClick={() => {
            setSelection([]);
            dataGrid.instance.clearSelection();
          }}
        >
          <Icon name="undo" />
          <Trans>Limpiar Selección</Trans>
        </Button>
      </div>
    );
  };

  return (
    <Fragment>
      <SelectionButtons />
      <Confirm
        open={confirmOpen}
        dimmer="inverted"
        content={i18n()._(t`¿Desea eliminar las órdenes seleccionadas?`)}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => {
          setConfirmOpen(false);
          deleteRecords();
        }}
      />

      <DataGrid
        key={"orderID"}
        dataSource={validationOrdersSource}
        showBorders={true}
        remoteOperations={true}
        onEditingStart={() => setIsEdition(true)}
        onInitNewRow={() => setIsEdition(false)}
        onRowInserted={() =>
          notify(i18n()._(t`Orden creada exitosamente`), "success", 1500)
        }
        onRowUpdated={() =>
          notify(i18n()._(t`Orden modificada exitosamente`), "success", 1500)
        }
        ref={ref => setDatagrid(ref)}
        onSelectionChanged={e => setSelection(e.selectedRowKeys)}
        onToolbarPreparing={e => addRefreshButton(e)}
      >
        <Export
          enabled
          allowExportSelectedData={false}
          fileName="ORDENES_VALIDACION"
        />
        <Selection
          mode="multiple"
          allowSelectAll={true}
          selectAllMode={"allPages"}
          showCheckBoxesMode={"onClick"}
          enabled={true}
        />
        <FilterRow visible={true} applyFilter={true} />
        <HeaderFilter visible={true} />
        <Column
          key
          dataField="orderID"
          caption={i18n()._(t`Orden`)}
          editorOptions={{ disabled: isEdition }}
        >
          <FormItem colSpan={2} />
          <RequiredRule />
        </Column>
        <Column dataField="lot" caption={i18n()._(t`Lote`)}>
          <FormItem colSpan={2} />
          <RequiredRule />
        </Column>
        <Column dataField="productCode" caption={i18n()._(t`Producto`)}>
          <FormItem colSpan={2} />
          <Lookup
            dataSource={productsSource}
            allowClearing
            valueExpr={"value"}
            displayExpr={"text"}
          />
          <RequiredRule />
        </Column>
        <Column
          caption={i18n()._(t`Estado`)}
          allowEditing={false}
          calculateCellValue={e => getValidationStage(e.state)}
        >
          <FormItem visible={false} />
        </Column>
        <Editing
          mode="form"
          allowAdding={user.roleID === UserRoles.SUPERVISOR}
          allowUpdating={user.roleID === UserRoles.SUPERVISOR}
          useIcons={true}
        />
      </DataGrid>
    </Fragment>
  );
});
