import React, { Component, useState, useEffect, useRef } from "react";
import {
  Icon,
  Segment,
  Header,
  Breadcrumb,
  Modal,
  Button
} from "semantic-ui-react";
import {
  Column,
  DataGrid,
  FormItem,
  FilterRow,
  StringLengthRule,
  RequiredRule,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  Lookup
} from "devextreme-react/data-grid";
import { UserRoles, SystemStops } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { List } from "devextreme-react/list";
import * as DataSources from "../../api/DataSources";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    user: state.authentication.user
  };
};

export default class EquipmentsConfig extends Component {
  render = () => (
    <Segment basic padded>
      <Header as="h2">
        <Trans>Configuración de Equipos</Trans>
        <Header.Subheader>
          <Trans>Actualiza la información de los equipos del sistema.</Trans>
        </Header.Subheader>
      </Header>
      <EquipmentsGrid />
    </Segment>
  );
}

EquipmentsConfig.getMenuItem = () => <Trans>Equipos</Trans>;
EquipmentsConfig.getPermissions = userLevel =>
  userLevel === UserRoles.SUPERVISOR || userLevel === UserRoles.ADMINISTRATOR;

const EquipmentsGrid = connect(mapStateToProps)(props => {
  //Calculate stops on mount
  const [stops, setStops] = useState([]);
  useEffect(() => {
    DataSources.createStopReasonsDataSource()
      .load()
      .then(reasons => setStops(getPathStops(reasons)));
  }, []);

  return (
    <DataGrid
      key={"equipmentID"}
      dataSource={dataSource}
      showBorders={true}
      style={{ maxHeight: 500, fontSize: "1rem" }}
      allowColumnReordering={true}
      allowColumnResizing={true}
      rowAlternationEnabled={true}
      remoteOperations={true}
    >
      <FilterRow visible={true} />
      <HeaderFilter visible={false} />
      <GroupPanel visible={true} />
      <Scrolling mode={"virtual"} />
      <Editing
        mode={"form"}
        allowAdding={props.user.roleID === UserRoles.SUPERVISOR}
        allowDeleting={props.user.roleID === UserRoles.SUPERVISOR}
        allowUpdating={props.user.roleID === UserRoles.SUPERVISOR}
        useIcons={true}
      />
      <Column dataField={"equipmentID"} visible={false} key>
        <FormItem visible={false} />
      </Column>
      <Column dataField={"name"} caption={i18n()._(t`Nombre`)}>
        <RequiredRule message={i18n()._(t`Debe definir un nombre de Equipo`)} />
        <StringLengthRule
          max={40}
          message={i18n()._(t`El nombre debe tener como máximo 40 caracteres.`)}
        />
      </Column>
      <Column dataField={"description"} caption={i18n()._(t`Descripción`)}>
        <RequiredRule
          message={i18n()._(t`Debe definir una descripción del recurso`)}
        />
        <StringLengthRule
          max={100}
          message={i18n()._(
            t`La descripción debe tener como máximo 100 caracteres.`
          )}
        />
      </Column>
      <Column dataField={"tag"} caption={i18n()._(t`Tag`)}>
        <StringLengthRule max={48} />
      </Column>
      <Column dataField={"lineID"} caption={i18n()._(t`Linea`)}>
        <Lookup
          dataSource={resourcesSource}
          valueExpr={"value"}
          displayExpr={"text"}
        />
      </Column>
      <Column
        caption={i18n()._(t`Paradas asociadas`)}
        visible={false}
        editCellComponent={e => <EquipmentStops stops={stops} data={e.data} />}
      >
        <FormItem colSpan={2} label={{ visible: false }} />
      </Column>
    </DataGrid>
  );
});

const EquipmentStops = ({ stops, data }) => {
  const { equipmentID = null } = data;

  const gridRef = useRef();
  const [selectorVisible, showSelector] = useState(false);

  //Custom icon
  const customToolbar = ({ component, toolbarOptions }) =>
    (toolbarOptions.items = [
      {
        location: "after",
        widget: "dxButton",
        options: { icon: "add", onClick: () => showSelector(true) }
      }
    ]);

  const addStop = reason => {
    reason.equipmentID = equipmentID;
    dataSourceEquipmentStops()
      .store.insert(reason)
      .then(res => {
        showSelector(false);
        gridRef.current.instance.refresh();
      })
      .catch(error => {
        //Log error
        showSelector(false);
      });
  };

  return (
    <>
      <StopSelector
        visible={selectorVisible}
        stops={stops}
        onClose={() => showSelector(false)}
        onApply={addStop}
      />
      <DataGrid
        disabled={equipmentID == null}
        ref={gridRef}
        key={["equipmentID", "reasonID"]}
        dataSource={dataSourceEquipmentStops(equipmentID)}
        showBorders={true}
        onToolbarPreparing={customToolbar}
        style={{ maxHeight: 300, fontSize: "1rem" }}
        remoteOperations={true}
      >
        <Editing
          mode={"form"}
          allowAdding={false}
          allowDeleting={true}
          allowUpdating={false}
          useIcons={true}
        />
        <Column
          dataField={"reasonID"}
          caption={i18n()._(t`Paradas asociadas al equipo`)}
          alignment={"left"}
          cellComponent={({ data }) => (
            <StopPath stops={stops} id={data.reasonID} />
          )}
        />
      </DataGrid>
    </>
  );
};

const StopPath = ({ stops, id }) => {
  let path = [];
  try {
    path = stops.find(x => x.reasonID === id).path;
  } catch {}
  return <Breadcrumb icon="right angle" sections={path} />;
};

const StopSelector = ({ stops, visible, onClose, onApply }) => {
  const [selected, setSelected] = useState(null);

  const optionChanged = ({ name, value }) => {
    if (name === "selectedItemKeys") {
      let reason =
        typeof value !== "undefined" && typeof value[0] !== "undefined"
          ? value[0]
          : null;
      setSelected(reason);
    }
  };

  return (
    <div>
      <Modal
        dimmer="inverted"
        open={visible}
        onClose={onClose}
        centered={false}
      >
        <Modal.Header as="h2">Vincular equipo a parada</Modal.Header>
        <Modal.Content scrolling>
          <List
            dataSource={stops}
            height={300}
            onOptionChanged={optionChanged}
            selectionMode={"single"}
            selectAllMode={"allPages"}
            showSelectionControls={true}
            searchExpr="reason"
            searchEnabled={true}
            searchMode={"contains"}
            itemRender={({ path }) => (
              <Breadcrumb icon="right angle" sections={path} />
            )}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => onApply(selected)}>
            <Icon name="checkmark" />
            <Trans>Confirmar</Trans>
          </Button>
          <Button onClick={onClose}>
            <Icon name="delete" />
            <Trans>Cancelar</Trans>
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

//#region Datasources

const url = "/api/equipments";

const dataSource = createStore({
  key: "equipmentID",
  loadUrl: `${url}/get`,
  insertUrl: `${url}/post`,
  updateUrl: `${url}/put`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const resourcesSource = createStore({
  key: "Value",
  loadUrl: `/api/Resources/LookupResources`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const dataSourceEquipmentStops = equipment => ({
  store: createStore({
    key: ["reasonID", "equipmentID"],
    loadUrl: `${url}/stops`,
    insertUrl: `${url}/stops`,
    deleteUrl: `${url}/stops`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  }),
  filter: [["equipmentID", "=", equipment]]
});

//#endregion

//#region Path methods

//Obtiene path de una parada, especificando el reasonID.
const getParent = (stops, id) => {
  let { reasonID, parentID, reason } = stops.find(stop => stop.reasonID === id);
  if (reasonID !== SystemStops.RootCategory) {
    let sub = getParent(stops, parentID);
    sub.push(reason);
    return sub;
  } else return [];
};

//Obtiene listado de paradas con path incluido.
const getPathStops = stops => {
  //Iterate stops
  let paths = [];
  stops.forEach(stop => {
    if (stop.isCategory === false) {
      paths.push({
        reasonID: stop.reasonID,
        path: getParent(stops, stop.reasonID),
        reason: stop.reason
      });
    }
  });
  return paths;
};

//#endregion
