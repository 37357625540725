import { registerModule, loadedModule } from "./actions";
import store from "../../app/store";

export function register(moduleName, importPromise) {
  if (typeof window == "undefined") return;
  if (typeof window.modules == "undefined") window.modules = [];

  if (typeof window.loadingModules == "undefined") window.loadingModules = 0;

  store.dispatch(registerModule(moduleName));
  importPromise.then(resolved).catch(notResolved);
}

function resolved(module) {
  if (
    typeof window == "undefined" ||
    typeof window.loadingModules == "undefined"
  )
    return;

  window.modules[module.default.name] = module.default;
  store.dispatch(loadedModule(module.default.name));
}

function notResolved(message) {}
