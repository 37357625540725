import { terminalService } from "./service";
import store from "../../app/store";

export const terminalActions = {
  configureTerminal,
  resetTerminal
};

export const terminalConstants = {
  TERMINAL_CONFIGURE: "TERMINAL_CONFIGURE", //New configuration
  TERMINAL_RESET: "TERMINAL_RESET",
  TERMINAL_SET_PARAMETERS: "TERMINAL_SET_PARAMETERS"
};

/**
 * Request global oee paramters.
 * If TerminalSyncByIP is enabled => synchronize terminal
 */
function fetchGlobalConfiguration() {
  //store.dispatch({ type: loginConstants.LOGIN_REQUEST, username });
  terminalService.fetchParameters().then(
    parameters => {
      store.dispatch({
        type: terminalConstants.TERMINAL_SET_PARAMETERS,
        parameters
      });
      if (parameters.terminalSyncByIP) {
        synchronize();
      }
    },
    error => console.log("ERROR: TERMINAL_SET_PARAMETERS", error)
  );
}

function synchronize() {
  terminalService.synchronize().then(terminal => {
    //Se obtuvo informacion remota, configuro terminal
    if (terminal) {
      localStorage.setItem("terminal", JSON.stringify(terminal));
      store.dispatch({ type: terminalConstants.TERMINAL_CONFIGURE, terminal });
    } else {
      localStorage.removeItem("terminal");
      store.dispatch({ type: terminalConstants.TERMINAL_RESET });
    }
  });
}

function configureTerminal(lineID) {
  if (typeof lineID == "undefined") return;

  let snapshot = store.getState();
  let { terminal } = snapshot;

  // Sync activado
  let sync = terminal.globalParameters.terminalSyncByIP;
  terminalService
    .configureTerminal(lineID, sync)
    .then(response => response.json())
    .then(terminal => {
      localStorage.setItem("terminal", JSON.stringify(terminal));
      store.dispatch({ type: terminalConstants.TERMINAL_CONFIGURE, terminal });
    })
    .catch(error => {
      /**Manage error */
    });
}

function resetTerminal() {
  let snapshot = store.getState();
  let { terminal } = snapshot;
  let terminalID = terminal.terminal ? terminal.terminal.terminalID : null;

  //Eliminación local
  localStorage.removeItem("terminal");
  store.dispatch({ type: terminalConstants.TERMINAL_RESET });

  //Eliminación remota
  terminalService.resetTerminal(terminalID);
}

//At start
fetchGlobalConfiguration();
