import React, { Component } from "react";
import { Dropdown, Button } from "semantic-ui-react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { CodeEditor } from "./CodeEditor";

/**
 * @description Administra TAGS OPC. Puede mostrar un tag o 'Custom Script' si su valor es un calculation.
 * 1. Obtiene script, determina si está editado y extrae tags de header.
 * 2. Obtiene lista de tags para edición posterior.
 */
export default class TagManager extends Component {
  constructor(props) {
    super(props);

    //Parse & load script
    let script = this.props.data ? this.props.data : " ";
    let isEdited = this.isEdited(script);
    let tags = this.loadTags(script);
    script = this.loadScript(script);

    //State
    this.state = { tags: tags, allTags: [], script: script, edited: isEdited };

    //Data source
    this.dataSource = createStore({
      key: "tagID",
      loadUrl: `/api/tags/get`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = authHeader();
      }
    });
  }

  /**
   * Fetch server data.
   */
  componentDidMount() {
    this.dataSource.load().then(data => {
      let tags = data.map(i => ({
        key: i.tagID,
        text: i.tagName,
        value: i.tagName,
        type: i.tagType
      }));
      this.setState({
        allTags: tags
      });
    });
  }

  saveCode(script) {
    this.setState({ script, edited: true }, () => {
      let code = `//edited:true;\n//tags:[];\n${script}`;
      this.props.setValue(code);
    });
  }

  selectionChanged(selectedTags) {
    console.log(selectedTags);
    if (
      selectedTags === "custom" ||
      (Array.isArray(selectedTags) && selectedTags.includes("custom"))
    )
      return;
    if (selectedTags === "") {
      this.setState({ tags: [], script: "", edited: false });
      return;
    }
    let autoGenScript = this.autogenerateScript(
      selectedTags,
      this.props.resultType
    );
    let autoGenScriptWhitoutHeaders = this.loadScript(autoGenScript);
    //Set tags, update script and save.
    this.setState(
      {
        tags: selectedTags,
        edited: false,
        script: autoGenScriptWhitoutHeaders
      },
      () => {
        this.props.setValue(autoGenScript);
      }
    );
  }

  render() {
    let { edited, allTags, script, tags } = this.state;
    let selected = edited ? "custom" : tags;
    let options = edited
      ? [{ key: -1, text: "Custom script", value: "custom" }]
      : allTags;

    return (
      <Button size="mini" as="div" labelPosition="left" fluid>
        <Dropdown
          clearable={true}
          fluid
          selection
          multiple={!edited}
          search={false}
          options={options}
          value={selected}
          placeholder="Add tags"
          onChange={(e, { value }) => this.selectionChanged(value)}
        />
        <CodeEditor
          script={script}
          tags={allTags}
          onApply={script => this.saveCode(script)}
        />
      </Button>
    );
  }

  /**
   * Determina si el script está editado o es una lista de tags
   */
  isEdited(script) {
    return script.includes("//edited:true;");
  }

  /**
   * Obtiene script, eliminado comentarios de cabecera.
   */
  loadScript(script) {
    //Elimina cabecera
    script = script.replace(/\/\/edited:\b(?:true|false)\b;/, "");
    script = script.replace(/\/\/tags:\[(.*)\];/, "");
    return script.trimLeft();
  }

  /**
   * Si el script no está customizado, obtiene tags seleccionados.
   */
  loadTags(script) {
    try {
      if (!this.isEdited(script)) {
        var tags = script.substring(
          script.indexOf("//tags:") + 7,
          script.indexOf("]") + 1
        );
        return JSON.parse(tags);
      }
      return [];
    } catch (ex) {
      return [];
    }
  }

  /**
   * Autogenera el script para los tags seleccionados, segun el tipo de resultado esperado.
   */
  autogenerateScript(tags, resultType) {
    if (resultType === "boolean") {
      let header = `//edited:false;\n//tags:${JSON.stringify(tags)};\n`;
      let references = tags.map(t => `global[\"${t}\"].data`);
      return `${header}${references.join(" && ")}`;
    } else {
      let header = `//edited:false;\n//tags:${JSON.stringify(tags)};\n`;
      let references = tags.map(t => `global[\"${t}\"].data`);
      return `${header}${references.join(" + ")}`;
    }
  }
}
