import React, { useState } from "react";
import { authHeader } from "../../utils/AuthHeader";
import { Segment, Dropdown, Modal, Button, Message } from "semantic-ui-react";
import Editor from "react-simple-code-editor";
import Prism from "prismjs/components/prism-core";
import "prismjs/themes/prism.css";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import { Trans, t } from "@lingui/macro";

/**
 * @description Editor de scripts calculations.
 */
export const CodeEditor = ({ script = "", tags = [], onChange, onApply }) => {
  let [code, setCode] = useState(script);
  let [response, setResponse] = useState({ error: false, message: "" });

  console.log("RESPONSE", response);
  return (
    <>
      <ModalEditor
        onOpen={() => {
          setCode(script);
          setResponse({ error: false, message: "" });
        }}
        onApply={() => onApply(code)}
        onExec={() =>
          execJavascript(code)
            .then(res => setResponse(res))
            .catch(e => setResponse("Error"))
        }
      >
        <Segment basic>
          <TagsToolBox
            tags={tags}
            addTag={value => setCode(`${code} ${value}`)}
          />

          <EditBox value={code} onValueChange={value => setCode(value)} />
          <Message error={response.error} content={response.message} />
        </Segment>
      </ModalEditor>
    </>
  );
};

/**
 * @description Caja de edición de código.
 * @param {*} value valor inicial del código a mostrar.
 * @param {*} onValueChange invocado en cáda edición del código.
 */
const EditBox = ({ value, onValueChange }) => (
  <>
    <div style={styles.container}>
      <Editor
        value={value}
        onValueChange={value => {
          onValueChange(value);
        }}
        highlight={value =>
          Prism.highlight(
            value,
            Prism.languages.javascript,
            "language-javascript"
          )
        }
        padding={10}
        style={styles.editor}
      />
    </div>
  </>
);

/**
 *
 * @param {*} tags Herramienta para incorporar tags al código en forma de variable.
 */
const TagsToolBox = ({ tags, addTag }) => (
  <>
    <Dropdown text="Agregar Tag" icon="dropdown">
      <Dropdown.Menu>
        {tags
          .filter(e => e.key >= 0)
          .map((e, i) => (
            <Dropdown.Item
              onClick={(e, { text }) => addTag(`global[\"${text}\"].data`)}
              key={i}
              description={e.type}
              text={e.text}
            />
          ))}
      </Dropdown.Menu>
    </Dropdown>
  </>
);

/**
 *
 */
const ModalEditor = ({ onOpen, onClose, onApply, onExec, children }) => (
  <>
    <Modal
      trigger={
        <Button size="mini">
          <Trans>Editar</Trans>
        </Button>
      }
      onClose={onClose}
      onOpen={onOpen}
      closeIcon={true}
      closeOnDocumentClick={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header as="h2">Edición de script</Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        <Button color="blue" content="Ejecutar" onClick={onExec} />
        <Button positive content="Aplicar" onClick={onApply} />
      </Modal.Actions>
    </Modal>
  </>
);

/**
 * @description Ejecuta javascript en el backend.
 */
function execJavascript(code) {
  //Security
  var headers = authHeader();
  headers["Content-Type"] = "application/x-www-form-urlencoded";
  //Payload
  let formData = new FormData();
  formData.append(
    "values",
    JSON.stringify({
      script: code
    })
  );

  return fetch(`/api/tags/execute/`, {
    headers: headers,
    method: "POST",
    body: new URLSearchParams(formData)
  }).then(res => res.json());
}

//Inline styles
const styles = {
  message: { paddingLeft: 20, color: "red" },
  container: { overflowY: "scroll", maxHeight: 300 },
  editor: {
    border: "#9e9e9e63 1px solid",
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 12
  }
};
