import React, { Component } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import ContextMenu from "devextreme-react/context-menu";
import DefaultLayout from "./DefaultLayout";
import { i18n } from "../i18n/components/ConnectProvider";
import { Trans, t } from "@lingui/macro";

//Default values
const GridDefaultValues = {
  breakpoints: {
    lg: 1200,
    md: 700 /* 996 */,
    sm: 500 /* 768 */,
    xs: 300,
    xxs: 0
  },
  cols: { lg: 12, md: 12, sm: 6, xs: 6, xxs: 2 },
  layouts: DefaultLayout
};

/**
 * Layout que permite configurar la posición de los hijos y la
 * persiste en el local storage.
 */
class ConfigurableLayout extends Component {
  constructor(props) {
    super(props);

    //Preferencias del usuario
    let initialLayout =
      getFromLocalStorage("layouts") || GridDefaultValues.layouts;
    //Referencia a último layout aplicado
    this.editedLayout = initialLayout;
    //State
    this.state = { edit: false, items: {}, layouts: initialLayout };
    this.contextItems = {};

    let savedItems = getFromLocalStorage("items") || {};

    //Configura menú contextual
    React.Children.map(props.children, child => {
      let { optional, defaultValue, text } = child.props;
      if (optional) {
        this.state.items[child.key] =
          child.key in savedItems ? savedItems[child.key] : defaultValue;
        this.contextItems[child.key] = text;
      }
    });
  }

  /**
   * Maneja acciones del menú contextual.
   */
  menuItemClick(item) {
    item.component.hide();
    let action = item.itemData.key;
    let { edit, items } = this.state;

    switch (action) {
      case "edit":
        //Cuando bloquea la grilla guardo cambios.
        if (edit) {
          saveTolLocalStorage("layouts", this.editedLayout);
          this.setState({
            edit: !edit,
            layouts: this.editedLayout,
            items: { ...items }
          });
        } else {
          this.setState({ edit: !edit, items: { ...items } });
        }
        break;
      case "reset":
        this.setState({ layouts: GridDefaultValues.layouts }, () =>
          saveTolLocalStorage("layouts", GridDefaultValues.layouts)
        );
        break;
      default:
        this.setState({ items: { ...items, [action]: !items[action] } }, () => {
          saveTolLocalStorage("items", this.state.items);
        });
    }
  }

  /**
   * Genera los items del menu contextual basandose en el state actual
   * de los hijos opcionales.
   */
  getUpdatedMenuItems() {
    return [
      ...Object.keys(this.state.items).map(key => {
        let menuItem = this.contextItems[key];
        let menuIcon = this.state.items[key] ? "check" : null;
        return { key: key, text: menuItem, icon: menuIcon };
      }),
      {
        key: "edit",
        text: this.state.edit
          ? i18n()._(t`Bloquear componentes`)
          : i18n()._(t`Editar componentes`),
        beginGroup: true
      },
      { key: "reset", text: i18n()._(t`Restaurar configuración inicial`) }
    ];
  }

  layoutChange(layout) {
    let breakPoint = "sm",
      width = this.props.width;
    let { lg, md } = GridDefaultValues.breakpoints;

    width >= md && (breakPoint = "md");
    width >= lg && (breakPoint = "lg");

    this.editedLayout = { ...this.editedLayout, [breakPoint]: layout };
  }

  render() {
    /**Evita renderizar los hijos opcionales y desactivados */
    let childrens = [];
    React.Children.map(this.props.children, child => {
      if (!child.props.optional || this.state.items[child.key]) {
        childrens.push(child);
      }
    });

    return (
      <>
        <ContextMenu
          target={"#container-id"}
          items={this.getUpdatedMenuItems()}
          onItemClick={item => this.menuItemClick(item)}
        />
        <div id={"container-id"}>
          <Responsive
            onLayoutChange={e => this.layoutChange(e)}
            className="layout"
            width={this.props.width}
            rowHeight={30}
            /* verticalCompact={false} preventCollision={true} */
            layouts={this.state.layouts}
            breakpoints={GridDefaultValues.breakpoints}
            cols={GridDefaultValues.cols}
            isResizable={this.state.edit}
            isDraggable={this.state.edit}
          >
            {childrens}
          </Responsive>
        </div>
      </>
    );
  }
}

//#region Local Storage Helper
function getFromLocalStorage(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rglPreferences")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }

  return ls[key];
}

function saveTolLocalStorage(key, value) {
  if (global.localStorage) {
    let ls = {};
    try {
      ls = JSON.parse(global.localStorage.getItem("rglPreferences")) || {};
    } catch (e) {
      console.error("Can't load RGL preferences...");
    }
    global.localStorage.setItem(
      "rglPreferences",
      JSON.stringify({
        ...ls,
        [key]: value
      })
    );
  }
}
//#endregion

export default WidthProvider(ConfigurableLayout);
