import React, { useState, useEffect, useRef } from "react";
import { Label, Icon } from "semantic-ui-react";
import { TreeView } from "devextreme-react/tree-view";
import { SplitLayout, CommandMenu, loadResources } from "./Common";
import { Explorer } from "./Explorer";
import * as DataSources from "../../../api/DataSources";

const dataSources = {
  stopReasonsDataSource: DataSources.createStopReasonsDataSource(),
  resourcesLookupDataSource: DataSources.createResourcesLookupDataSource()
};

export const StopReasonsTree = ({
  changeMode,
  crudActions,
  lastChanges,
  visible
}) => {
  const treeRef = useRef();
  const [treeItems, setTreeItems] = useState([]);
  const [selectedItem, setSelected] = useState(null);
  const [menuOptions, setMenuOptions] = useState({});
  const [selectedLine, changeLine] = useState({
    lineID: -1,
    lineName: "Todas"
  });
  const [resources, setResources] = useState([]);

  //Linea seleccionada
  let { lineID, lineName } = selectedLine || {
    lineID: -1,
    lineName: "Todas"
  };

  //Carga inicial de datos
  useEffect(() => {
    loadResources(dataSources.resourcesLookupDataSource).then(res =>
      setResources(res)
    );
  }, []);

  useEffect(() => {
    dataSources.stopReasonsDataSource.load().then(res => {
      //Memoriza ultimo indice
      if (selectedItem && selectedItem.reasonID) {
        let editedItem = res.find(x => x.reasonID === selectedItem.reasonID);
        if (editedItem) {
          editedItem.expanded = true;
          editedItem.selected = true;
        }
      }
      setTreeItems(res);
    });
  }, [lastChanges]);

  //Ejecutado al seleccionar un item del tree
  const selectItem = ({ itemData, itemIndex }) => {
    if (itemData && selectedItem && itemData.reasonID === selectedItem.reasonID)
      return;

    //Sin selección, por defecto
    let newOptions = {
      allowChangeVisibility: false,
      allowEdit: false,
      allowAdd: false,
      allowDelete: false
    };

    treeItems.forEach(item => {
      if (item.selected) {
        //Only allow delete non-system cats
        newOptions.allowChangeVisibility = true;
        newOptions.allowDelete = !item.systemStop;
        newOptions.allowAdd = item.isCategory;
        newOptions.allowEdit = true;
      }
    });

    //Actualiza opciones del menú segun la selección activa
    setSelected(itemData);
    setMenuOptions(newOptions);
  };

  const enableNode = () => {
    //Obtiene en que líneas está visible el nodo
    if (Array.isArray(selectedItem.enabledForLine) === false) {
      try {
        //Si no hay línea seleccionada, es visible para todas
        selectedItem.enabledForLine = JSON.parse(selectedItem.enabledForLine);
      } catch (e) {
        selectedItem.enabledForLine = [];
      }
    }

    if (lineID === -1) {
      selectedItem.enabledForLine = [
        ...resources.filter(r => r.lineID).map(r => r.lineID)
      ];
    }
    //Hace visible solo para la línea seleccionada
    else if (selectedItem.enabledForLine.includes(lineID) === false) {
      selectedItem.enabledForLine.push(lineID);
    }
    selectedItem.enabledForLine = JSON.stringify(selectedItem.enabledForLine);
    //Update
    dataSources.stopReasonsDataSource
      .update(selectedItem.reasonID, selectedItem)
      .then(updatedItem => {
        let updtedItems = [...treeItems];
        var foundIndex = updtedItems.findIndex(
          x => x.reasonID === updatedItem.reasonID
        );
        updtedItems[foundIndex] = updatedItem;
        setTreeItems(updtedItems);
      });
  };

  const hideNode = () => {
    //Obtiene en que líneas está visible el nodo
    if (Array.isArray(selectedItem.enabledForLine) === false) {
      try {
        selectedItem.enabledForLine = JSON.parse(selectedItem.enabledForLine);
      } catch (e) {
        selectedItem.enabledForLine = [];
      }
    }

    //Oculta para todas las líneas
    if (lineID === -1) {
      selectedItem.enabledForLine = [];
    }
    //Oculta solo para la línea seleccionada
    else if (selectedItem.enabledForLine.includes(lineID)) {
      let lineIndex = selectedItem.enabledForLine.findIndex(x => x === lineID);
      delete selectedItem.enabledForLine[lineIndex];
    }

    selectedItem.enabledForLine = JSON.stringify(selectedItem.enabledForLine);
    //Update
    dataSources.stopReasonsDataSource
      .update(selectedItem.reasonID, selectedItem)
      .then(updatedItem => {
        let updtedItems = [...treeItems];
        var foundIndex = updtedItems.findIndex(
          x => x.reasonID === updatedItem.reasonID
        );
        updtedItems[foundIndex] = updatedItem;
        setTreeItems(updtedItems);
      });
  };

  return (
    <SplitLayout>
      <SplitLayout.First>
        <CommandMenu
          data={{ treeMode: visible, resources, lineName }}
          actions={{
            changeMode,
            changeLine,
            enableNode,
            hideNode,
            addNode: () => crudActions.addNode(selectedItem),
            addCategory: () => crudActions.addCategory(selectedItem),
            editNode: () => crudActions.editNode(selectedItem),
            deleteNode: () => crudActions.deleteNode(selectedItem)
          }}
          options={menuOptions}
        />
        <TreeView
          visible={visible}
          style={{
            visibility: visible ? "visible" : "hidden",
            marginTop: 20
          }}
          id={"simple-treeview"}
          ref={treeRef}
          itemComponent={i => <Item {...i} currentLine={lineID} />}
          items={treeItems}
          onItemSelectionChanged={item => selectItem(item)}
          dataStructure={"plain"}
          displayExpr={"name"}
          parentIdExpr={"parentID"}
          keyExpr={"reasonID"}
          selectNodesRecursive={true}
          selectionMode={"single"}
          selectByClick={true}
          showCheckBoxesMode={"none"}
          width={300}
          onItemClick={e => {}}
        />
      </SplitLayout.First>
      {visible && (
        <SplitLayout.Second>
          <Explorer tree={treeItems} currentLine={lineID} />
        </SplitLayout.Second>
      )}
    </SplitLayout>
  );
};

/**
 * Template del item de vista de Árbol
 */
const Item = ({ enabledForLine, currentLine, reason, color, systemStop }) => {
  //Parsea o inicializa
  if (Array.isArray(enabledForLine) === false) {
    try {
      enabledForLine = JSON.parse(enabledForLine);
    } catch (e) {
      enabledForLine = [];
    }
  }
  //Estoy viendo todas las líneas
  if (typeof currentLine === "undefined" || currentLine === -1) {
    return (
      <div>
        <Label size="tiny" circular style={{ backgroundColor: color }} empty />
        {reason}
      </div>
    );
  }
  //Procesa visibilidad
  let visible = enabledForLine.includes(currentLine) || systemStop;
  return (
    <div>
      {!visible && <Icon name="eye slash outline" color="grey" />}
      <Label size="tiny" circular style={{ backgroundColor: color }} empty />
      {reason}
    </div>
  );
};
