import React from "react";
import { Form, TextArea } from "semantic-ui-react";

export const UserSign = ({ data, setData, user, reason = true }) => {
  const reasonLength = 40;
  return (
    <>
      {reason && (
        <>
          <Form.Field>
            <label>Motivo</label>
            <TextArea
              value={data.reason}
              onChange={(_, { value }) => setData({ ...data, reason: value })}
              rows={2}
              placeholder="Motivo"
            />
            <span
              style={{
                color: data.reason.length > reasonLength ? "red" : "grey"
              }}
            >
              {`Utilizados ${data.reason.length} de ${reasonLength} caracteres.`}
            </span>
          </Form.Field>
        </>
      )}
      <Form.Field>
        <label>Usuario</label>
        <input
          value={user ? user : undefined}
          disabled={user ? true : false}
          placeholder="Usuario"
          autoComplete="username"
          onChange={e => setData({ ...data, username: e.target.value.trim() })}
        />
      </Form.Field>
      <Form.Field>
        <label>Contraseña</label>
        <input
          type="password"
          placeholder="Contraseña"
          autoComplete="current-password"
          onChange={e => setData({ ...data, password: e.target.value.trim() })}
        />
      </Form.Field>
    </>
  );
};
