import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Grid,
  Form,
  Button,
  Segment,
  Message,
  Menu,
  Dropdown,
  Image
} from "semantic-ui-react";
import { loginActions } from "./actions";
import { setLanguage } from "../i18n/actions";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

const Login = ({ loggingIn, loggedIn, error, setLanguage }) => {
  useEffect(() => {
    document.title = "OEE++ Login";
  }, []);
  const [state, setState] = useState({
    username: "",
    password: "",
    submitted: false
  });

  const handleChange = ({ target }) =>
    setState({ ...state, [target.name]: target.value.trim() });

  const handleSubmit = e => {
    e.preventDefault();
    setState({ ...state, submitted: true });
    if (state.username && state.password) {
      loginActions.login(state.username, state.password);
    }
  };

  const { submitted, username, password } = state;

  if (loggedIn) return <Redirect to="/" push />;

  return (
    <>
      <Dropdown
        item
        simple
        direction="left"
        icon="language"
        style={{ position: "fixed", top: 20, right: 30 }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={(e, data) =>
              setLanguage(data.children.toString().toLowerCase())
            }
          >
            ES
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e, data) =>
              setLanguage(data.children.toString().toLowerCase())
            }
          >
            EN
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Grid centered className="middle aligned" style={{ height: "100vh" }}>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <Segment loading={loggingIn}>
            <Menu borderless secondary stackable>
              <Menu.Item
                className="logo borderless fitted"
                style={{ padding: "10px 10px 10px 0px" }}
              >
                <Image src="/Argensun-new.png" style={{ height: 36 }} />
              </Menu.Item>
            </Menu>
            <Message
              negative
              hidden={!(submitted && (!username || !password || error))}
            >
              <Message.Header>
                <Trans>Error</Trans>
              </Message.Header>
              <Message.List>
                {submitted && !username && (
                  <Message.Item>
                    <Trans>El campo nombre de usuario es obligatorio.</Trans>
                  </Message.Item>
                )}
                {submitted && !password && (
                  <Message.Item>
                    <Trans>El campo contraseña es obligatorio.</Trans>
                  </Message.Item>
                )}
                {error && <Message.Item>{error}</Message.Item>}
              </Message.List>
            </Message>
            <Form onSubmit={handleSubmit}>
              <Form.Field>
                <label>
                  <Trans>Nombre de usuario</Trans>
                </label>
                <input
                  placeholder={i18n()._(t`Nombre`)}
                  name="username"
                  onChange={handleChange}
                  autoComplete="username"
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <Trans>Contraseña</Trans>
                </label>
                <input
                  type="password"
                  placeholder={i18n()._(t`Contraseña`)}
                  name="password"
                  onChange={handleChange}
                  autoComplete="current-password"
                />
              </Form.Field>
              <Form.Field>
                <Button
                  style={{ backgroundColor: "#1C4257", color: "white" }}
                  fluid
                  type="submit"
                >
                  <Trans>Continuar</Trans>
                </Button>
              </Form.Field>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

const mapStateToProps = state => ({
  loggingIn: state.authentication.loggingIn,
  loggedIn: state.authentication.loggedIn,
  error: state.authentication.error
});

const mapDispatchToProps = dispatch => ({
  setLanguage: language => dispatch(setLanguage(language))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
