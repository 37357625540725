import { useEffect, useState } from "react";
import packageJson from "../../package.json";

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    if (a === b) continue;
    else if (a < b) console.warn("Version mismatch, web downgraded?");
    return a !== b || isNaN(b);
  }

  return false;
};

export const refreshCacheAndReload = () => {
  console.log("Clearing cache and hard reloading");

  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(names => {
      for (let name of names) caches.delete(name);
    });

    // Deletes browser cache and reloads
    window.location.reload();
  }
};

const CacheBuster = props => {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(true);

  useEffect(() => {
    fetch("/meta.json", {cache: "reload"})
      .then(response => response.json())
      .then(meta => {
        if (meta == null) {
          console.warn("Could not verify version: no response");
          setIsLatestVersion(true);
        }

        const shouldForceRefresh = semverGreaterThan(
          meta.version,
          global.appVersion
        );

        if (shouldForceRefresh) {
          console.warn("New version available, refreshing website");
          setIsLatestVersion(false);
        } else {
          console.log("No new version found");
          setIsLatestVersion(true);
        }
      })
      .catch(err => console.error("Could not verify version: error", err))
      .finally(() => setLoading(false));
  }, []);

  return props.children({ loading, isLatestVersion });
};

export default CacheBuster;
