import React, { useEffect, useState, useRef } from "react";
import UnrecognizedStops from "./UnrecognizedStops";
import moment from "moment";
import { Segment, Header, Button } from "semantic-ui-react";
import { StackedBar } from "./StackedBar";
import { Trans, t } from "@lingui/macro";
import { getShiftLimits } from "../../utils/ShiftHelper";
import { getOrdersAndStopsAsync, getStopsAndOrdersInRange } from "./service";
import { i18n } from "../i18n/components/ConnectProvider";

import "react-circular-progressbar/dist/styles.css";

export const StopsExplorer = ({ line, lastUpdate, onRecognized, navigate }) => {
  const unrecognized = useRef();
  const [recounter, recognize] = useState(0);
  const [date, setDate] = useState(moment());
  const [data, setData] = useState({
    stops: [],
    orders: []
  });
  const { start, end } = getShiftLimits(date);
  const [unrecognizedStops, setUnrecognizedStops] = useState(0);

  useEffect(() => {
    getStopsAndOrdersInRange(line, start, end).then(data =>
      setData({ stops: data.stops, orders: data.orders })
    );
  }, [lastUpdate, line, start, end, recounter]);

  useEffect(() => {
    if (typeof navigate !== "undefined") {
      navigate(date);
    }
  }, [line, start]);

  const changeDay = day => {
    //Evito ir mas allá del turno actual.
    const { end } = getShiftLimits(moment());
    if (day === 1 && Math.abs(date.diff(moment(end), "hours")) <= 8) {
      return;
    }
    let newDate = day === 0 ? moment() : date.clone().add(day * 8, "h");

    setDate(newDate);
  };

  const onReco = (s, r) => {
    if (typeof onRecognized !== "undefined") onRecognized(s, r);
    recognize(recounter + 1);
  };

  //Si es el mismo dia
  let showDay = !moment(start).isSame(moment(), "day");
  let day = moment(start).format("DD/MM");
  let time = `${moment(start).format("HH:mm")} - ${moment(end).format(
    "HH:mm"
  )}`;
  time = showDay ? `${time}  (${day})` : time;

  return (
    <>
      <Segment
        style={{ position: "relative", height: "100%", overflowY: "auto" }}
      >
        <Header as="h5">
          <Trans>Explorador de paradas ({unrecognizedStops})</Trans>
          <span style={{ marginLeft: "10px", color: "gray" }}>{time}</span>
        </Header>
        <TurnNavigator changeDay={changeDay} />
        <StackedBar
          fetching={false}
          leftTime={start}
          rightTime={end}
          stopsData={data.stops}
          ordersData={data.orders}
          onRecognize={stop => unrecognized.current.showRecognize(stop)}
        />
        <UnrecognizedStops
          maxHeight={"auto"}
          counter={setUnrecognizedStops}
          fetching={false}
          ref={unrecognized}
          line={line}
          onRecognized={onReco}
        />
      </Segment>
    </>
  );
};

const TurnNavigator = ({ changeDay }) => {
  return (
    <>
      <Button.Group
        style={{ position: "absolute", top: 12, right: 12 }}
        size="tiny"
      >
        <Button icon="angle left" onClick={() => changeDay(-1)} />
        <Button content={i18n()._(t`Ahora`)} onClick={() => changeDay(0)} />
        <Button icon="angle right" onClick={() => changeDay(1)} />
      </Button.Group>
    </>
  );
};
