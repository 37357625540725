import React from "react";
import {
  Column,
  DataGrid,
  FormItem,
  FilterRow,
  StringLengthRule,
  RequiredRule,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  Lookup
} from "devextreme-react/data-grid";
import * as DataSources from "../../../api/DataSources";
import ColorBox from "devextreme-react/color-box";
import notify from "devextreme/ui/notify";

//Datasource
const reasonsDataSource = DataSources.createStopReasonsDataSource();
const categoriesDataSource = {
  store: DataSources.createStopReasonsDataSource(),
  filter: [["isCategory", "=", true]]
};

export class StopReasonsGrid extends React.Component {
  gridRef = React.createRef();

  //#region External API
  select(node) {
    let selectedRow = this.getRowIndexByKey(node.reasonID);
    this.gridRef.current.instance.selectRows(selectedRow);
  }

  addNode(parent) {
    this.gridRef.current.instance.option(
      "editing.popup.title",
      "Agregar parada"
    );
    this.gridRef.current.instance.option("onInitNewRow", e => {
      e.data.isCategory = false;
      e.data.parentID = this.props.visible ? undefined : parent.reasonID;
    });
    this.gridRef.current.instance.addRow();
    this.gridRef.current.instance.option("onInitNewRow", undefined);
  }

  addCategory(parent) {
    this.gridRef.current.instance.option(
      "editing.popup.title",
      "Agregar categoría"
    );
    this.gridRef.current.instance.option("onInitNewRow", e => {
      e.data.isCategory = true;
      e.data.parentID = this.props.visible ? undefined : parent.reasonID;
    });
    this.gridRef.current.instance.addRow();
    this.gridRef.current.instance.option("onInitNewRow", undefined);
  }

  editNode(node) {
    let selectedKey;

    if (this.props.visible) {
      selectedKey = this.getSelectedRowKeys();
    } else {
      selectedKey = node.reasonID;
      // Se fuerza la grilla cargue la row a editar
      this.gridRef.current.instance.filter(["ReasonID", "=", node.reasonID]);
    }

    // Parche que espera la grilla cargue el row antes de editar
    setTimeout(() => {
      let selectedRow = this.getRowIndexByKey(selectedKey);

      /*Si hay selección para editar, configura titlulo de popup*/
      if (selectedRow && selectedRow >= 0) {
        let editNode = this.props.visible ? this.getSelectedRowData() : node;
        let popupTitle = editNode.isCategory
          ? "Editar categoría"
          : "Editar parada";
        this.gridRef.current.instance.option("editing.popup.title", popupTitle);
      }
      this.gridRef.current.instance.editRow(selectedRow);
    }, 300);
  }

  deleteNode(node) {
    let selectedKey;

    if (this.props.visible) {
      selectedKey = this.getSelectedRowKeys();
    } else {
      selectedKey = node.reasonID;
      // Se fuerza la grilla cargue la row a eliminar
      this.gridRef.current.instance.filter(["ReasonID", "=", node.reasonID]);
    }

    // Parche que espera la grilla cargue el row antes de eliminar
    setTimeout(() => {
      let selectedRow = this.getRowIndexByKey(selectedKey);
      this.gridRef.current.instance.deleteRow(selectedRow);
    }, 300);
  }
  //#endregion

  //#region Helpers
  getSelectedRowKeys = () =>
    this.gridRef.current.instance.getSelectedRowKeys()[0];
  getRowIndexByKey = key => this.gridRef.current.instance.getRowIndexByKey(key);
  getSelectedRowData = () =>
    this.gridRef.current.instance.getSelectedRowsData()[0];
  //#endregion

  render() {
    return (
      <>
        <DataGrid
          style={{
            visibility: this.props.visible ? "visible" : "hidden",
            maxHeight: 500,
            fontSize: "1rem"
          }}
          ref={this.gridRef}
          key={"reasonID"}
          onRowUpdated={() => this.props.rowUpdated(new Date())}
          onRowRemoved={() => this.props.rowUpdated(new Date())}
          onRowInserted={() => this.props.rowUpdated(new Date())}
          onContentReady={e =>
            e.component.columnOption("command:edit", "visible", false)
          }
          dataSource={reasonsDataSource}
          showBorders={true}
          onDataErrorOccurred={e => notify(e.error, "error", 1500)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          hoverStateEnabled={true}
          selection={{ mode: "single" }}
          remoteOperations={true}
          onToolbarPreparing={e => {
            var toolbarItems = e.toolbarOptions.items;
            toolbarItems.forEach(item => {
              if (
                item.name === "saveButton" ||
                item.name === "revetButton" ||
                item.name === "addRowButton"
              ) {
                // Change the item options here
                item.visible = false;
              }
            });
          }}
          onEditorPreparing={e => {
            if (e.parentType === "dataRow") {
              //Categoría no editable
              if (e.dataField === "isCategory") {
                e.editorOptions.disabled = true;
                return;
              }
              //Si se edita una parada de sistema habilita solo cambio de color, razón.
              if (e.row.data.systemStop) {
                e.editorOptions.disabled =
                  e.dataField !== "color" && e.dataField !== "pareto";
              }
            }
          }}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={false} />
          <GroupPanel visible={true} />
          <Scrolling mode={"virtual"} />
          <Editing
            mode={"popup"}
            allowAdding={true}
            allowDeleting={true}
            allowUpdating={true}
            useIcons={true}
            popup={{ height: "auto", showTitle: true }}
          />
          <Column dataField={"reasonID"} caption={"ID"} visible={true} key>
            <FormItem visible={false} />
          </Column>
          <Column dataField={"parentID"} caption={"Categoría"} visible={true}>
            <RequiredRule message={"Debe seleccionar una categoría."} />
            <Lookup
              dataSource={categoriesDataSource}
              allowClearing
              valueExpr={"reasonID"}
              displayExpr={"reason"}
            />
          </Column>
          <Column dataField={"reason"} caption={"Motivo"}>
            <RequiredRule message={"Debe especificar el motivo."} />
            <StringLengthRule
              max={80}
              message={"El motivo debe tener como máximo 80 caracteres."}
            />
          </Column>
          <Column dataField={"detail"} caption={"Detalle"}>
            <StringLengthRule
              max={255}
              message={"El detalle debe tener como máximo 255 caracteres."}
            />
          </Column>
          <Column
            dataField={"pareto"}
            dataType={"boolean"}
            caption={"Pareto"}
          ></Column>
          <Column
            dataField={"discountOEE"}
            dataType={"boolean"}
            caption={"Descuenta OEE"}
          ></Column>
          <Column
            dataField={"isCategory"}
            dataType={"boolean"}
            caption={"Categoría"}
          ></Column>
          <Column
            dataField={"color"}
            caption={"Color"}
            editCellComponent={e => (
              <ColorBox
                defaultValue={e.data.color}
                onValueChanged={x => e.setValue(x.value)}
              />
            )}
          ></Column>
          <Column dataField={"updatedBy"} caption={"Actualizado por"}>
            <FormItem visible={false} />
          </Column>
          <Column
            dataField={"updateDate"}
            dataType={"date"}
            format={"dd/MM/yyyy HH:mm:ss"}
            caption={"Ultima actualización "}
          >
            <FormItem visible={false} />
          </Column>
        </DataGrid>
      </>
    );
  }
}
