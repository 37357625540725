import React from "react";
import moment from "moment";
import { Chart } from "devextreme-react";
import { ArgumentAxis } from "devextreme-react/chart";
import { WidthProvider } from "react-grid-layout";

/**
 * Componente que permite visualizar las paradas en un intervalo de tiempo especificado.
 * Si los datos provistos no respetan el formato, o bien exceden el intervalo de tiempo
 * esepcficiado, el componente renderiza un mensaje de error.
 * Los datos deben estar ordenados en orden ascendente.
 */
export const StackedBar = ({
  leftTime,
  rightTime,
  stopsData,
  ordersData,
  onRecognize,
  fetching
}) => {
  let stopsAndBlanks = [];
  //let errorOccurred = undefined;
  /**
   * Intercala espacios en blanco para indicar la marcha en el stackbar.
   */
  try {
    stopsAndBlanks = intercaleStopsAndBlanks(leftTime, rightTime, stopsData);
  } catch (error) {
    //Agregar reporte a backend de este tipo de error, logueando
    //Que paradas estan involucradas en el error.
    console.error(
      "Checksum error en stacked bar",
      leftTime,
      rightTime,
      stopsData
    );
    //errorOccurred = error;
  }

  /**
   * Adpatación de stops para el formato esperado por dxChart.
   */
  let dataSource = stopsAndBlanks.map(v => ({
    key: v.key,
    arg: "stops",
    value: v.duration,
    name: v.reason
  }));

  let colors = [];
  stopsAndBlanks.forEach(v => {
    colors[v.reason] = v.color;
  });

  /**Loading, clear previous data*/
  if (fetching) {
    //dataSource = {}
  }

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", width: "100%" }}>
        <Chart
          loadingIndicator={{ show: false }}
          size={{ height: 50 }}
          rotated={true}
          dataSource={dataSource}
          legend={{ visible: false }}
          valueAxis={{ label: { visible: false }, grid: { visible: false } }}
          commonSeriesSettings={{ valueField: "value", type: "stackedBar" }}
          seriesTemplate={{ nameField: "key" }}
          animation={false}
          tooltip={getToolTipConfiguration(stopsAndBlanks, ordersData)}
          customizePoint={point => getCustomizedPoint(point, colors)}
          onPointClick={point =>
            onPointClick(point, stopsAndBlanks, onRecognize)
          }
        >
          <ArgumentAxis label={{ visible: false }} />
        </Chart>
      </div>

      <ShiftIndicators leftTime={leftTime} rightTime={rightTime} />
    </div>
  );
};

/**
 * Adapta la información obtenida en stacked bar, agregando periodos de tiempo
 * para completar la infromación necesaria por dxChart.
 * Si la suma de los intervalos de tiempo parcial excede el tiempo total del rango
 * arroja una excepción, informando la inconsistencia de la información.
 *
 * @param {*} leftTime Limite inicial del rango de tiempo.
 * @param {*} rightTime Limite final del rango de tiempo.
 * @param {*} stopsData Listado de paradas a adaptar.
 */
function intercaleStopsAndBlanks(leftTime, rightTime, stopsData) {
  let right = moment(rightTime);
  let left = moment(leftTime);

  //Calcula la duración total del rango
  var rangeDuration = moment.duration(right.diff(left));
  var rangeMinutes = rangeDuration.asMinutes();

  //Calcula la duración de cada parada en el rango observado.
  let stops = stopsData.map(v => {
    var stopDuration = moment.duration(
      moment(v.endDate).diff(moment(v.startDate))
    );
    var stopMinutes = stopDuration.asMinutes();
    v.duration = (stopMinutes * 100) / rangeMinutes;
    return v;
  });

  //Intercala espacios de tiempo en blanco para representar entre paradas.
  let key = 0;
  let checksum = 0;
  let stopsAndBlanks = [].concat(
    ...stops.map((x, index, arr) => {
      //Obtiene item anterior o en la primer vuelta obtiene limite izquierdo.
      let prev = index === 0 ? { endDate: leftTime } : arr[index - 1];

      //Si tenemos item anterior
      if (!prev) return [];

      let diff = moment
        .duration(moment(prev.endDate).diff(moment(x.startDate)))
        .asMinutes();
      let diffPercent = Math.abs((diff * 100) / rangeMinutes);

      //Acumulo total para verificar integridad de datos.
      checksum += Math.abs(diff);

      if (diffPercent > 0) {
        //Retornamos stop y blank
        let blankKey = ++key;
        x.key = ++key;
        return [{ key: blankKey, reason: "blank", duration: diffPercent }, x];
      }
      x.key = ++key;
      return [x];
    })
  );

  //Establece hasta donde vamos a completar con blanks. (Actualidad | Limite derecho)
  let rightLimit = moment().isAfter(right) ? right : moment();
  let lastStop = stopsAndBlanks[stopsAndBlanks.length - 1] || {
    endDate: leftTime
  };

  let diffWithLastStop = moment
    .duration(rightLimit.diff(moment(lastStop.endDate)))
    .asMinutes();
  let diffWithLastStopPercent = Math.abs(
    (diffWithLastStop * 100) / rangeMinutes
  );
  let diffWithRight = moment.duration(rightLimit.diff(right)).asMinutes();
  let diffWithRightPercent = Math.abs((diffWithRight * 100) / rangeMinutes);
  checksum += Math.abs(diffWithLastStop);

  if (diffWithLastStopPercent > 0) {
    stopsAndBlanks.push({
      key: ++key,
      reason: "blank",
      duration: diffWithLastStopPercent
    });
  }
  if (diffWithRightPercent > 0) {
    stopsAndBlanks.push({
      key: ++key,
      reason: "end",
      duration: diffWithRightPercent
    });
  }

  /**
   * Error forzado para no mostrar datos incronguentes.
   */
  if (checksum > rangeMinutes) {
    //console.log("Checksum",checksum,rangeMinutes)
    throw new Error("sum exceeds the range.");
  }

  return stopsAndBlanks;
}

/**
 * Configuración del tooltip mostrado sobre los componentes de stacked bar.
 */
function getToolTipConfiguration(stops, orders) {
  return {
    enabled: true,
    location: "center",
    customizeTooltip: function(arg) {
      //Tooltip informa razon, motivo y duración total.
      let key = arg.point.data.key;
      let stop = stops.find(s => s.key === key);
      //Simplifica tooltip si no es de una parada.
      let observation =
        stop.observation !== "N/A" && stop.observation !== null
          ? stop.observation
          : "Sin observación";
      let updateBy =
        stop.updatedBy !== null ? `Actualizado por: ${stop.updatedBy}` : "";
      let reason = stop.reason;
      if (reason === "blank") {
        return { text: `Máquina en marcha` };
      }
      if (reason === "end") {
        return { text: `No transcurrido` };
      }

      let stopEndDate = stop.originalEndDate
        ? moment(stop.originalEndDate)
        : moment();
      var stopDuration = moment.duration(
        moment(stopEndDate).diff(moment(stop.originalStartDate))
      );
      var minutes = Math.trunc(stopDuration.asMinutes());
      //Obtencion de datos de la orden si existen
      // let order = orders.find(o => o.orderID === stop.order);
      // let lot = typeof order != undefined ? order.lot : "N/A";
      //Traduccion de undefined stop
      reason =
        reason === "" || reason === undefined || reason === "N/A"
          ? "Sin definir"
          : reason;
      return {
        // \n Lote:${lot} antes se mostraba lote en text:
        text: `${reason} \n Observación: ${observation} \n ${updateBy} \n Duración: ${minutes} mins.\n Desde ${moment(
          stop.originalStartDate
        ).format("DD/MM/YYYY HH:mm")} hasta ${
          stop.originalEndDate
            ? moment(stop.originalEndDate).format("DD/MM/YYYY HH:mm")
            : "ahora"
        } \n Parcial desde  ${moment(stop.startDate).format(
          "DD/MM/YYYY HH:mm"
        )} hasta ${
          stop.endDate
            ? moment(stop.endDate).format("DD/MM/YYYY HH:mm")
            : "ahora"
        } `
      };
    }
  };
}

/**
 * Configuración de cada segmento del stacked bar.
 */
function getCustomizedPoint(pointInfo, colors) {
  //Blank predefined color
  if (pointInfo.data.name === "end") {
    return { color: "#f2f2f2" };
  }
  if (pointInfo.data.name === "blank") {
    return { color: "#66d18a" };
  }
  //No recognized predefined color
  else if (pointInfo.data.name === "undefined") {
    return { color: "#db3636" };
  }

  //Category color
  let predefinedColor = colors[pointInfo.data.name] || "#db3636";
  return { color: predefinedColor };
}

/**
 * Evento al cliquear en un punto. Si es parada sin definir, propaga accion al padre.
 */
function onPointClick(point, stops, callback) {
  let stop = stops.find(s => s.key === point.target.data.key);
  if (typeof stop.recognized != "undefined" && stop.recognized === false) {
    callback(stop);
  } else {
    window.location.href = `/paradas/listado?order=${stop.order}&line=${stop.lineID}&stopId=${stop.stopID}`;
  }
}

/**
 * Componente que dibuja los indicadores de hora debajo el stacked bar.
 */
const ShiftIndicators = WidthProvider(({ width, leftTime, rightTime }) => {
  const minIndicatorWidth = 80;
  const right = moment(rightTime);
  let left = moment(leftTime);

  const indicatorStyle = {
    paddingLeft: "2px",
    borderLeft: "#ddd 1px solid",
    textOverflow: "ellipsis",
    overflow: "hidden",
    float: "left"
  };

  let shiftInterval = moment.duration(right.diff(left));
  let maxIndicatorCount = width / minIndicatorWidth;
  let maxMinutesPerInterval = shiftInterval.as("minutes") / maxIndicatorCount;
  let minutesPerInterval =
    maxMinutesPerInterval > 60 ? 60 : maxMinutesPerInterval > 30 ? 30 : 15;
  let indicatorCount = +Math.ceil(
    shiftInterval.as("minutes") / minutesPerInterval
  ).toFixed(0);

  let indicators = [];
  for (let k = 0; k < indicatorCount; k++) {
    let nextInterval = left.clone().add(minutesPerInterval, "minutes");
    if (nextInterval.isAfter(right)) {
      nextInterval = right;
    }
    let currentInterval = moment.duration(nextInterval.diff(left));
    let width = (currentInterval * 100) / shiftInterval;
    indicators.push(
      <div style={{ ...indicatorStyle, width: width + "%" }} key={k}>
        {left.format("H:mm")}
      </div>
    );
    left = nextInterval;
  }

  return (
    <>
      <div style={{ width: "100%", paddingTop: "40px", paddingBottom: "8px" }}>
        {indicators}
      </div>
      <br />
    </>
  );
});
