import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

const SectorLevel = props =>
  props.tree.map((sector, i) => {
    let lines = sector.lines.map(line => `${line.lineID}`).join(",");
    let queryParam = `?sector=${encodeURI(sector.sector)}&lines=${lines}`;

    return (
      <Dropdown.Item
        as={Link}
        key={i}
        to={`/resumen${queryParam}`}
        style={{ color: "rgba(0,0,0,.87" }}
      >
        {sector.sector}
      </Dropdown.Item>
    );
  });

export default ({ tree }) => {
  if (!tree) return <Dropdown item loading simple disabled />;

  return (
    <Dropdown item text={i18n()._(t`RESUMEN`)} simple>
      <Dropdown.Menu>
        <Dropdown.Header>
          <Trans>Resumen por sector</Trans>
        </Dropdown.Header>
        <Dropdown.Divider />
        <SectorLevel tree={tree} />
      </Dropdown.Menu>
    </Dropdown>
  );
};
