import React, { Component } from "react";
import { Segment, Header } from "semantic-ui-react";
import {
  Column,
  FormItem,
  EmailRule,
  DataGrid,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  Lookup,
  RequiredRule,
  StringLengthRule,
  Button
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { UserRoles } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import ArrayStore from "devextreme/data/array_store";
import { authHeader } from "../../utils/AuthHeader";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";
import TelegramUser, {
  postTelegramUser,
  postAllTelegramUsers
} from "./../../services/telegramUsersService";

export default class UsersConfig extends Component {
  render() {
    return (
      <Segment basic padded>
        <Header as="h2">
          <Trans>Configuración de usuarios</Trans>
          <Header.Subheader>
            <Trans>Actualiza la información de los usuarios del sistema.</Trans>
          </Header.Subheader>
        </Header>
        <UsersDataGrid />
      </Segment>
    );
  }
}

UsersConfig.getMenuItem = () => <Trans>Usuarios</Trans>;
UsersConfig.getPermissions = userLevel => userLevel === UserRoles.ADMINISTRATOR;

const url = "/api/users";

const dataSource = createStore({
  key: "userID",
  loadUrl: `${url}/get`,
  insertUrl: `${url}/post`,
  updateUrl: `${url}/put`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const groupsData = createStore({
  key: "Value",
  loadUrl: `/api/notifications/groups/lookup`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const rolesData = new ArrayStore({
  key: "key",
  data: UserRoles.KeyValuePair
});

class UsersDataGrid extends React.Component {
  render() {
    return (
      <DataGrid
        key={"userID"}
        dataSource={dataSource}
        showBorders={true}
        style={{ maxHeight: 500, fontSize: "1rem" }}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        remoteOperations={true}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "after",
            widget: "dxButton",
            options: {
              icon: "message",
              hint: i18n()._(
                t`Enviar correo de registro en Telegram a usuarios no registrados`
              ),
              onClick: () =>
                postAllTelegramUsers(
                  () => notify(i18n()._(t`Correo enviado`), "success", 2000),
                  () =>
                    notify(
                      i18n()._(t`Ha ocurrido un error, vuelva a intentarlo`),
                      "error",
                      5000
                    )
                )
            }
          });
        }}
        onEditorPreparing={e => {
          if (e.parentType === "dataRow") {
            switch (e.dataField) {
              case "userID":
                e.editorOptions.readOnly = !e.row.inserted;
                break;
              case "password":
                e.editorOptions.mode = "password";
                break;
              default:
                break;
            }
          }
        }}
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={false} />
        <GroupPanel visible={true} />
        <Scrolling mode={"virtual"} />
        <Editing
          mode={"form"}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
          useIcons={true}
        />
        <Column dataField={"userID"} caption={i18n()._(t`Usuario`)} key>
          <RequiredRule
            message={i18n()._(
              t`Debe definir un nombre de acceso para el usuario.`
            )}
          />
          <StringLengthRule
            max={12}
            message={i18n()._(
              t`El nombre de usuario debe tener como máximo 12 caracteres.`
            )}
          />
        </Column>
        <Column dataField={"groupID"} caption={i18n()._(t`Grupo`)}>
          <Lookup
            dataSource={groupsData}
            valueExpr={"value"}
            displayExpr={"text"}
          />
        </Column>
        <Column dataField={"firstName"} caption={i18n()._(t`Nombre`)}>
          <RequiredRule
            message={i18n()._(t`Debe definir un nombre de usuario.`)}
          />
          <StringLengthRule
            max={40}
            message={i18n()._(
              t`El nombre de usuario debe tener como máximo 40 caracteres.`
            )}
          />
        </Column>
        <Column dataField={"lastName"} caption={i18n()._(t`Apellido`)}>
          <RequiredRule
            message={i18n()._(t`Debe definir un apellido para el usuario.`)}
          />
          <StringLengthRule
            max={40}
            message={i18n()._(
              t`El apellido debe tener como máximo 40 caracteres.`
            )}
          />
        </Column>
        <Column dataField={"email"} caption={i18n()._(t`Email`)}>
          <EmailRule
            message={i18n()._(t`Debe ingresar un formato de email válido.`)}
          />
        </Column>
        {/* <Column dataField={'phone'} caption={i18n()._(t`Telefono`)}>
                  <RangeRule min={1} max={99999999999999} dataType={'number'} message={i18n()._(t`Ingrese un número de teléfono válido, solo númerico.`)} />
                </Column> */}
        <Column
          dataField={"password"}
          caption={i18n()._(t`Contraseña`)}
          visible={false}
        >
          <RequiredRule
            message={i18n()._(t`Debe definir una contraseña para el usuario.`)}
          />
          <StringLengthRule
            max={50}
            message={i18n()._(
              t`La contraseña debe tener como máximo 12 caracteres.`
            )}
          />
        </Column>
        <Column dataField={"roleID"} caption={i18n()._(t`Rol`)}>
          <RequiredRule
            message={i18n()._(t`Debe definir un rol para el usuario.`)}
          />
          <Lookup
            dataSource={rolesData}
            valueExpr={"key"}
            displayExpr={"value"}
          />
        </Column>
        <Column dataField={"active"} caption={i18n()._(t`Activo`)} />
        <Column dataField={"updatedBy"} caption={i18n()._(t`Actualizado por`)}>
          <FormItem visible={false} />
        </Column>
        <Column
          dataField={"updateDate"}
          dataType={"date"}
          format={"dd/MM/yyyy HH:mm:ss"}
          caption={i18n()._(t`Ultima actualización `)}
        >
          <FormItem visible={false} />
        </Column>
        <Column type="buttons">
          <Button
            icon="message"
            hint={i18n()._(t`Registrar en Telegram`)}
            onClick={({ row }) => {
              if (row.data.email) {
                postTelegramUser(
                  row.data.userID,
                  () => notify(i18n()._(t`Correo enviado`), "success", 2000),
                  () =>
                    notify(
                      i18n()._(t`Ha ocurrido un error, vuelva a intentarlo`),
                      "error",
                      5000
                    )
                );
              } else {
                notify(
                  i18n()._(
                    t`El usuario ${row.data.userID} debe ser configurado con un correo previamente`
                  ),
                  "info",
                  3000
                );
              }
            }}
          />
          <Button name="edit" />
          <Button name="delete" />
        </Column>
      </DataGrid>
    );
  }
}
