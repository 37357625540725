import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../utils/AuthHeader";

function createStopReasonsDataSource() {
  return createStore({
    key: "reasonID",
    loadUrl: `/api/stopreasons/get`,
    insertUrl: `/api/stopreasons/post`,
    updateUrl: `/api/stopreasons/put`,
    deleteUrl: `/api/stopreasons/delete`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  });
}

function createResourcesLookupDataSource() {
  return createStore({
    key: "Value",
    loadUrl: `/api/Resources/LookupResources`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  });
}

export { createResourcesLookupDataSource, createStopReasonsDataSource };
