import React, { useState } from "react";
import { connect } from "react-redux";
import { SelectBox } from "devextreme-react/select-box";
import { Button, Card, Segment } from "semantic-ui-react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { terminalActions } from "./actions";

const resourcesSource = createStore({
  key: "Value",
  loadUrl: `/api/resources/LookupResources`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const CardLine = ({ data, onReset }) => (
  <Card>
    <Card.Content>
      <Card.Header>Line {data.lineID}</Card.Header>
      <Card.Meta>
        Configurada el {data.updateDate} por {data.updatedBy}
      </Card.Meta>
      <Card.Description>
        La linea es accesible a todos los operarios de la terminal.
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
      <div className="ui one buttons">
        <Button basic color="red" onClick={() => onReset()}>
          Eliminar
        </Button>
      </div>
    </Card.Content>
  </Card>
);

const CardLineEdit = props => {
  const [lineId, setLine] = useState(undefined);
  return (
    <Card>
      <Card.Content>
        <Card.Header>
          <SelectBox
            dataSource={resourcesSource}
            allowClearing
            valueExpr={"value"}
            displayExpr={"text"}
            onValueChanged={e => setLine(e.value)}
          />
        </Card.Header>
        <Card.Description>
          Seleccione la linea que desea configurar para esta terminal.
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui one buttons">
          <Button basic color="green" onClick={() => props.onConfigure(lineId)}>
            Configurar
          </Button>
        </div>
      </Card.Content>
    </Card>
  );
};

const Terminal = ({ terminal, resetTerminal, configureTerminal }) => {
  return (
    <Segment loading={typeof terminal.globalParameters == "undefined"}>
      <Card.Group>
        {!terminal.terminal && (
          <CardLineEdit onConfigure={line => configureTerminal(line)} />
        )}
        {terminal.terminal && (
          <CardLine data={terminal.terminal} onReset={e => resetTerminal()} />
        )}
      </Card.Group>
    </Segment>
  );
};

function mapStateToProps(state) {
  return {
    terminal: state.terminal
  };
}

const mapDispatchToProps = dispatch => {
  return {
    configureTerminal: line => terminalActions.configureTerminal(line),
    resetTerminal: () => terminalActions.resetTerminal()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Terminal);
