import { useState } from "react";
import { useSelector } from "react-redux";
import { BeaconStatus, MttoStatus } from "../utils/Consts";
import { getLineStatus } from "../features/indicators/service";
import { useInterval } from "../utils/IntervalHook";

export default function useLineStatus(line, interval = 15000) {
  const currentStop = useSelector(state => state.indicators.currentStop);
  const [beaconStatus, setBeaconStatus] = useState(BeaconStatus.UNKNOWN);

  useInterval(
    () => {
      if (line == null) {
        setBeaconStatus(BeaconStatus.UNKNOWN);
        return;
      }

      if (
        currentStop != null &&
        currentStop.maintenance &&
        currentStop.maintenance.status !== MttoStatus.Finished
      ) {
        setBeaconStatus(BeaconStatus.MAINTEINANCE);
        return;
      }

      getLineStatus(line)
        .then(status =>
          status
            ? setBeaconStatus(BeaconStatus.ON)
            : setBeaconStatus(BeaconStatus.OFF)
        )
        .catch(_ => setBeaconStatus(BeaconStatus.OFF));
    },
    interval,
    [line]
  );

  return {
    lineStatus: beaconStatus === BeaconStatus.ON,
    beaconColor: beaconStatus
  };
}
