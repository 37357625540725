import { indicatorsConstants } from "./actions";
import moment from "moment";

const initialState = {
  line: null,
  indexes: {},
  historyIndexes: {},
  currentStop: {},
  navigation: {},
  watching: false
};

export default function _indicators(state = initialState, action) {
  switch (action.type) {
    case indicatorsConstants.INDICATORS_SETLINE:
      return {
        line: action.payload,
        navigation: state.navigation,
        currentStop: { ...state.currentStop },
        indexes: { ...state.indexes },
        historyIndexes: { ...state.historyIndexes },
        watching: state.watching,
        lastUpdate: state.lastUpdate
      };
    case indicatorsConstants.INDICATORS_UPDATE:
      return {
        line: state.line,
        navigation: state.navigation,
        currentStop: { ...state.currentStop },
        indexes: { ...action.payload },
        historyIndexes: { ...state.historyIndexes },
        watching: state.watching,
        lastUpdate: moment().unix()
      };
    case indicatorsConstants.INDICATORS_UPDATE_HISTORY:
      return {
        line: state.line,
        navigation: { ...action.payload.navigation },
        currentStop: { ...state.currentStop },
        indexes: { ...state.indexes },
        historyIndexes: { ...action.payload.indexes },
        watching: state.watching,
        lastUpdate: moment().unix()
      };
    case indicatorsConstants.INDICATORS_SET_CURRENT_STOP:
      return {
        line: state.line,
        navigation: state.navigation,
        currentStop: { ...action.payload },
        indexes: { ...state.indexes },
        historyIndexes: { ...state.historyIndexes },
        watching: state.watching,
        lastUpdate: moment().unix()
      };
    case indicatorsConstants.INDICATORS_SET_NAVIGATION:
      return {
        line: state.line,
        navigation: { ...action.payload },
        currentStop: { ...state.currentStop },
        indexes: { ...state.indexes },
        historyIndexes: { ...state.historyIndexes },
        watching: state.watching,
        lastUpdate: moment().unix()
      };
    case indicatorsConstants.INDICATORS_START_OBSERVER:
      return {
        line: state.line,
        navigation: state.navigation,
        currentStop: { ...state.currentStop },
        indexes: { ...state.indexes },
        historyIndexes: { ...state.historyIndexes },
        watching: true,
        lastUpdate: state.lastUpdate
      };
    default:
      return state;
  }
}
