import React, { useEffect, Component } from "react";
import { Segment, Header, Label } from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import {
  Column,
  DataGrid,
  FilterRow,
  GroupPanel,
  Scrolling,
  Export,
  HeaderFilter
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

const Log = props => {
  useEffect(() => {
    document.title = "OAE++ Logs";
  }, []);
  return (
    <>
      <Segment padded="very" style={{ margin: 20 }}>
        <Header as="h2">
          <Trans>Visor de Logs</Trans>
        </Header>
        <LogDataGrid />
      </Segment>
    </>
  );
};

const url = "/api/log";

const dataSource = createStore({
  key: "time",
  loadUrl: `${url}/get`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

//const GridOptions = ({ row, role, onClick }) => {
//    if (role === UserRoles.SUPERVISOR && row.data.recognized)
//        return <Button onClick={() => onClick('modify', row.data)} size='mini' color='blue' >Modificar</Button>;
//
//    if (role === UserRoles.SUPERVISOR || role === UserRoles.OPERATOR)
//        return <Button onClick={() => onClick('recognize', row.data)} size='mini' color='red' >Reconocer</Button>;
//    return null;
//}

class LogDataGrid extends Component {
  toolbarPreparing({ component, toolbarOptions }) {
    toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: function() {
          component.refresh();
        }
      }
    });
  }

  /*  rowPrepared(info) {
         console.log(info);
         if (info.rowType === 'data') {
             if (info.data.level === 'ERROR')
                 info.rowElement.style.background = 'red';
             if (info.data.level === 'WARN')
                 info.rowElement.style.background = 'yellow';
             if (info.data.level === 'INFO')
                 info.rowElement.style.background = 'green';
         }
     }
  */

  render() {
    return (
      <DataGrid
        columnAutoWidth={true}
        dataSource={dataSource}
        editing={{
          allowUpdating: false,
          allowAdding: false,
          allowDeleting: false
        }}
        showBorders={true}
        style={{ maxHeight: 500, fontSize: "1rem" }}
        allowColumnReordering={true}
        allowColumnResizing={true}
        remoteOperations={true}
        onToolbarPreparing={e => this.toolbarPreparing(e)}
        showRowLines={true}
      >
        <Export enabled allowExportSelectedData={false} fileName="OAE_LOG" />
        <HeaderFilter visible={true} />
        <FilterRow visible={true} />
        <GroupPanel visible={true} />
        <Scrolling mode={"virtual"} />
        <Column
          caption={i18n()._(t`Fecha creación `)}
          dataField={"time"}
          sortIndex={0}
          sortOrder={"desc"}
          dataType={"Fecha"}
          customizeText={c => c.valueText}
          format={"dd/MM/yyyy HH:mm:ss"}
          cssClass={"top-cell"}
        />
        <Column
          caption={i18n()._(t`Nivel`)}
          dataField={"level"}
          cssClass={"top-cell"}
          filterValues={["FATAL", "ERROR", "WARN"]}
          cell={e => <p>Blue</p>}
          cellComponent={e => <Level text={e.data.level} />}
        />
        <Column
          dataField={"logger"}
          caption={i18n()._(t`Logger`)}
          cssClass={"top-cell"}
        />
        <Column
          dataField={"message"}
          caption={i18n()._(t`Mensaje`)}
          encodeHtml={false}
          customizeText={cellInfo => {
            return cellInfo.value.replace(/(?:\r\n|\r|\n)/g, "<br />");
          }}
          cssClass={"top-cell"}
        />
      </DataGrid>
    );
  }
}

const Level = ({ text }) => {
  let color = "gray";
  color = text === "FATAL" ? "#FF3F00" : color;
  color = text === "ERROR" ? "#FF3F00" : color;
  color = text === "WARN" ? "#FFC700" : color;
  color = text === "INFO" ? "#00C0F4" : color;
  color = text === "DEBUG" ? "#8FCE4F" : color;
  color = text === "TRACE" ? "#F0E9DF" : color;

  return (
    <Label
      size="tiny"
      style={{
        backgroundColor: color,
        color: "white"
      }}
    >
      {text}
    </Label>
  );
};

export default Log;
