import React, { Component } from "react";
import { Segment, Header, Checkbox, Form } from "semantic-ui-react";
import {
  Column,
  DataGrid,
  FormItem,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  Lookup
} from "devextreme-react/data-grid";
import { UserRoles } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

class TerminalsConfig extends Component {
  constructor(props) {
    super(props);
    this.state = { sync: false, isLoading: true };
  }
  //Fetch parameters
  componentDidMount() {
    fetch("/api/parameters/get/", { headers: authHeader() })
      .then(response => response.json())
      .then(data =>
        this.setState({ sync: data.terminalSyncByIP, isLoading: false })
      );
  }

  //Save parameters
  toggleSync(e, data) {
    this.setState({ isLoading: true }, () => {
      //Security
      var headers = authHeader();
      headers["Content-Type"] = "application/x-www-form-urlencoded";
      //Payload
      let formData = new FormData();
      formData.append(
        "values",
        JSON.stringify({ terminalSyncByIP: data.checked })
      );

      fetch(`/api/parameters/put/`, {
        headers: headers,
        method: "PUT",
        body: new URLSearchParams(formData)
      })
        .then(response => response.json())
        .then(data =>
          this.setState({ sync: data.terminalSyncByIP, isLoading: false })
        )
        .catch(error => this.setState({ error }));
    });
  }

  render() {
    return (
      <Segment basic padded>
        <Header as="h2">
          <Trans>Configuración de terminales</Trans>
          <Header.Subheader>
            <Trans>
              Actualiza la información de las terminales del sistema.
            </Trans>
          </Header.Subheader>
        </Header>
        <Form loading={this.state.isLoading}>
          <Form.Field>
            <Checkbox
              checked={this.state.sync}
              onChange={(e, d) => this.toggleSync(e, d)}
              toggle
              label={i18n()._(t`Sincronizar terminales por dirección IP`)}
            />
          </Form.Field>
        </Form>
        <TerminalsDataGrid />
      </Segment>
    );
  }
}

TerminalsConfig.getMenuItem = () => <Trans>Terminales</Trans>;
TerminalsConfig.getPermissions = userLevel =>
  userLevel === UserRoles.ADMINISTRATOR;
export default TerminalsConfig;

const url = "/api/terminals";

const dataSource = createStore({
  key: "terminalID",
  loadUrl: `${url}/get`,
  insertUrl: `${url}/post`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const resourcesSource = createStore({
  key: "Value",
  loadUrl: `/api/resources/LookupResources`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const TerminalsDataGrid = () => (
  <DataGrid
    key={"terminalID"}
    dataSource={dataSource}
    showBorders={true}
    style={{ maxHeight: 500, fontSize: "1rem" }}
    allowColumnReordering={true}
    allowColumnResizing={true}
    rowAlternationEnabled={true}
    remoteOperations={true}
  >
    <FilterRow visible={true} />
    <HeaderFilter visible={false} />
    <GroupPanel visible={true} />
    <Scrolling mode={"virtual"} />
    <Editing
      mode={"form"}
      allowAdding={true}
      allowDeleting={true}
      allowUpdating={false}
      useIcons={true}
    />
    <Column dataField={"terminalID"} key>
      <FormItem visible={false} />
    </Column>
    <Column dataField={"lineID"} caption={i18n()._(t`Linea`)}>
      <Lookup
        dataSource={resourcesSource}
        allowClearing
        valueExpr={"value"}
        displayExpr={"text"}
      />
    </Column>
    <Column
      dataField={"ipAddress"}
      caption={i18n()._(t`Dirección IP`)}
    ></Column>
    <Column dataField={"updatedBy"} caption={i18n()._(t`Actualizado  por`)}>
      <FormItem visible={false} />
    </Column>
    <Column
      dataField={"updateDate"}
      format={"dd/MM/yyyy HH:mm:ss"}
      dataType={"date"}
      caption={i18n()._(t`Ultima actualización`)}
    >
      <FormItem visible={false} />
    </Column>
  </DataGrid>
);
