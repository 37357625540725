import { register } from "./register";

/**
 * Loader.js
 * Importación de modulos para la generación de bundles independientes.
 */

register(
  "order-manager",
  import(/* webpackChunkName: "order-manager" */ "../../modules/order-manager")
);
register(
  "production-manager",
  import(
    /* webpackChunkName: "production-manager" */ "../../modules/production-manager"
  )
);
register(
  "manual-stops-manager",
  import(
    /* webpackChunkName: "manual-stops-manager" */ "../../modules/manual-stops-manager"
  )
);
register(
  "mtto-manager",
  import(/* webpackChunkName: "mtto-manager" */ "../../modules/mtto-manager")
);
register(
  "stops-manager",
  import(/* webpackChunkName: "stops-manager" */ "../../modules/stops-manager")
);
