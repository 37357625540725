import { getShiftLimits } from "../../../utils/ShiftHelper";
import moment from "moment";
import { getOEEHistory } from "../service";
import store from "../../../app/store";
import { indicatorsConstants } from "../actions";
import { useEffect } from "react";
import { useSelector } from "react-redux";

// Navega por turno
export function useShiftNavigation() {
  let { line } = useSelector(state => state.indicators);

  useEffect(() => {
    /* if ((line || line === 0) && start)
            navigate(line, start) */
  }, [line]);

  return (line, date) => navigate(line, date);
}

function navigate(line, date) {
  const { start, end } = getShiftLimits(moment(date));
  getOEEHistory(line, start).then(indexes => {
    store.dispatch({
      type: indicatorsConstants.INDICATORS_UPDATE_HISTORY,
      payload: {
        indexes: indexes,
        navigation: { start, end }
      }
    });
  });
}
