import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect
} from "react";
import {
  Button,
  Modal,
  Segment,
  Message,
  Form,
  Grid,
  GridColumn
} from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import { SystemStops } from "../../utils/Consts";
import * as DataSources from "../../api/DataSources";
import { recognizeStop } from "./service";

//Datasource
const reasonsDataSource = DataSources.createStopReasonsDataSource();

//#region Recognize Modal

const RecognizeSteps = {
  Selecting: "Selecting",
  Signing: "Signing"
};

const recognizeInitialState = {
  stop: null, //Stop to recognize
  open: false, //Modal state
  step: RecognizeSteps.Selecting, //Current step of reconition
  category: SystemStops.RootCategory, //Selected category
  reason: null //Selected reason,
};

export const RecognizeModal = forwardRef(({ recognized }, ref) => {
  const [state, setState] = useState(recognizeInitialState);

  // Expose some imperative methods
  useImperativeHandle(ref, () => ({
    show: stop =>
      setState({
        stop,
        open: true,
        step: RecognizeSteps.Selecting,
        categoryID: SystemStops.RootCategory,
        reason: null
      })
  }));

  //Actions
  const close = () => setState({ ...recognizeInitialState });
  const selectCategory = categoryID => setState({ ...state, categoryID });
  const selectReason = reason => {
    setState({ ...state, reason, step: RecognizeSteps.Signing });
  };
  const finish = () => {
    if (typeof recognized !== "undefined") {
      recognized();
    }
    close();
  };

  //Current
  const selecting = state.step === RecognizeSteps.Selecting;
  const signing = state.step === RecognizeSteps.Signing;

  //Current stop number
  const { stopID = 0, recognized: reco = false } = state.stop || {};

  //Modification ?
  const title =
    reco === true ? (
      <Trans>Modificación de parada nro. {stopID}</Trans>
    ) : (
      <Trans>Registro de parada nro. {stopID}</Trans>
    );

  return (
    <Modal
      open={state.open}
      dimmer={"inverted"}
      size={selecting ? "small" : "tiny"}
      onClose={close}
      closeIcon
    >
      <Modal.Header as="h2">
        {selecting && title}
        {signing && <Trans>Autorización requerida</Trans>}
      </Modal.Header>
      <Modal.Content scrolling>
        {selecting && (
          <StopSelection
            stop={state.stop}
            categoryID={state.categoryID}
            reason={state.reason}
            selectCategory={selectCategory}
            selectReason={selectReason}
          />
        )}
        {signing && (
          <SignForm
            stop={state.stop}
            reason={state.reason}
            root={state.root}
            path={state.path}
            finsihed={finish}
          />
        )}
      </Modal.Content>
    </Modal>
  );
});
//#endregion

//#region Inner forms

const SignForm = ({ stop, reason, finsihed }) => {
  const [state, setState] = useState({
    username: "",
    password: "",
    observation: null,
    error: null,
    submitted: false,
    loading: false
  });

  const handleChange = ({ target }) =>
    setState({ ...state, [target.name]: target.value.trim() });

  const handleSubmit = e => {
    e.preventDefault();
    setState({ ...state, submitted: true });
    if (state.username && state.password) {
      setState({ ...state, loading: true });
      recognizeStop(
        stop,
        reason,
        state.observation,
        state.username,
        state.password,
        r => finsihed(),
        r => setState({ ...state, loading: false, submitted: true, error: r })
      );
    }
  };

  const { submitted, username, password, observation, error, loading } = state;

  return (
    <>
      <Message
        negative
        hidden={!(submitted && (!username || !password || error))}
      >
        <Message.Header>Error</Message.Header>
        <Message.List>
          {submitted && !username && (
            <Message.Item>El campo username es obligatorio.</Message.Item>
          )}
          {submitted && !password && (
            <Message.Item>El campo password es obligatorio.</Message.Item>
          )}
          {error && <Message.Item>{error}</Message.Item>}
        </Message.List>
      </Message>
      <Form onSubmit={handleSubmit} loading={loading}>
        <Form.Field>
          <label>Observación</label>
          <textarea
            rows={2}
            placeholder="Observación"
            name="observation"
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Nombre de usuario</label>
          <input
            placeholder="Nombre"
            name="username"
            onChange={handleChange}
            autoComplete="username"
          />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input
            type="password"
            placeholder="Password"
            name="password"
            onChange={handleChange}
            autoComplete="current-password"
          />
        </Form.Field>
        <Form.Field>
          <Button fluid type="submit">
            Continuar
          </Button>
        </Form.Field>
      </Form>
    </>
  );
};

export const StopSelection = ({
  stop,
  categoryID,
  selectReason,
  selectCategory
}) => {
  const [state, setState] = useState({
    loading: true,
    reasons: []
  });

  //Load reasons
  useEffect(() => {
    reasonsDataSource
      .load()
      .then(res => setState({ loading: false, reasons: res }));
  }, [stop]);

  const reasonClick = reason =>
    reason.isCategory ? selectCategory(reason.reasonID) : selectReason(reason);

  //Map active reasons
  const reasons = state.reasons
    .filter(
      r =>
        r.parentID === categoryID &&
        enabledForLine(stop.lineID, r.enabledForLine)
    )
    .map(reason => (
      <Reason key={reason.reasonID} reason={reason} onClick={reasonClick} />
    ));

  return (
    <Segment basic loading={state.loading}>
      <Grid centered padded={false}>
        {reasons}
      </Grid>
    </Segment>
  );
};

const Reason = ({ reason, onClick }) => {
  let { reason: reasonText = "" } = reason;
  /*     if (reasonText.length > 10) {
        reasonText = `${reasonText.substr(0, 10)}...`;
    } */

  return (
    <GridColumn mobile={"8"} tablet={"5"} computer={"4"} style={{ padding: 5 }}>
      <Button
        size="tiny"
        fluid
        onClick={() => onClick(reason)}
        style={{ color: "white", backgroundColor: reason.color }}
      >
        {reasonText}
      </Button>
    </GridColumn>
  );
};

const enabledForLine = (current, lines) => {
  try {
    let enabledForLine = JSON.parse(lines);
    return enabledForLine.includes(current);
  } catch {
    return false;
  }
};

//#endregion
