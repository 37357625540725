import React, { Component } from "react";
import { connect } from "react-redux";
import { Responsive, Menu } from "semantic-ui-react";
import { Tab } from "semantic-ui-react";

//Base system config pages
import UsersConfig from "./UsersConfig";
import SectorsConfig from "./SectorsConfig";
import GroupsConfig from "./GroupsConfig";
import ResourcesConfig from "./ResourcesConfig";
import OPCSerersConfig from "./OPCServersConfig";
import OPCTagsConfig from "./OPCTagsConfig";
import ParametersConfig from "./ParametersConfig";
import EquipmentsConfig from "./EquipmentsConfig";
import StopReasonsConfig from "./StopReasons/Index";
import TargetsConfig from "./TargetsConfig";
import ProductsConfig from "./ProductsConfig";
import ProductSpeedsConfig from "./ProductSpeedsConfig";
import TerminalsConfig from "./TerminalsConfig";

//Agrega ConfigComponent a base panes si cumple con los permisos
const addConfigPane = (panes, role, key, ConfigComponent) => {
  if (ConfigComponent.getPermissions(role)) {
    panes.push({
      menuItem: (
        <Menu.Item key={key}>{ConfigComponent.getMenuItem()}</Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <ConfigComponent />
        </Tab.Pane>
      )
    });
  }
};

class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = { modules: {}, auth: {}, width: 1024 };
  }

  componentDidMount() {
    document.title = "OEE++ Configuración";
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.modules !== prevState.modules ||
      nextProps.auth !== prevState.auth
    ) {
      return { modules: nextProps.modules, auth: nextProps.auth };
    } else return null;
  }

  handleOnUpdate = (e, { width }) => this.setState({ width });

  render() {
    //User role
    var role = this.state.auth.user.roleID;

    //Base configuration panes
    let panes = [];
    addConfigPane(panes, role, "UsersConfig", UsersConfig);
    addConfigPane(panes, role, "GroupsConfig", GroupsConfig);
    addConfigPane(panes, role, "SectorsConfig", SectorsConfig);
    addConfigPane(panes, role, "ResourcesConfig", ResourcesConfig);
    addConfigPane(panes, role, "OPCSerersConfig", OPCSerersConfig);
    addConfigPane(panes, role, "OPCTagsConfig", OPCTagsConfig);
    addConfigPane(panes, role, "ParametersConfig", ParametersConfig);
    addConfigPane(panes, role, "TerminalsConfig", TerminalsConfig);
    addConfigPane(panes, role, "EquipmentsConfig", EquipmentsConfig);
    addConfigPane(panes, role, "StopReasonsConfig", StopReasonsConfig);
    //addConfigPane(panes, role, "StopCategoriesConfig", StopCategoriesConfig);
    addConfigPane(panes, role, "TargetsConfig", TargetsConfig);
    addConfigPane(panes, role, "ProductsConfig", ProductsConfig);
    addConfigPane(panes, role, "ProductSpeedsConfig", ProductSpeedsConfig);
    //addConfigPane(panes, role, "MigrationsConfig", MigrationsConfig);

    //Modules configuration panes
    Object.keys(this.state.modules).forEach(key => {
      let Component = window.modules[key];
      if (!Component) return;

      Component = Component.oee_configuration_page;

      //User logged, retrieve permissions
      if (Component && this.state.auth && this.state.auth.user) {
        var hasPermissions = Component.getPermissions(role);
        if (hasPermissions) {
          panes.push({
            menuItem: <Menu.Item>{Component.getMenuItem()}</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <Component />
              </Tab.Pane>
            )
          });
        }
      }
    });

    return (
      <Responsive fireOnMount onUpdate={this.handleOnUpdate}>
        <Tab
          className="configuration-tabs"
          menu={{ fluid: true, vertical: this.state.width > 799 }}
          panes={panes}
        />
      </Responsive>
    );
  }
}

const mapStateToProps = state => {
  return {
    modules: state.modules.registered,
    auth: state.authentication
  };
};

export default connect(mapStateToProps)(Configuration);
