import React, { Component } from "react";
import { Segment, Header } from "semantic-ui-react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { Chart } from "devextreme-react/chart";
import {
  Column,
  DataGrid,
  FilterRow,
  GroupPanel,
  Scrolling,
  Export,
  HeaderFilter
} from "devextreme-react/data-grid";

export class TagsExecutionDiagnostic extends Component {
  //Initial state values
  state = {
    data: [],
    average: 0,
    limitLine: {
      label: { text: `Max 1000 ms.`, font: { color: "red", size: 14 } },
      value: 1000,
      color: "red",
      width: 2
    }
  };
  //Api consumer store
  dataSource = createStore({
    loadUrl: `/api/diagnostics/calculationtimes`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  });

  //Lifecycle hooks to manage timer subscription
  componentDidMount() {
    this.interval = setInterval(() => this.refresh(), 5000);
    this.refresh();
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  //Refresh data from API
  refresh() {
    this.dataSource
      .load()
      .then(data => this.average(data))
      .then(({ data }) => this.setState({ data }));
  }

  //Calc average of data
  average(data) {
    const durations = data.map(ev => ev.duration);
    const avg = durations.reduce((p, c) => p + c, 0) / durations.length;
    const average = isNaN(avg) ? 0 : Math.round(avg);
    return { data, average };
  }

  //Update constants lines
  updateLines({ data, average }) {
    //Prevent
    if (!this.chartReference) return;
    //Add avg line
    this.chartReference.instance.option({
      valueAxis: {
        constantLines: [
          {
            label: {
              text: `Avg ${average} ms.`,
              font: { color: "lightsalmon", size: 14 }
            },
            value: average,
            color: "lightsalmon",
            width: 2
          },
          this.state.limitLine
        ]
      }
    });
    return { data, average };
  }

  //where the magic happens
  render() {
    let panes = [];
    let array = [];
    let { data } = this.state;
    data.forEach((entry, index) => {
      array.push({
        dateTime: entry.dateTime
      });
      entry.lines.forEach(({ line, result }) => {
        if (panes.includes(line) === false) panes.push(line);
        array[index][`Line${line}`] = result ? 1 : 0;
      });
    });

    if (panes.length === 0) return <Segment placeholder loading />;

    return (
      <Segment padded="very" style={{ height: panes.length * 80 + 150 }}>
        <Header
          as="h2"
          content="Tags Execution Time"
          subheader="Showing last 100 updates."
        />
        <Chart
          style={{ height: panes.length * 80 }}
          ref={ref => {
            this.chartReference = ref;
          }}
          palette="Violet"
          dataSource={array}
          panes={panes.map(p => ({ name: `Pane${p}` }))}
          commonSeriesSettings={{
            type: "steparea",
            argumentField: "dateTime",
            point: { visible: false }
          }}
          commonAxisSettings={{ grid: { visible: true } }}
          series={panes.map(p => ({
            pane: `Pane${p}`,
            valueField: `Line${p}`,
            name: `Line ${p}`
          }))}
          tooltip={{
            enabled: true,
            customizeTooltip: ({ seriesName }) => ({ text: `${seriesName}` })
          }}
          argumentAxis={{
            label: { format: "HH:mm:ss", visible: false },
            argumentType: "datetime",
            minorTick: { visible: false }
          }}
        />
      </Segment>
    );
  }
}

export class TagsExecutionDiagnostic2 extends Component {
  //Initial state values
  state = {
    data: [],
    average: 0,
    limitLine: {
      label: { text: `Max 1000 ms.`, font: { color: "red", size: 14 } },
      value: 1000,
      color: "red",
      width: 2
    }
  };
  //Api consumer store
  dataSource = createStore({
    loadUrl: `/api/diagnostics/calculationtimes`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  });

  //Lifecycle hooks to manage timer subscription
  componentDidMount() {
    this.interval = setInterval(() => this.refresh(), 5000);
    this.refresh();
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  //Refresh data from API
  refresh() {
    this.dataSource
      .load()
      .then(data => this.average(data))
      .then(data => this.updateLines(data))
      .then(({ data, average }) => this.setState({ data, average }));
  }

  //Calc average of data
  average(data) {
    const durations = data.map(ev => ev.duration);
    const avg = durations.reduce((p, c) => p + c, 0) / durations.length;
    const average = isNaN(avg) ? 0 : Math.round(avg);
    return { data, average };
  }

  //Update constants lines
  updateLines({ data, average }) {
    //Prevent
    if (!this.chartReference) return;
    //Add avg line
    this.chartReference.instance.option({
      valueAxis: {
        constantLines: [
          {
            label: {
              text: `Avg ${average} ms.`,
              font: { color: "lightsalmon", size: 14 }
            },
            value: average,
            color: "lightsalmon",
            width: 2
          },
          this.state.limitLine
        ]
      }
    });
    return { data, average };
  }

  //where the magic happens
  render() {
    return (
      <Segment padded="very">
        <DataGrid
          columnAutoWidth={true}
          /* dataSource={dataSource} */
          editing={{
            allowUpdating: false,
            allowAdding: false,
            allowDeleting: false
          }}
          showBorders={true}
          style={{ maxHeight: 500, fontSize: "1rem" }}
          allowColumnReordering={true}
          allowColumnResizing={true}
          remoteOperations={true}
          /*  onToolbarPreparing={e => this.toolbarPreparing(e)} */
          showRowLines={true}
        >
          <Export enabled allowExportSelectedData={false} fileName="OAE_LOG" />
          <HeaderFilter visible={true} />
          <FilterRow visible={true} />
          <GroupPanel visible={true} />
          <Scrolling mode={"virtual"} />
          <Column
            sortIndex={0}
            sortOrder={"desc"}
            dataField={"time"}
            dataType={"Fecha"}
            customizeText={c => c.valueText}
            format={"dd/MM/yyyy HH:mm:ss"}
            caption={"Fecha creación "}
            cssClass={"top-cell"}
          />
          {/*  <Column dataField={'level'} caption={'Nivel'} cssClass={'top-cell'}
                            filterValues={['FATAL','ERROR','WARN']}
                            cell={e=><p>Blue</p>}
                            cellComponent={e => <Level text={e.data.level} />} /> */}
          <Column
            dataField={"logger"}
            caption={"Logger"}
            cssClass={"top-cell"}
          />
          <Column
            dataField={"message"}
            caption={"Mensaje"}
            encodeHtml={false}
            customizeText={cellInfo => {
              return cellInfo.value.replace(/(?:\r\n|\r|\n)/g, "<br />");
            }}
            cssClass={"top-cell"}
          />
        </DataGrid>
        ;
      </Segment>
    );
  }
}
