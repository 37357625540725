import React from "react";
import { Header, Icon, Segment } from "semantic-ui-react";
import { Trans } from "@lingui/macro";

export const NotLine = props => {
  return (
    <>
      <Segment placeholder style={{ height: "100vh" }}>
        <Header as="h2" icon>
          <Icon name="warning circle" />
          <Trans>La terminal no está configurada con una línea.</Trans>
          <Header.Subheader>
            <Trans>
              Pide a un administrador que asigne una línea al terminal.
            </Trans>
          </Header.Subheader>
        </Header>
      </Segment>
    </>
  );
};

export const ForbiddenLine = props => {
  return (
    <>
      <Segment placeholder style={{ height: "100vh" }}>
        <Header as="h2" icon>
          <Icon name="ban" />
          <Header.Subheader>
            <Trans>No tienes acceso para ver información de esta línea.</Trans>
          </Header.Subheader>
        </Header>
      </Segment>
    </>
  );
};
