import {
  Form,
  Item,
  RangeRule,
  ButtonItem,
  ButtonOptions,
  SimpleItem,
  Label
} from "devextreme-react/form";
import React, {
  Component,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback
} from "react";
import { Segment, Header } from "semantic-ui-react";
import { UserRoles } from "../../utils/Consts";
import { authHeader } from "../../utils/AuthHeader";
import notify from "devextreme/ui/notify";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

//#region Page

export default class ParametersConfig extends Component {
  render() {
    return (
      <Segment basic padded>
        <Header as="h2">
          <Trans>Configuración de Parámetros generales</Trans>
          <Header.Subheader>
            <Trans>
              Actualiza los parametros de configuración de la plataforma.
            </Trans>
          </Header.Subheader>
        </Header>
        <Parameters />
      </Segment>
    );
  }
}

ParametersConfig.getMenuItem = () => <Trans>Parámetros</Trans>;
ParametersConfig.getPermissions = userLevel =>
  userLevel === UserRoles.ADMINISTRATOR;

//#endregion

//#region Components

const Parameters = () => {
  const form = useRef();
  const { loading, parameters } = useParameters();
  const { saving, saveParameters } = useSaveParameters();

  //Set form parameters
  useEffect(() => {
    if (parameters) {
      form.current.instance.option("formData", parameters);
    }
  }, [parameters]);

  //Save data to server
  const saveChanges = useCallback(e => {
    e.preventDefault();
    const data = form.current.instance.option("formData");
    saveParameters(data);
  }, []);

  //Memoize form (prevent devexpress issue; don't change this)
  const MemoizedForm = useMemo(
    () => <ParametersForm ref={form} saveChanges={saveChanges} />,
    []
  );

  return (
    <Segment basic loading={loading || saving}>
      {MemoizedForm}
    </Segment>
  );
};

const ParametersForm = React.forwardRef(({ saveChanges }, ref) => (
  <form onSubmit={saveChanges}>
    <Form key={"id"} ref={ref}>
      <Item
        itemType="group"
        caption={i18n()._(t`General`)}
        colCountByScreen={{ xs: 1, sm: 1, md: 2, lg: 2 }}
      >
        <Item
          dataField={"microStopTime"}
          label={{ text: i18n()._(t`Tiempo micro parada (seg)`) }}
          cssClass={" "}
        >
          <RangeRule ignoreEmptyValue={false} min={0} max={999999} />
        </Item>
        <Item
          dataField={"problemResolutionTime"}
          label={{ text: i18n()._(t`Tiempo marcha parada (seg)`) }}
          cssClass={" "}
        >
          <RangeRule ignoreEmptyValue={false} min={0} max={999999} />
        </Item>
        <Item
          dataField={"gridRefreshTime"}
          label={{ text: i18n()._(t`Tiempo refresco de grilla (seg)`) }}
          cssClass={" "}
        >
          <RangeRule ignoreEmptyValue={false} min={0} max={999999} />
        </Item>
        <Item
          dataField={"oeeCalculationRefresh"}
          label={{ text: i18n()._(t`Tiempo de calculo OEE (seg)`) }}
          cssClass={" "}
        >
          <RangeRule ignoreEmptyValue={false} min={0} max={999999} />
        </Item>
        <Item
          dataField={"updatedBy"}
          disabled={true}
          label={{ text: i18n()._(t`Actualizado por`) }}
        ></Item>
        <Item
          dataField={"updateDate"}
          disabled={true}
          editorOptions={{ displayFormat: "dd/MM/yyyy" }}
          dataType={"datetime"}
          label={{ text: i18n()._(t`Ultima actualizacion `) }}
        ></Item>
      </Item>
      <Item
        itemType="group"
        caption={i18n()._(t`Autenticación`)}
        colCountByScreen={{ xs: 1, sm: 1, md: 2, lg: 2 }}
      >
        <SimpleItem
          dataField="enableDomainValidation"
          editorType="dxCheckBox"
          editorOptions={{
            text: i18n()._(t`Validar dominio`)
          }}
        >
          <Label visible={false} />
        </SimpleItem>
        <Item
          dataField={"domain"}
          label={{ text: i18n()._(t`Dominio`) }}
          cssClass={" "}
        />
        <SimpleItem
          dataField="enableGroupValidation"
          editorType="dxCheckBox"
          editorOptions={{
            text: i18n()._(t`Validar grupo`)
          }}
        >
          <Label visible={false} />
        </SimpleItem>
        <Item
          dataField={"group"}
          label={{ text: i18n()._(t`Grupo`) }}
          cssClass={" "}
        />
      </Item>
      <Item
        itemType="group"
        caption={i18n()._(t`Correo electrónico`)}
        colCountByScreen={{ xs: 1, sm: 1, md: 2, lg: 2 }}
      >
        <SimpleItem
          dataField="emailEnabled"
          editorType="dxCheckBox"
          editorOptions={{ text: i18n()._(t`Habilitar correos`) }}
        >
          <Label visible={false} />
        </SimpleItem>
        <Item
          dataField={"emailFrom"}
          label={{ text: i18n()._(t`Remitente`) }}
        />
        <Item dataField={"emailHost"} label={{ text: i18n()._(t`Host`) }} />
        <Item dataField={"emailPort"} label={{ text: i18n()._(t`Puerto`) }}>
          <RangeRule ignoreEmptyValue={false} min={0} max={65535} />
        </Item>
      </Item>
      <Item
        itemType="group"
        caption={i18n()._(t`Telegram`)}
        colCountByScreen={{ xs: 1, sm: 3, md: 3, lg: 3 }}
      >
        <SimpleItem
          dataField="telegramEnabled"
          editorType="dxCheckBox"
          editorOptions={{
            text: i18n()._(t`Habilitar notificaciones Telegram`)
          }}
        >
          <Label visible={false} />
        </SimpleItem>
        <Item
          dataField={"telegramBotName"}
          editorOptions={{ placeholder: "username_bot" }}
          label={{ text: i18n()._(t`Nombre del bot`) }}
        />
        <Item
          dataField={"telegramNotificationInterval"}
          label={{ text: i18n()._(t`Intervalo de notificaciones (min)`) }}
        >
          <RangeRule ignoreEmptyValue={false} min={0} />
        </Item>
        <Item
          colSpan={3}
          dataField={"telegramBotToken"}
          editorOptions={{
            mode: "password",
            placeholder: "Ingrese nuevo token (reemplazará al existente)"
          }}
          label={{ text: i18n()._(t`Token`) }}
        />
        <SimpleItem
          dataField="telegramBotUseProxy"
          editorType="dxCheckBox"
          editorOptions={{ text: i18n()._(t`Habilitar proxy`) }}
        >
          <Label visible={false} />
        </SimpleItem>
        <Item
          dataField={"telegramBotProxyHost"}
          label={{ text: i18n()._(t`Host proxy`) }}
        />
        <Item
          dataField={"telegramBotProxyPort"}
          label={{ text: i18n()._(t`Puerto proxy`) }}
        >
          <RangeRule ignoreEmptyValue={false} min={0} max={65535} />
        </Item>
      </Item>
      <Item itemType="group">
        <ButtonItem colSpan={2} horizontalAlignment={"right"}>
          <ButtonOptions
            text={i18n()._(t`Guardar`)}
            type={"success"}
            useSubmitBehavior
          />
        </ButtonItem>
      </Item>
    </Form>
  </form>
));

//#endregion

//#region CRUD HOOKS

function useParameters() {
  const [state, setState] = useState({
    loading: true,
    parameters: {}
  });
  useEffect(() => {
    fetch("/api/parameters/get/", { headers: authHeader() })
      .then(response => response.json())
      .then(data => setState({ loading: false, parameters: data }));
  }, []);
  return state;
}

function useSaveParameters() {
  const [saving, setSaving] = useState(false);
  const saveParameters = data => {
    var headers = authHeader();
    headers["Content-Type"] = "application/x-www-form-urlencoded";
    //Payload
    let formData = new FormData();
    formData.append("values", JSON.stringify(data));

    setSaving(true);

    fetch(`/api/parameters/put/`, {
      headers: headers,
      method: "PUT",
      body: new URLSearchParams(formData)
    })
      .then(response => response.json())
      .then(data => {
        notify("Parametros actualizados", "success");
        setSaving(false);
      })
      .catch(error => {
        notify("Error al actualizar parametros", "error");
        setSaving(false);
      });
  };

  return { saving, saveParameters };
}
//#endregion
