//@format
import { authHeader } from "../../utils/AuthHeader";

export const terminalService = {
  fetchParameters,
  synchronize,
  resetTerminal,
  configureTerminal
};

function fetchParameters() {
  return fetch("/api/parameters/get/", { headers: authHeader() })
    .then(response => response.json())
    .then(parameters => {
      localStorage.setItem("parameters", JSON.stringify(parameters));
      return parameters;
    });
}

function synchronize() {
  return fetch(`/api/terminals/syncronize/0`, { headers: authHeader() })
    .then(response => response.json())
    .then(data => data.terminal);
}

function getLocalIp() {
  return new Promise(function(resolve, reject) {
    try {
      //compatibility for firefox and chrome
      window.RTCPeerConnection =
        window.RTCPeerConnection ||
        window.mozRTCPeerConnection ||
        window.webkitRTCPeerConnection;

      if (!window.RTCPeerConnection) {
        reject("Your browser does not support this API");
      }

      var pc = new RTCPeerConnection({ iceServers: [] }),
        noop = function() {};
      pc.createDataChannel(""); //create a bogus data channel
      pc.createOffer(pc.setLocalDescription.bind(pc), noop); // create offer and set local description
      pc.onicecandidate = function(ice) {
        //listen for candidate events
        if (!ice || !ice.candidate || !ice.candidate.candidate) return;
        var ipAddress = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(
          ice.candidate.candidate
        );
        if (
          !ipAddress ||
          Array.isArray(ipAddress) === false ||
          ipAddress.length === 0
        ) {
          reject("Error resolving local ip");
          return;
        }
        ipAddress = ipAddress[1];
        pc.onicecandidate = noop;
        pc.close();
        resolve(ipAddress);
      };
    } catch (error) {
      reject("Error resolving local ip:", error);
    }
  });
}

function resetTerminal(terminalID) {
  //Security
  var headers = authHeader();
  headers["Content-Type"] = "application/x-www-form-urlencoded";
  //Payload
  let formData = new FormData();
  formData.append("key", terminalID);

  fetch(`/api/terminals/delete/`, {
    headers: headers,
    method: "DELETE",
    body: new URLSearchParams(formData)
  })
    .then(response => {
      /* verificar response.status== 200*/
    })
    .catch(error => {
      /*Alertar error */
    });
}

function configureTerminal(lineId, sync) {
  if (sync) {
    return getLocalIp()
      .then(ip => postTerminal(lineId, ip))
      .catch(err => postTerminal(lineId, " "));
  } else {
    return postTerminal(lineId, " ");
  }
}

function postTerminal(lineId, ip) {
  //Security
  var headers = authHeader();
  headers["Content-Type"] = "application/x-www-form-urlencoded";
  //Payload
  let formData = new FormData();
  formData.append(
    "values",
    JSON.stringify({
      lineID: lineId,
      ipAddress: ip
    })
  );

  return fetch(`/api/terminals/post/`, {
    headers: headers,
    method: "POST",
    body: new URLSearchParams(formData)
  })
    .then(response => response.json())
    .then(data => data);
}
