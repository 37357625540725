import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Header } from "semantic-ui-react";
import {
  Column,
  DataGrid,
  FormItem,
  FilterRow,
  RequiredRule,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  Lookup
} from "devextreme-react/data-grid";
import { UserRoles } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { NumericRule } from "devextreme-react/form";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

export default class TargetsConfig extends Component {
  render = () => (
    <Segment basic padded>
      <Header as="h2">
        <Trans>Configuración de Targets</Trans>
        <Header.Subheader>
          <Trans>Actualiza la información de los recursos del sistema.</Trans>
        </Header.Subheader>
      </Header>
      <TargetsGrid />
    </Segment>
  );
}

TargetsConfig.getMenuItem = () => <Trans>Target Línea</Trans>;
TargetsConfig.getPermissions = userLevel =>
  userLevel === UserRoles.SUPERVISOR || userLevel === UserRoles.ADMINISTRATOR;

const url = "/api/targets";

const dataSource = createStore({
  key: "lineID",
  loadUrl: `${url}/get`,
  insertUrl: `${url}/post`,
  updateUrl: `${url}/put`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const resourcesSource = createStore({
  key: "Value",
  loadUrl: `/api/Resources/LookupResources`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const mapStateToProps = state => {
  return {
    user: state.authentication.user
  };
};

const TargetsGrid = connect(mapStateToProps)(({ user }) => (
  <DataGrid
    key={"lineID"}
    dataSource={dataSource}
    showBorders={true}
    style={{ maxHeight: 500, fontSize: "1rem" }}
    allowColumnReordering={true}
    allowColumnResizing={true}
    rowAlternationEnabled={true}
    remoteOperations={true}
    onEditorPreparing={e => {
      //Key insertAllow editNotAllow
      if (e.parentType === "dataRow" && e.dataField === "lineID")
        e.editorOptions.readOnly = !e.row.inserted;
    }}
  >
    <FilterRow visible={true} />
    <HeaderFilter visible={false} />
    <GroupPanel visible={true} />
    <Scrolling mode={"virtual"} />
    <Editing
      mode={"form"}
      allowAdding={user.roleID === UserRoles.SUPERVISOR}
      allowDeleting={user.roleID === UserRoles.SUPERVISOR}
      allowUpdating={user.roleID === UserRoles.SUPERVISOR}
      useIcons={true}
    />
    <Column dataField={"lineID"} caption={i18n()._(t`Linea`)} key>
      <RequiredRule />
      <Lookup
        dataSource={resourcesSource}
        allowClearing
        valueExpr={"value"}
        displayExpr={"text"}
      />
    </Column>
    <Column dataField={"tag"} caption={`Tag`}>
      <RequiredRule />
    </Column>
    <Column dataField={"targetSummary"} caption={i18n()._(t`Target Resumen`)}>
      <NumericRule ignoreEmptyValue={false} />
    </Column>
    <Column dataField={"targetOEE"} caption={i18n()._(t`Target OEE`)}>
      <NumericRule ignoreEmptyValue={false} />
    </Column>
    <Column dataField={"updatedBy"} caption={i18n()._(t`Actualizado por`)}>
      <FormItem visible={false} />
    </Column>
    <Column
      dataField={"updateDate"}
      format={"dd/MM/yyyy HH:mm:ss"}
      dataType={"date"}
      caption={i18n()._(t`Ultima actualización `)}
    >
      <FormItem visible={false} />
    </Column>
  </DataGrid>
));
