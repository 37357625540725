import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { Column, DataGrid, Scrolling } from "devextreme-react/data-grid";
import { UserRoles } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { RecognizeModal } from "./Recognize";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

const url = "/api/stops";

const dataSource = createStore({
  key: ["order", "lineID", "stopID"],
  loadUrl: `${url}/get`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const GridOptions = ({ row, role, onClick }) => {
  return role === UserRoles.SUPERVISOR || role === UserRoles.OPERATOR ? (
    <Button
      onClick={() => onClick("recognize", row.data)}
      size="mini"
      color="red"
    >
      <Trans>Reconocer</Trans>
    </Button>
  ) : null;
};

class UnrecognizedGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.recognizeRef = React.createRef();
  }

  /**
   * For use with refs. Show recognize modal of inner Recognize component.
   */
  showRecognize(stop) {
    let recognize = this.recognizeRef.current;
    if (recognize) {
      recognize.show(stop);
    }
  }

  actionInRow(action, stop) {
    let recognize = this.recognizeRef.current;
    if (action === "recognize" && recognize) {
      recognize.show(stop);
    }
  }

  onRecognized(stop, reason) {
    //Callback to parent to inform recognized action
    if (this.props.onRecognized) this.props.onRecognized(stop, reason);
  }

  render() {
    return (
      <>
        <RecognizeModal
          ref={this.recognizeRef}
          recognized={(s, r) => this.onRecognized(s, r)}
        />
        <DataGrid
          key={["order", "lineID", "stopID"]}
          dataSource={{
            store: dataSource,
            filter: [
              ["lineID", "=", this.props.line],
              "and",
              ["recognized", "=", false]
            ]
          }}
          showBorders={false}
          style={{
            maxHeight: this.props.maxHeight ? this.props.maxHeight : 160,
            fontSize: "1rem"
          }}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          remoteOperations={true}
          loadPanel={{ enabled: false }}
          onEditorPreparing={e => {
            //Key insertAllow editNotAllow
            if (e.parentType === "dataRow" && e.dataField === "code")
              e.editorOptions.readOnly = !e.row.inserted;
            if (e.parentType === "dataRow" && e.dataField === "lineID")
              e.editorOptions.readOnly = !e.row.inserted;
          }}
          onContentReady={e => {
            if (this.props.counter)
              this.props.counter(e.component.totalCount());
          }}
        >
          <Scrolling mode={"virtual"} />
          <Column
            visible={
              this.props.user.roleID === UserRoles.SUPERVISOR ||
              this.props.user.roleID === UserRoles.OPERATOR
            }
            width={100}
            alignment={"center"}
            cellComponent={r => (
              <GridOptions
                onClick={(a, v) => this.actionInRow(a, v)}
                role={this.props.user.roleID}
                row={r}
              />
            )}
          />
          <Column caption={i18n()._(t`ID`)} width={70} dataField={"stopID"} />
          <Column caption={i18n()._(t`Split`)} width={70} dataField={"split"} />
          <Column caption={i18n()._(t`Motivo`)} dataField={"reason"} />
          <Column
            caption={i18n()._(t`Desde`)}
            sortOrder={"desc"}
            sortIndex={1}
            dataField={"startDate"}
            dataType={"datetime"}
            format={"dd/MM/yyyy HH:mm:ss"}
          />
          <Column
            caption={i18n()._(t`Hasta`)}
            dataField={"endDate"}
            dataType={"datetime"}
            format={"dd/MM/yyyy HH:mm:ss"}
          />
        </DataGrid>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.authentication
  };
};

const UnrecognizedStops = React.forwardRef((props, ref) => (
  <UnrecognizedGrid
    maxHeight={props.maxHeight}
    counter={props.counter}
    user={props.auth.user}
    fetching={props.fetching}
    line={props.line}
    onRecognized={props.onRecognized}
    ref={ref}
  />
));

export default connect(
  mapStateToProps, //stateToProps
  null, //dispatchToProps
  null, //merge props
  { forwardRef: true } //options
)(UnrecognizedStops);
