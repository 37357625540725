import { terminalConstants } from "./actions";

let terminal = JSON.parse(localStorage.getItem("terminal"));
const initialState = terminal
  ? { configured: true, terminal }
  : { configured: false };

export default function _terminal(state = initialState, action) {
  switch (action.type) {
    case terminalConstants.TERMINAL_CONFIGURE:
      return {
        configured: true,
        terminal: action.terminal,
        globalParameters: state.globalParameters
      };
    case terminalConstants.TERMINAL_RESET:
      return {
        configured: false,
        terminal: undefined,
        globalParameters: state.globalParameters
      };
    case terminalConstants.TERMINAL_SET_PARAMETERS:
      return {
        configured: state.configured,
        terminal: state.terminal,
        globalParameters: action.parameters
      };
    default:
      return state;
  }
}
