import React from "react";
import { connect } from "react-redux";
import { I18nProvider as Provider } from "@lingui/react";
import en from "../../locales/en/messages.js";
import es from "../../locales/es/messages.js";
import dxEs from "devextreme/localization/messages/es.json";
import dxEn from "devextreme/localization/messages/en.json";
import { locale, loadMessages } from "devextreme/localization";
import { ConnectProvider } from "./components/ConnectProvider.js";

loadMessages(dxEs);
loadMessages(dxEn);

const I18nProvider = props => {
  //Devexpress provider
  locale(props.language);
  //Lingui provider
  return (
    <Provider language={props.language} catalogs={{ en, es }}>
      <ConnectProvider>{props.children}</ConnectProvider>
    </Provider>
  );
};

const mapStateToProps = state => {
  return {
    language: state.i18n.language
  };
};

export default connect(mapStateToProps)(I18nProvider);
