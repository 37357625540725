import React from "react";
import { connect } from "react-redux";
import { Segment, Header } from "semantic-ui-react";
import {
  Column,
  DataGrid,
  FormItem,
  FilterRow,
  RequiredRule,
  RangeRule,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  Lookup
} from "devextreme-react/data-grid";
import { UserRoles } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

const mapStateToProps = state => {
  return {
    user: state.authentication.user
  };
};

export default class ProductSpeedsConfig extends React.Component {
  render = () => (
    <Segment basic padded>
      <Header as="h2">
        <Trans>Configuración de Velocidades de producto</Trans>
        <Header.Subheader>
          <Trans>Actualiza la información de las velocidades.</Trans>
        </Header.Subheader>
      </Header>
      <ProductSpeedGrid />
    </Segment>
  );
}

ProductSpeedsConfig.getMenuItem = () => <Trans>Velocidades</Trans>;
ProductSpeedsConfig.getPermissions = userLevel =>
  userLevel === UserRoles.SUPERVISOR || userLevel === UserRoles.ADMINISTRATOR;

const url = "/api/productspeeds";

const dataSource = createStore({
  key: ["code", "lineID"],
  loadUrl: `${url}/get`,
  insertUrl: `${url}/post`,
  updateUrl: `${url}/put`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const resourcesSource = createStore({
  key: "Value",
  loadUrl: `/api/Resources/LookupResources`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const productsSource = createStore({
  key: "Value",
  loadUrl: `/api/products/lookup`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const ProductSpeedGrid = connect(mapStateToProps)(props => (
  <DataGrid
    key={["code", "lineID"]}
    dataSource={dataSource}
    showBorders={true}
    style={{ maxHeight: 500, fontSize: "1rem" }}
    allowColumnReordering={true}
    allowColumnResizing={true}
    rowAlternationEnabled={true}
    remoteOperations={true}
    onEditorPreparing={e => {
      //Key insertAllow editNotAllow
      if (e.parentType === "dataRow" && e.dataField === "code")
        e.editorOptions.readOnly = !e.row.inserted;
      if (e.parentType === "dataRow" && e.dataField === "lineID")
        e.editorOptions.readOnly = !e.row.inserted;
    }}
  >
    <FilterRow visible={true} />
    <HeaderFilter visible={false} />
    <GroupPanel visible={true} />
    <Scrolling mode={"virtual"} />
    <Editing
      mode={"form"}
      allowAdding={props.user.roleID === UserRoles.SUPERVISOR}
      allowDeleting={props.user.roleID === UserRoles.SUPERVISOR}
      allowUpdating={props.user.roleID === UserRoles.SUPERVISOR}
      useIcons={true}
    />
    <Column dataField={"code"} caption={i18n()._(t`Código`)}>
      <RequiredRule />
      <Lookup
        dataSource={productsSource}
        allowClearing
        valueExpr={"value"}
        displayExpr={"text"}
      />
    </Column>
    <Column dataField={"lineID"} caption={i18n()._(t`Linea`)}>
      <RequiredRule />
      <Lookup
        dataSource={resourcesSource}
        allowClearing
        valueExpr={"value"}
        displayExpr={"text"}
      />
    </Column>
    <Column dataField={"speed"} caption={i18n()._(t`Velocidad`)}>
      <RequiredRule />
      <RangeRule
        min={0}
        max={99999.999}
        message={i18n()._(t`El factor debe ser un número mayor o igual a 0`)}
      />
    </Column>
    <Column dataField={"updatedBy"} caption={i18n()._(t`Actualizado por`)}>
      <FormItem visible={false} />
    </Column>
    <Column
      dataField={"updateDate"}
      dataType={"date"}
      format={"dd/MM/yyyy HH:mm:ss"}
      caption={i18n()._(t`Ultima actualización `)}
    >
      <FormItem visible={false} />
    </Column>
  </DataGrid>
));
