import React, { useRef, useEffect, useState } from "react";
import { Segment, Form, Header, Button } from "semantic-ui-react";
import { SelectBox } from "devextreme-react/select-box";
import { Shifts } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { DateBox } from "devextreme-react/date-box";
import { RadioGroup, Item } from "devextreme-react/radio-group";
import { Pareto } from "./Pareto";
import {
  getShiftLimitsFixed,
  getTodayLimits as getDayLimits
} from "../../utils/ShiftHelper";
import { OrdersSummary } from "../orders/OrdersSummary";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

/**
 * Componente para visualizar resumen de paradas.
 */
export const Summary = props => {
  useEffect(() => {
    document.title = "OEE++ Paradas";
  }, []);

  const [filter, setFilter] = useState(undefined);

  return (
    <>
      <Segment padded="very" style={{ margin: 20 }}>
        <Header as="h2">
          <Trans>Resumen de paradas</Trans>
          <Header.Subheader>
            <Trans>
              Análisis de paradas por línea, según periodo especificado.
            </Trans>
          </Header.Subheader>
        </Header>
        <FilterOptions onApplyFitler={f => setFilter(f)} />
        <FilteredData filter={filter} />
      </Segment>
    </>
  );
};

/**
 * Componente que agrupa datos a mostrar.
 */
const FilteredData = ({ filter }) => {
  if (typeof filter == "undefined") return null;

  const { lineId, date, period } = filter;

  //Validate
  if (
    typeof lineId !== "number" ||
    typeof date == "undefined" ||
    typeof period == "undefined"
  )
    return i18n()._(t`Por favor complete los datos de filtro`);

  let { start, end } = getPeriodRange(date, period);

  return (
    <>
      <Header as="h3">
        <Trans>Ordenes</Trans>
      </Header>
      <OrdersSummary line={lineId} startDate={start} endDate={end} />
      <Header as="h3">
        <Trans>Paradas</Trans>
      </Header>
      <Pareto.Stops line={lineId} startDate={start} endDate={end} useColors />
    </>
  );
};

/**
 * Componente para seleccionar filtros del resumen.
 */
const FilterOptions = ({ onApplyFitler }) => {
  var lineRef = useRef(null);

  const [state, setState] = useState({
    sectorId: undefined,
    lineId: undefined,
    date: new Date(),
    period: undefined
  });

  let disableLine = typeof state.sectorId !== "number";

  return (
    <>
      <Form>
        <Form.Group>
          <Form.Field>
            <label>
              1. <Trans>Sector</Trans>
            </label>
            <SelectBox
              allowClearing
              dataSource={sectorsSource}
              valueExpr={"value"}
              displayExpr={"text"}
              onValueChanged={e => {
                lineRef.current.instance.reset();
                setState({ ...state, sectorId: e.value, lineId: undefined });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>
              2. <Trans>Línea</Trans>
            </label>
            <SelectBox
              ref={lineRef}
              disabled={disableLine}
              dataSource={lineInSector(state.sectorId)}
              allowClearing
              valueExpr={"value"}
              displayExpr={"text"}
              onValueChanged={e => setState({ ...state, lineId: e.value })}
            />
          </Form.Field>
          <Form.Field>
            <label>
              3. <Trans>Fecha</Trans>
            </label>
            <DateBox
              defaultValue={new Date()}
              placeholder={i18n()._(t`Seleccionar...`)}
              onValueChanged={e => setState({ ...state, date: e.value })}
            />
          </Form.Field>
          <Form.Field>
            <label>
              4. <Trans>Periodo</Trans>
            </label>
            <RadioGroup
              layout={"horizontal"}
              onValueChanged={e => setState({ ...state, period: e.value.text })}
            >
              {Periods.map(i => (
                <Item key={i.key} text={i.text}></Item>
              ))}
            </RadioGroup>
          </Form.Field>
        </Form.Group>
        <Button
          onClick={() => onApplyFitler(state)}
          floated="right"
          icon="checkmark"
          content={i18n()._(t`Aplicar`)}
        />
        <div style={{ clear: "both" }}></div>
      </Form>
    </>
  );
};

/**
 * Funciones para consumo de api.
 */
const sectorsSource = createStore({
  key: "Value",
  loadUrl: `/api/sectors/lookup`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const resourcesSource = createStore({
  key: "Value",
  loadUrl: `/api/Resources/LookupResources`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

function lineInSector(sectorID) {
  return {
    store: resourcesSource,
    filter: typeof sectorID === "number" ? ["sectorID", "=", sectorID] : null
  };
}

function getPeriodRange(date, period) {
  let periodObject = Periods.find(p => p.text === period);
  switch (periodObject.key) {
    case "TM":
      return getShiftLimitsFixed(date, Shifts.Morning);
    case "TT":
      return getShiftLimitsFixed(date, Shifts.Afternoon);
    case "TN":
      return getShiftLimitsFixed(date, Shifts.Night);
    case "RD":
      return getDayLimits(date);
    case "RO":
      return { start: undefined, end: undefined };
    default:
      return { start: undefined, end: undefined };
  }
}

const Periods = [
  { key: "TM", text: "Turno mañana" },
  { key: "TT", text: "Turno tarde" },
  { key: "TN", text: "Turno noche" },
  { key: "RD", text: "Resumen del día" },
  { key: "RO", text: "Resumen orden actual" }
];
