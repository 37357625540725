import React, { useEffect, useState } from "react";
import { Segment, Header, Dropdown } from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import { TagsUpdateDiagnostic } from "./TagsUpdateDiagnostic";
import { TagsExecutionDiagnostic } from "./TagsExecutionDiagnostic";
import { OpcServersDiagnostic } from "./OpcServersDiagnostic";

//Registered diagnostics visors
const diagnosticOptions = [
  {
    key: "TagsUpdateOverTime",
    text: "Tags Update Over Time (ms)",
    value: "TagsUpdateOverTime"
  },
  {
    key: "TagsExecutionHistory",
    text: "Tags Execution History",
    value: "TagsExecutionHistory"
  },
  {
    key: "OpcServersStatus",
    text: "OPC Servers Status",
    value: "OpcServersStatus"
  }
];

const Diagnostics = props => {
  useEffect(() => {
    document.title = "OEE++ Diagnostics";
  }, []);
  //Selected values
  let [value, setValues] = useState([]);

  //Diagnostics page
  return (
    <Segment.Group style={{ margin: 20 }}>
      <Segment padded="very">
        <Header icon>
          <Trans>Visor de diagnostico</Trans>
        </Header>
        <DiagnosticsSelector
          options={diagnosticOptions}
          onChange={(e, { value }) => setValues(value)}
          values={value}
        />
      </Segment>
      {value.includes("TagsUpdateOverTime") && <TagsUpdateDiagnostic />}
      {value.includes("TagsExecutionHistory") && <TagsExecutionDiagnostic />}
      {value.includes("OpcServersStatus") && <OpcServersDiagnostic />}
    </Segment.Group>
  );
};

/**
 * Dropdown selector.
 */
const DiagnosticsSelector = ({ options, values, onChange }) => (
  <Dropdown
    options={options}
    placeholder="Elegir evento"
    search
    selection
    fluid
    multiple
    values={values}
    onChange={onChange}
  />
);

export default Diagnostics;
