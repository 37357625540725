import { navigationConstants } from "./actions";

const initialState = { resources: [] };

export default function _navigation(state = initialState, action) {
  switch (action.type) {
    case navigationConstants.NAVIGATION_LOAD_RESOURCES:
      return { resources: action.payload };
    default:
      return state;
  }
}
