import React from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Image, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { setLanguage } from "../i18n/actions";
import NavigationTree from "./NavigationTree";
import { UserRoles } from "../../utils/Consts";
import SectorsList from "./SectorsList";
import { resolveNavigationItems } from "../modules-manager/resolver";
import { loginActions } from "../login/actions";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";
import { generateReport } from "./service";

const Navigation = ({ user, navigation, modules, setLanguage }) => {
  let administrator = user.roleID === UserRoles.ADMINISTRATOR;
  let supervisor = user.roleID === UserRoles.SUPERVISOR;
  let operator = user.roleID === UserRoles.OPERATOR;
  let consultant = user.roleID === UserRoles.CONSULTANT;

  let fullName = "unknown user";
  if (user) {
    let { firstName, lastName } = user;
    fullName = `${firstName} ${lastName}`;
  }

  let { navigationItems, stopItems } = resolveNavigationItems(user, modules, {
    navigation
  });

  return (
    <>
      <Menu attached>
        <Menu.Item
          as={Link}
          to="/"
          className="logo borderless fitted"
          style={{ padding: "10px" }}
        >
          <Image src="/Argensun-Alt-White-new.png" style={{ height: 18 }} />
        </Menu.Item>

        <div className="borderless fitted item">
          <span
            style={{
              height: "16px",
              borderLeft: "1px solid #fafafa"
            }}
          />
        </div>

        {(supervisor || consultant || administrator || operator) && (
          <NavigationTree tree={navigation.resources} />
        )}

        <Dropdown item text={i18n()._(t`PARADAS`)} simple>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/paradas/listado">
              <Trans>Listado de paradas</Trans>
            </Dropdown.Item>
            {stopItems}
            <Dropdown.Item as={Link} to="/paradas/resumen2">
              <Trans>Resumen de paradas</Trans>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {(supervisor || consultant || administrator) && (
          <>
            <Dropdown item text={i18n()._(t`INDICES`)} simple>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/indexes/order">
                  <Trans>Índices por Silo/Secuencia</Trans>
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/indexes/shift">
                  <Trans>Índices por turno</Trans>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <SectorsList tree={navigation.resources} />
          </>
        )}

        {navigationItems}

        {(supervisor || consultant || administrator) && (
          <Dropdown item text={i18n()._(t`REPORTES`)} simple>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => generateReport()}>
                <Icon name="download" />
                <Trans>Reporte OEE</Trans>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {administrator && (
          <>
            <Menu.Item content={"LOGS"} as={Link} to="/logs" />
            <Menu.Item content={"DIAGNOSTICS"} as={Link} to="/diagnostics" />
          </>
        )}

        <Menu.Menu position="right">
          <Dropdown item simple icon="language">
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={(_, data) =>
                  setLanguage(data.children.toString().toLowerCase())
                }
              >
                ES
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(_, data) =>
                  setLanguage(data.children.toString().toLowerCase())
                }
              >
                EN
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item icon="user" simple>
            <Dropdown.Menu>
              <Dropdown.Header>{fullName}</Dropdown.Header>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => loginActions.logout()}
                as={Link}
                to="/login"
              >
                <Trans>Cerrar sesión</Trans>
              </Dropdown.Item>
              {user && !operator && !consultant && (
                <>
                  <Dropdown.Item as={Link} to="/configuracion">
                    <Trans>Configuración</Trans>
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    </>
  );
};

const mapStateToProps = state => {
  return {
    modules: state.modules.registered,
    user: state.authentication.user,
    navigation: state.navigation
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguage: language => dispatch(setLanguage(language))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
