import React, { useState, useEffect } from "react";
import { Segment, Header, Rail, Label, Progress } from "semantic-ui-react";
import CircularProgressbar from "react-circular-progressbar";
import { StopsExplorer } from "../stops/StopsExplorer";
import { Pareto } from "../stops/Pareto";
import OrderInformation from "./OrderInformation";
import DefaultLayout from "./DefaultLayout";
import ConfigurableLayout from "./ConfigurableLayout";
import { indicatorsActions } from "./actions";
import { getOPCStatus } from "./service";
import { StopsManager } from "../stops/StopsManager";
import ReactMinimalPieChart from "react-minimal-pie-chart";
import StopIndicator from "../stops/components/StopIndicator";
import { useShiftIndexes } from "./hooks/useShiftIndexes";
import { useShiftNavigation } from "./hooks/useShiftNavigation";
import { LineAlert } from "../orders/OrderAlert";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";
import { getLineTargets } from "../orders/service";
import { Switch } from "devextreme-react/switch";
import { validateOrderAsync, getHeaders } from "../orders/service";
import notify from "devextreme/ui/notify";

export const Line = ({
  line,
  indexes,
  lastUpdate,
  modules,
  user,
  currentStop,
  navigation
}) => {
  const [targets, setTargets] = useState([]);
  const [target, setTarget] = useState(null);

  /*Inicia proceso observador para actualizar cada x tiempo  */
  useEffect(() => {
    indicatorsActions.startWatcher();
    getLineTargets().then(tgs => setTargets(tgs));
  }, []);
  /*Verifica y actualiza si es una linea distinta a la anterior*/
  useEffect(() => {
    indicatorsActions.setLine(line);
  }, [line]);
  useEffect(() => {
    let filt = targets.filter(x => x.lineID === parseInt(line));
    if (filt.length > 0) {
      setTarget(filt[0]);
    }
  }, [line, targets]);
  /*Navega por turno */
  const navigate = useShiftNavigation();
  const shiftChange = day => navigate(line, day);

  const {
    productCode,
    productName,
    startDate,
    lot,
    order,
    oee,
    throughput,
    acceptance,
    utilization,
    desiredProductionQuantity,
    productionQuantity,
    productionSpeed,
    desiredProductionSpeed,
    prevOrderEndDate,
    validation
  } = indexes;

  const {
    shift,
    oeeShift,
    throughputShift,
    acceptanceShift,
    utilizationShift,
    mtbfShift,
    mttrShift
  } = useShiftIndexes();

  return (
    <>
      <LineAlert line={line} />
      <ConfigurableLayout layout={DefaultLayout}>
        <div key={"oee"}>
          <CircularIndicator
            variableName={i18n()._(t`Línea ${line} - OEE Silo/Secuencia.`)}
            value={oee}
            relativeValue={oeeShift}
            shift={shift}
            color={
              target && oee >= target.targetOEE / 100 ? "#31aca6" : "#ec5453"
            }
          />
        </div>
        <div key="progress">
          <ProgressIndicator
            productionQuantity={productionQuantity}
            desiredProductionQuantity={desiredProductionQuantity}
            line={line}
            lot={lot}
            indexes={indexes}
            user={user}
            modules={modules}
            currentStop={currentStop}
          />
        </div>
        <div key="order">
          <OrderIndicator
            productCode={productCode}
            productName={productName}
            lastUpdate={lastUpdate}
            startDate={startDate}
            order={order}
            lot={lot}
            line={line}
            validation={validation}
            user={user}
          />
        </div>
        <div
          key={"stopsmanager"}
          defaultValue={true}
          text={i18n()._(t`Gestión de paradas`)}
          optional={1}
        >
          <StopsManager
            line={line}
            lot={lot}
            lotStartDate={prevOrderEndDate}
            navigation={navigation}
            currentStop={currentStop}
            productionSpeed={productionSpeed}
            desiredProductionSpeed={desiredProductionSpeed}
            mtbfShift={mtbfShift}
            mttrShift={mttrShift}
          />
        </div>
        <div key={"stops"}>
          <StopsExplorer
            line={line}
            navigate={shiftChange}
            onRecognized={indicatorsActions.update}
            lastUpdate={lastUpdate}
          />
        </div>
        <div
          key={"utilization"}
          defaultValue={true}
          text={i18n()._(t`Utilization de Silo/Secuencia`)}
          optional={1}
        >
          <CircularIndicator
            variableName={i18n()._(t`Utilization de Silo/Secuencia`)}
            value={utilization > 0 ? utilization : 0}
            relativeValue={utilizationShift > 0 ? utilizationShift : 0}
            shift={shift}
            color={"#31aca6"}
          />
        </div>
        <div
          key={"throughput"}
          defaultValue={true}
          text={i18n()._(t`Throughput de Silo/Secuencia`)}
          optional={1}
        >
          <CircularIndicator
            variableName={i18n()._(t`Throughput de Silo/Secuencia`)}
            value={throughput > 0 ? throughput : 0}
            relativeValue={throughputShift > 0 ? throughputShift : 0}
            shift={shift}
            color={"#48a7f3"}
          />
        </div>
        <div
          key={"acceptance"}
          defaultValue={false}
          text={i18n()._(t`Acceptance de Silo/Secuencia`)}
          optional={1}
        >
          <CircularIndicator
            variableName={i18n()._(t`Acceptance de Silo/Secuencia`)}
            value={acceptance > 0 ? acceptance : 0}
            relativeValue={acceptanceShift > 0 ? acceptanceShift : 0}
            shift={shift}
            color={"#ec5453"}
          />
        </div>
        <div
          key={"stoptime"}
          defaultValue={true}
          text={i18n()._(t`Parada actual`)}
          optional={1}
        >
          <CurrentStop
            variableName={i18n()._(t`Parada actual`)}
            currentStop={currentStop}
          />
        </div>
      </ConfigurableLayout>
    </>
  );
};

export const OrderIndicator = ({
  user,
  productCode,
  productName,
  lot,
  lastUpdate,
  startDate,
  line,
  order
}) => {
  const [loading, setLoading] = useState(true);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    let formData = new FormData();
    let filter = [
      ["lineID", "=", line],
      "and",
      ["orderID", "=", order],
      "and",
      ["lot", "=", lot]
    ];

    formData.append("filter", JSON.stringify(filter));
    fetch(`/api/orders?${new URLSearchParams(formData)}`, {
      headers: getHeaders(),
      method: "GET"
    })
      .then(r => r.json())
      .then(r => {
        if (!r || !r.data || !r.data[0]) return;

        setValidation(r.data[0].validation);
        setLoading(false);
      })
      .catch(err => console.error(err));
  }, [line, order, lot]);

  const switchChanged = e => {
    let text = e.value
      ? i18n()._(t`Silo/Secuencia validada con éxito`)
      : i18n()._(t`Silo/Secuencia desvalidada con éxito`);

    validateOrderAsync(
      order,
      e.value,
      user.userID,
      r => notify(text, "success", 1500),
      r => notify(r, "error", 5000)
    );
    setValidation(e.value);
  };

  return (
    <>
      <Segment style={{ height: "100%", overflow: "hidden" }}>
        <Header as="h5">
          <Trans>Información silo/secuencia</Trans>
          <div className="field-validacion">
            <div style={{ display: "inline" }}>
              <Switch
                switchedOnText={i18n()._(t`SI`)}
                switchedOffText={i18n()._(t`NO`)}
                value={validation}
                defaultValue={false}
                onValueChanged={switchChanged}
                disabled={loading || !order}
              />
            </div>
            <div className="validacion"> {i18n()._(t`Validación`)}</div>
          </div>
        </Header>
        <OrderInformation
          order={order}
          productCode={productCode}
          productName={productName}
          lot={lot}
          startDate={startDate}
        />
        <Pareto.Stops lastUpdate={lastUpdate} line={line} limit={5} useColors />
      </Segment>
    </>
  );
};

export const FlexibleIndicator = ({ children, title, basic, noPadding }) => {
  const style = {
    segment: { height: "100%", padding: noPadding ? "0px" : undefined },
    header: { padding: "10px" }
  };
  return (
    <Segment style={style.segment} basic={basic}>
      <Rail attached internal position="left">
        <Header style={style.header} textAlign="left" as="h5" content={title} />
      </Rail>
      {children}
    </Segment>
  );
};

export const ProgressIndicator = ({
  currentStop,
  modules = [],
  indexes,
  lot,
  user,
  line,
  productionQuantity = 0,
  desiredProductionQuantity = 0,
  hookComponents
}) => {
  /** A continuación se iteran los modulos que modifican
   * el panel de Indicadores.
   * Si el modulo utiliza esta opción, debe definir el componente
   * en oee_indicators_menu.
   */
  let menuExtends = [];
  Object.keys(modules).forEach((key, idx) => {
    let Component = window.modules[key];
    if (typeof Component == "undefined" || !Component.oee_indicators_menu)
      return;

    Component = Component.oee_indicators_menu;
    var role = user.roleID;
    var hasPermissions = Component.getPermissions(role);

    if (hasPermissions) {
      menuExtends.push(
        <Component
          key={idx}
          lot={lot}
          indexes={indexes}
          currentStop={currentStop}
          user={user}
          line={line}
        />
      );
    }
  });

  const noPaddingNorMargin = { padding: 0, margin: 0 };
  const percentStyles = {
    position: "absolute",
    height: "4px",
    bottom: "-6px",
    width: 1,
    backgroundColor: "#9e9e9e"
  };

  return (
    <>
      <Segment style={{ height: "100%", overflow: "hidden" }}>
        <Segment basic clearing style={noPaddingNorMargin}>
          <Header style={noPaddingNorMargin} as="h5" floated="left">
            {i18n()._(t`Progreso: ${productionQuantity} kilos/piezas`)}
          </Header>
          <Header style={noPaddingNorMargin} as="h5" floated="right">
            {desiredProductionQuantity}
          </Header>
        </Segment>

        <div style={{ position: "relative", overflow: "hidden" }}>
          <span style={{ ...percentStyles, left: "10%" }}></span>
          <span style={{ ...percentStyles, left: "50%" }}></span>
          <span style={{ ...percentStyles, left: "90%" }}></span>
          <Progress
            className={"indicator"}
            value={productionQuantity}
            precision={0}
            total={desiredProductionQuantity}
            progress
            size={"small"}
            color={"blue"}
          />
        </div>
        {menuExtends}
      </Segment>
    </>
  );
};

const SpeedIndicator = ({
  productionSpeed = 0,
  desiredProductionSpeed = 1,
  line
}) => {
  const [run, setStatus] = useState(null);
  /**Timer que actualiza baliza */
  useEffect(() => {
    const updateRun = () => getOPCStatus(line).then(res => setStatus(res.run));
    updateRun();
    const interval = setInterval(updateRun, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [line]);

  //Status to colors
  let greenIcon =
    typeof run != "undefined" && run === true ? "#79D799" : "#DDDDDD";
  let redIcon =
    typeof run != "undefined" && run === false ? "#EC5453" : "#DDDDDD";

  return (
    <>
      <Segment style={{ height: "100%" }}>
        <div style={{ paddingLeft: "22%", paddingRight: "22%" }}>
          <Header as="h5" textAlign="center">
            <Trans>Velocidad</Trans>
          </Header>
          <CircularProgressbar
            percentage={(productionSpeed * 100) / desiredProductionSpeed}
            strokeWidth={5}
            text={`${(productionSpeed * 100) / desiredProductionSpeed}%`}
            styles={{
              path: { stroke: `#66D18A`, strokeLinecap: "butt" },
              text: { fill: "#66D18A", fontSize: "22px" }
            }}
          />
        </div>
        <Rail attached internal position="left">
          <Segment basic textAlign="left">
            <Trans>Actual</Trans>
            <br />
            {productionSpeed}
          </Segment>
        </Rail>

        <Rail attached internal position="right">
          <Segment basic textAlign="right">
            <Trans>Nominal</Trans>
            <br />
            {desiredProductionSpeed}
          </Segment>
        </Rail>
        <div style={{ textAlign: "center", marginTop: 5 }}>
          <Label
            size="mini"
            circular
            style={{ backgroundColor: greenIcon }}
          ></Label>
          <Label
            size="mini"
            circular
            style={{ backgroundColor: redIcon }}
          ></Label>
        </div>
      </Segment>
    </>
  );
};

const CircularIndicator = ({
  variableName,
  value = 0,
  relativeValue = 0,
  shift,
  color,
  max = 999
}) => (
  <Segment
    style={{
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    }}
  >
    <Header style={{ flex: "0 1 auto", marginBottom: 0 }} as="h5">
      {variableName}
    </Header>
    <ReactMinimalPieChart
      style={{
        flex: "1 1 auto",
        height: "100%",
        padding: "0px",
        overflow: "hidden"
      }}
      animate={false}
      animationDuration={500}
      animationEasing="ease-out"
      cx={50}
      cy={50}
      data={[{ color, value: value * 100 }]}
      label={({ color, data, dataIndex, ...props }) => (
        <text fill={color} dominantBaseline="middle" {...props}>
          {`${
            data[0].percentage > 999 ? "999+" : data[0].percentage.toFixed(0)
          }%`}
        </text>
      )}
      labelPosition={0}
      labelStyle={{
        fontFamily: "sans-serif",
        fontSize: "25px"
      }}
      background={"#E5E5E5"}
      lengthAngle={360}
      lineWidth={10}
      onClick={undefined}
      onMouseOut={undefined}
      onMouseOver={undefined}
      paddingAngle={0}
      radius={50}
      ratio={1}
      rounded={false}
      startAngle={-90}
      totalValue={100}
    />

    <div style={{ flex: "0 1 auto" }}>
      <Segment.Group
        horizontal
        style={{ boxShadow: "none", border: "none", margin: 0, padding: 0 }}
      >
        <Segment style={{ padding: 0, margin: 0, textAlign: "left" }}>
          <span style={{ fontSize: 11 }}>
            <Trans>Turno</Trans>
          </span>
          <br />
          {shift}
        </Segment>
        <Segment style={{ padding: 0, margin: 0, textAlign: "right" }}>
          <span style={{ fontSize: 11 }}>
            <Trans>Valor</Trans>
          </span>
          <br />
          {`${relativeValue}%`}
        </Segment>
      </Segment.Group>
    </div>
  </Segment>
);

const CurrentStop = ({ variableName, currentStop }) => {
  const { stop, maintenance } = currentStop;

  return (
    <Segment
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <Header
        style={{
          flex: "0 1 auto",
          marginBottom: 0
        }}
        as="h5"
      >
        {variableName}
      </Header>
      <StopIndicator
        showTitle={false}
        stopData={stop}
        callData={maintenance}
        style={{
          flex: "1 1 auto",
          height: "100%",
          padding: "0px",
          overflow: "hidden"
        }}
      />
    </Segment>
  );
};
