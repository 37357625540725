import { useRef, useEffect } from "react";

/**
 * Hook que implementa funcionalidad de setInterval dinámica.
 */
export function useInterval(callback, delay, effectArgument) {
  const savedCallback = useRef();

  useEffect(() => {
    callback();
  }, effectArgument);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
