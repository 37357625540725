import React, { PureComponent } from "react";
import { Column, DataGrid, Scrolling } from "devextreme-react/data-grid";
import {
  getProductionRange,
  getProductionCurrentOrder,
  getLineTargets
} from "./service";
import { Segment } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

/**
 * Componente para visualizar información de produccion de las ordenes.
 * Si no se especifica comienzo y fin, se utiliza la información actual
 * de la linea asignada.
 */
export class OrdersSummary extends PureComponent {
  state = { data: [], targets: [], currentTarget: 0, loading: false };

  gridRef = React.createRef();

  //Update state cuando cambian las props.
  static getDerivedStateFromProps(nextProps, prevState) {
    let { line, startDate, endDate, limit } = nextProps;

    let currentTarget = 0;
    try {
      currentTarget = prevState.targets.find(x => x.lineID === line)
        .targetSummary;
    } catch (ex) {}

    return { ...prevState, currentTarget, line, startDate, endDate, limit };
  }

  componentDidMount() {
    this.loadOrdersData();
    getLineTargets().then(targets => this.setState({ ...this.state, targets }));
  }

  componentDidUpdate(prevProps, prevState) {
    //Si hay cambios en los filtros, actualiza
    if (
      prevState.line !== this.state.line ||
      prevState.limit !== this.state.limit ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.loadOrdersData();
    }
  }

  loadOrdersData() {
    let { line, startDate, endDate } = this.state;
    let useDates =
      typeof startDate !== "undefined" && typeof endDate !== "undefined";

    this.setState({ ...this.state, loading: true });

    if (useDates) {
      getProductionRange(line, startDate, endDate)
        .then(data =>
          this.setState({ ...this.state, data: data, loading: false })
        )
        .catch(() =>
          this.setState({ ...this.state, data: [], loading: false })
        );
    } else {
      getProductionCurrentOrder(line)
        .then(data =>
          this.setState({ ...this.state, data: data, loading: false })
        )
        .catch(() =>
          this.setState({ ...this.state, data: [], loading: false })
        );
    }
  }

  contentReady() {
    if (!this.gridRef.current) return;

    const gridInstance = this.gridRef.current.instance;
    if (gridInstance.getDataSource() != null) {
      var total = gridInstance.getTotalSummaryValue("units");
      var summary = document.getElementsByClassName(
        "dx-datagrid-summary-item dx-datagrid-text-content"
      )[0];
      if (total >= this.state.currentTarget) {
        summary.style.color = "#2E8B57";
      } else {
        summary.style.color = "#DC143C";
      }
    }
  }

  render() {
    return (
      <>
        <Segment basic loading={this.state.loading}>
          <DataGrid
            ref={this.gridRef}
            dataSource={this.state.data}
            showBorders={false}
            style={{ maxHeight: 500, fontSize: "1rem" }}
            allowColumnResizing={true}
            grouping={{ autoExpandAll: false }}
            onContentReady={e => this.contentReady(e)}
            summary={{
              totalItems: [
                {
                  column: "units",
                  summaryType: "sum",
                  alignByColumn: true,
                  displayFormat: "Total {0}"
                }
              ]
            }}
          >
            <Scrolling mode={"virtual"} />
            <Column
              dataField={"lot"}
              minWidth={120}
              caption={i18n()._(t`Lote`)}
            />
            <Column dataField={"productCode"} caption={i18n()._(t`Código`)} />
            <Column dataField={"productName"} caption={i18n()._(t`Nombre`)} />
            <Column dataField={"packs"} caption={i18n()._(t`Bultos`)} />
            <Column dataField={"wrappers"} caption={i18n()._(t`Paquetes`)} />
            <Column dataField={"units"} caption={i18n()._(t`Cantidad`)} />
            <Column dataField={"wrapperType"} caption={i18n()._(t`Unidad`)} />
          </DataGrid>
        </Segment>
      </>
    );
  }
}
