import { useState, useEffect, useRef } from "react";
import { authHeader } from "../../utils/AuthHeader";
import { OrderStatus, MttoStatus } from "../../utils/Consts";
import { getOrdersAndStopsAsync } from "../stops/service";
import { createStopReasonsDataSource } from "../../api/DataSources";
import moment from "moment";

export const API = {
  LineBreadcrumb: "LineBreadcrumb",
  OEEIndex: "OEEIndex"
};

/**
 * Custom React Hook para consumir API OEE.
 * @param {*} action Constante que indica Endpoint / Opcion a consumir.
 * @param {*} payload Valores necesitados por la acción.
 */
export const useAPI = (
  action,
  payload,
  allowReload = false,
  reloadValue = null
) => {
  const reloadWhen = allowReload ? [payload, reloadValue] : [payload];

  const [data, updateData] = useState(undefined);
  useEffect(() => {
    service(action, payload).then(json => updateData(json));
  }, reloadWhen);

  return data;
};

function service(endpoint, payload) {
  switch (endpoint) {
    case API.LineBreadcrumb:
      return getResourcesInSector(payload);
    case API.OEEIndex:
      return getOEEIndex(payload);
    default:
      return undefined;
  }
}

export function getResourcesInSector(payload) {
  let lineID = payload;

  let formData = new FormData();
  formData.append("filter", JSON.stringify(["lineID", "=", lineID]));

  return fetch(`/api/resources/sector?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data[0])
    .catch(error => console.log("Fatal error"));
}

export function getOEEIndex(payload) {
  let lineID = payload;

  let formData = new FormData();
  formData.append(
    "filter",
    JSON.stringify([
      ["lineID", "=", lineID],
      "and",
      ["status", "=", OrderStatus.Ready]
    ])
  );

  return fetch(`/api/oeeindex/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data[0])
    .catch(error => console.log("Fatal error"));
}

export function getOEEHistory(line, shiftStart) {
  let formData = new FormData();
  formData.append(
    "filter",
    JSON.stringify([
      ["lineID", "=", line],
      "and",
      ["shiftStart", "=", shiftStart]
    ])
  );

  return fetch(`/api/oeeindex/history/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data[0])
    .catch(error => console.log("Fatal error"));
}

export function getLineStatus(line) {
  let formData = new FormData();
  formData.append("lineID", line);

  return fetch(`/api/resources/status/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response)
    .catch(error => console.log("Fatal error"));
}

export async function getNavigationData(line, lotStart, start, end) {
  let [
    { stops },
    { stops: navigationStops, orders: navigationOrders }
  ] = await Promise.all([
    getOrdersAndStopsAsync(line, lotStart, moment()),
    getOrdersAndStopsAsync(line, start, end, true)
  ])
    .then(data => data)
    .catch(err => console.log("getNavigationData error", err));

  return { navigationStops, navigationOrders, stops };
}

export async function getMaintenanceData(line) {
  let stopRequest = getCurrentStop(line);
  let maintenanceRequest = getMaintenance(line);
  //Await
  let stop = await stopRequest;
  let maintenance = await maintenanceRequest;
  return { stop, maintenance };
}

export function getCurrentStop(line) {
  let formData = new FormData();
  formData.append(
    "filter",
    JSON.stringify([["endDate", "=", null], "and", ["lineID", "=", line]])
  );

  return fetch(`/api/stops/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data[0]);
}

export function getMaintenance(line) {
  let formData = new FormData();
  formData.append(
    "filter",
    JSON.stringify([
      ["resourceLineID", "=", line],
      "and",
      ["status", "<>", MttoStatus.Finished]
    ])
  );

  return fetch(`/api/maintenance/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data[0]);
}

export function getOPCStatus(line) {
  let formData = new FormData();
  formData.append("line", line);

  return fetch(`/api/opcstatus/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .catch(error => console.log("Fatal error"));
}

function getHeaders() {
  //Common values
  let headers = authHeader();
  headers["Content-Type"] = "application/x-www-form-urlencoded";

  return headers;
}

/**
 * Hook que implementa funcionalidad de setInterval dinámica.
 */
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

/**
 * Obtiene información de overview para resumen.
 */
export async function getLinesOverview(lines) {
  let overviews = [];

  let ordersRequest = getActiveOrders();
  let callsRequest = getActiveCalls();
  let stopsRequest = getCurrentStops();

  //Await
  let orders = await ordersRequest;
  let calls = await callsRequest;
  let stops = await stopsRequest;

  lines.forEach(line => {
    let overview = { line };
    overview.orderData = orders.find(order => order.lineID === line);
    overview.callData = calls.find(call => call.resourceLineID === line);
    overview.stopData = stops.find(call => call.lineID === line);
    overviews.push(overview);
  });
  return overviews;
}

/**
 * Obtiene todas las ordenes activas.
 */
export function getActiveOrders() {
  let formData = new FormData();
  formData.append("filter", JSON.stringify(["status", "=", OrderStatus.Ready]));

  return fetch(`/api/oeeindex/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data);
}

/**
 * Obtiene todas las paradas activas
 */
export function getCurrentStops() {
  let formData = new FormData();
  formData.append("filter", JSON.stringify(["endDate", "=", null]));

  return fetch(`/api/stops/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data);
}

/**
 * Obtiene todas las llamdas a mantenimiento activas.
 */
export function getActiveCalls() {
  let formData = new FormData();
  formData.append(
    "filter",
    JSON.stringify([
      ["status", "=", MttoStatus.Waiting],
      "or",
      ["status", "=", MttoStatus.Started]
    ])
  );

  return fetch(`/api/maintenance/get?${new URLSearchParams(formData)}`, {
    headers: getHeaders(),
    method: "GET"
  })
    .then(response => response.json())
    .then(response => response.data);
}
