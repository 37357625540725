import React, { useEffect, useState } from "react";
import ContainerDimensions from "react-container-dimensions";
import moment from "moment";
import { ArgumentAxis, Margin } from "devextreme-react/chart";
import { Chart } from "devextreme-react";
import { CommonSeriesSettings } from "devextreme-react/pie-chart";
import { Segment, Header, Label } from "semantic-ui-react";
import { Tooltip } from "devextreme-react/slider";
import { Trans, t } from "@lingui/macro";
import { useSelector } from "react-redux";

import useLine from "../../hooks/useLine";
import useLineStatus from "../../hooks/useLineStatus";
import { getOrdersAndStopsAsync } from "./service";
import { reduceStopsIntoRootObject, getRootStopReasons } from "./service";
import { i18n } from "../i18n/components/ConnectProvider";
import { useShiftIndexes } from "../indicators/hooks/useShiftIndexes";

function useReasonSeries() {
  const [reasonSeries, setReasonSeries] = useState([]);

  useEffect(() => {
    getRootStopReasons().then(data => {
      let newReasonSeries = data.map(sr => {
        return {
          color: sr.color,
          name: sr.reason,
          valueField: sr.reason
        };
      });

      newReasonSeries = [
        ...newReasonSeries,
        {
          color: "#55D18A",
          name: i18n()._(t`Runtime`),
          valueField: "runtime"
        },
        {
          color: "red",
          name: i18n()._(t`No reconocida`),
          valueField: "unrecognized"
        }
      ];

      setReasonSeries(newReasonSeries);
    });
  }, []);

  return reasonSeries;
}

const StopsManager = ({ navigation, lotStartDate, line, lot }) => {
  const reasonSeries = useReasonSeries();
  const [lotStops, setLotStops] = useState([]);
  const [navigationStops, setNavigationStops] = useState([]);
  const [lotData, setLotData] = useState(null);
  const [shiftData, setShiftData] = useState(null);

  // Update lot stops
  useEffect(() => {
    getOrdersAndStopsAsync(line, lotStartDate, moment())
      .then(data => setLotStops(data.stops || []))
      .catch(err => console.error(err));
  }, [line, lotStartDate]);

  // Update navigation stops
  useEffect(() => {
    getOrdersAndStopsAsync(line, navigation.start, navigation.end)
      .then(data => setNavigationStops(data.stops || []))
      .catch(err => console.error(err));
  }, [line, navigation]);

  // Calculate lot charts
  useEffect(() => {
    if (!lot || !reasonSeries || !lotStops || reasonSeries.length == 0) {
      setLotData(null);
      return;
    }

    let newLotData = reduceStopsIntoRootObject(
      lotStops,
      reasonSeries,
      lotStartDate,
      new Date()
    );

    newLotData.selector = i18n()._(t`S/Sec`);
    setLotData(newLotData);
  }, [reasonSeries, lotStops, lotStartDate]);

  //Calculate navgiation charts
  useEffect(() => {
    if (!reasonSeries || !navigationStops || reasonSeries.length == 0) {
      setShiftData(null);
      return;
    }

    let newShiftData = reduceStopsIntoRootObject(
      navigationStops,
      reasonSeries,
      navigation.start,
      navigation.end
    );
    newShiftData.selector = i18n()._(t`Turno`);
    setShiftData(newShiftData);
  }, [reasonSeries, navigationStops, navigation]);

  return (
    <Segment style={{ position: "relative", height: "100%" }}>
      <Title text={i18n()._(t`Gestión de paradas`)} />
      <div
        style={{
          minHeight: 90,
          height: "60%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          verticalAlign: "middle"
        }}
      >
        <MainteinanceIndicators />
        <ContainerDimensions>
          {({ height }) => (
            <>
              <StackStops
                dataSource={shiftData}
                series={reasonSeries}
                height={height}
              />
              <StackStops
                dataSource={lotData}
                series={reasonSeries}
                height={height}
              />
            </>
          )}
        </ContainerDimensions>
      </div>
      <SpeedAndBeaconIndicator />
    </Segment>
  );
};

const StackStops = ({ series, dataSource, height }) => {
  if (!series || !Array.isArray(series) || series.length === 0) return null;

  if (dataSource == null || Object.keys(dataSource).length === 0) return null;

  return (
    <Chart
      legend={{ visible: false }}
      size={{ width: 55, height }}
      valueAxis={{ label: { visible: false }, grid: { visible: false } }}
      barGroupWidth={25}
      dataSource={[dataSource]}
      series={series}
      //onIncidentOccurred={_ => {}}
    >
      <Margin top={10} bottom={10} left={10} right={10} />
      <ArgumentAxis label={{ visible: true }} position={"top"} />
      <CommonSeriesSettings
        argumentField={"selector"}
        type={"fullstackedbar"}
        label={{
          customizeText: e =>
            e.percentText.slice(0, -1) > 30 ? e.percentText : null,
          font: { size: 9 },
          backgroundColor: "transparent",
          visible: true,
          alignment: "right"
        }}
      />
      <Tooltip
        enabled={true}
        customizeTooltip={({ seriesName, percentText }) => ({
          text: `${seriesName} ${percentText}`
        })}
      />
    </Chart>
  );
};

function SpeedAndBeaconIndicator() {
  const { productionSpeed, desiredProductionSpeed } = useSelector(
    state => state.indicators.indexes
  );
  const line = useLine();

  return (
    <>
      <Title text={i18n()._(t`Velocidad de producción`)} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around"
        }}
      >
        <div>
          <Trans>Actual</Trans>
          <div>{(productionSpeed ? productionSpeed.toFixed(0) : 0) + "/m"}</div>
        </div>
        <div>
          <Trans>Nominal</Trans>
          <div>
            {(desiredProductionSpeed ? desiredProductionSpeed.toFixed(0) : 0) +
              "/m"}
          </div>
        </div>
        <div>
          <Trans>Baliza</Trans>
          <BeaconIndicator line={line} />
        </div>
      </div>
    </>
  );
}

function BeaconIndicator({ line }) {
  const { beaconColor } = useLineStatus(line);

  if (!line) return null;

  return (
    <div>
      <Label circular={true} style={{ backgroundColor: beaconColor }} />
    </div>
  );
}

function Title({ text }) {
  return (
    <Header as="h5" style={{ marginBottom: 0 }}>
      {text}
    </Header>
  );
}

function MainteinanceIndicators() {
  const { mtbfShift, mttrShift } = useShiftIndexes();

  return (
    <div style={{ marginTop: 10 }}>
      <b>GMTBF</b>
      <div>{(mtbfShift ? mtbfShift : 0) + " min"}</div>
      <b>GMTBR</b>
      <div>{(mttrShift ? mttrShift : 0) + " min"}</div>
    </div>
  );
}

export { StopsManager, StackStops };
