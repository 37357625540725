//polyfills for IE Support
import "core-js/features/url-search-params/index";
import "core-js/features/array/from";
import "core-js/features/object/assign";
import "core-js/features/promise";
import "core-js/features/promise";
import "whatwg-fetch";
import "core-js/features/symbol/index";
import "core-js/web/url-search-params";
import "core-js/features/math/sign";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/set";
import "formdata-polyfill";
import "core-js/features/array/includes";
import "core-js/features/string/includes";
import "core-js/features/string/trim-start";
import "core-js/features/string/trim-left";
import "core-js/features/string/trim-end";
import "core-js/features/string/trim-right";
import "core-js/features/string/repeat";
import "core-js/features/object/values";
import "core-js/features/math/trunc";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App.js";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import I18nProvider from "./features/i18n/I18nProvider";
import store from "./app/store";

require("es6-promise").polyfill();

ReactDOM.render(
  <Provider store={store}>
    <I18nProvider>
      <App />
    </I18nProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
