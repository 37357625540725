import React from "react";
import { Segment, Header } from "semantic-ui-react";
import {
  Column,
  FormItem,
  DataGrid,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  Lookup,
  RequiredRule,
  StringLengthRule
} from "devextreme-react/data-grid";
import { UserRoles } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

/**
 * OPC Types
 */
const types = [
  "Boolean",
  "Byte",
  "Double",
  "Float",
  "Int16",
  "Int32",
  "Int64",
  "SByte",
  "String",
  "UInt16",
  "UInt32",
  "UInt64"
];

const OPCTagsConfig = () => {
  return (
    <Segment basic padded>
      <Header as="h2">
        <Trans>Configuración de TAGS OPC</Trans>
        <Header.Subheader>
          <Trans>Actualiza los tags OPC.</Trans>
        </Header.Subheader>
      </Header>
      <ServersDataGrid />
    </Segment>
  );
};

OPCTagsConfig.getMenuItem = () => <Trans>Tags OPC</Trans>;
OPCTagsConfig.getPermissions = userLevel =>
  userLevel === UserRoles.ADMINISTRATOR;
//export
export default OPCTagsConfig;

class ServersDataGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filter: [], fields: [] };
    this.dataSource = createStore({
      key: "tagID",
      loadUrl: `/api/tags/get`,
      insertUrl: `/api/tags/post`,
      updateUrl: `/api/tags/put`,
      deleteUrl: `/api/tags/delete`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = authHeader();
      }
    });

    this.lookup = createStore({
      key: "Value",
      loadUrl: `/api/opcservers/lookup`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = authHeader();
      }
    });
  }

  render() {
    return (
      <DataGrid
        key={"tagID"}
        dataSource={this.dataSource}
        showBorders={true}
        style={{ maxHeight: 500, fontSize: "1rem" }}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        remoteOperations={true}
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={false} />
        <GroupPanel visible={true} />
        <Scrolling mode={"virtual"} />
        <Editing
          mode={"form"}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
          useIcons={true}
        />
        <Column
          dataField={"tagID"}
          caption={i18n()._(t`TagID`)}
          visible={false}
        >
          <FormItem visible={false} />
        </Column>
        <Column dataField={"tagName"} caption={i18n()._(t`Nombre`)}>
          <RequiredRule
            message={i18n()._(t`Debe definir un nombre del servidor`)}
          />
          <StringLengthRule
            max={100}
            message={i18n()._(
              t`El nombre debe tener como máximo 80 caracteres.`
            )}
          />
        </Column>
        <Column dataField={"tagType"} caption={i18n()._(t`Tipo`)}>
          <RequiredRule />
          <Lookup dataSource={types} />
        </Column>
        <Column dataField={"opcServerID"} caption={i18n()._(t`Server`)}>
          <RequiredRule />
          <Lookup
            dataSource={this.lookup}
            allowClearing
            valueExpr={"value"}
            displayExpr={"text"}
          />
        </Column>
        <Column dataField={"tagValue"} caption={i18n()._(t`URL`)}>
          <RequiredRule />
        </Column>
      </DataGrid>
    );
  }
}
