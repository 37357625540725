const INITIAL_STATE = {
  //Descripción de modulos externos
  registered: [],
  //Páginas de configuración de modulos
  configurationPages: [],
  //Páginas de navegación, internas al layout
  navigationPages: []
};

const modules = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "REGISTER_MODULE":
      return {
        ...state,
        registered: {
          ...state.registered,
          [action.name]: { status: "loading" }
        }
      };
    case "LOADED_MODULE":
      return {
        ...state,
        registered: { ...state.registered, [action.name]: { status: "loaded" } }
      };
    case "REGISTER":
      return {
        configurationPages: [
          ...state.configurationPages,
          action.configurationPage
        ],
        navigationPages: [...state.navigationPages, ...action.navigationPages]
      };
    case "TOGGLE_MODULE":
      return state;
    /*  return state.map(todo =>
     *  todo.id === action.id ? { ...todo, completed: !todo.completed } : todo
     *  */
    default:
      return state;
  }
};
export default modules;
