import moment from "moment";
import { Shifts } from "./Consts";

//// En caso de refactorizar para trabajar con moment objects en vez de strings,
//// refactorizar tambien componentes que lo utilizan:
////  1. StopsExplorer
////  2. Summary

/**
 * Obtiene los limites del turno para la hora y el día especificado.
 * Si no se especifica parametro, usa la fecha y hora actual.
 * @returns {start:'YYYY-MM-DD HH:mm:ss', end:'YYYY-MM-DD HH:mm:ss'}
 */
export function getShiftLimits(day = moment()) {
  let shiftStart = undefined;
  let shiftEnd = undefined;

  //Ranges
  let thatMorningStart = moment(day)
    .hour(Shifts.Morning.start.hour)
    .minute(Shifts.Morning.start.minute)
    .second(0);
  let thatMorningEnd = moment(day)
    .hour(Shifts.Morning.end.hour)
    .minute(Shifts.Morning.end.minute)
    .second(0);

  let thatAfternoonStart = moment(day)
    .hour(Shifts.Afternoon.start.hour)
    .minute(Shifts.Afternoon.start.minute)
    .second(0);
  let thatAfternoonEnd = moment(day)
    .hour(Shifts.Afternoon.end.hour)
    .minute(Shifts.Afternoon.end.minute)
    .second(0);

  //prev night
  let prevNightStart = moment(day)
    .hour(Shifts.Night.start.hour)
    .minute(Shifts.Night.start.minute)
    .second(0)
    .add(-1, "d");
  let prevNightEnd = moment(day)
    .hour(Shifts.Night.end.hour)
    .minute(Shifts.Night.end.minute)
    .second(0);
  //next night
  let thatNightStart = moment(day)
    .hour(Shifts.Night.start.hour)
    .minute(Shifts.Night.start.minute)
    .second(0);
  let thatNightEnd = moment(day)
    .hour(Shifts.Night.end.hour)
    .minute(Shifts.Night.end.minute)
    .second(0)
    .add(1, "d");

  if (day.isBetween(thatMorningStart, thatMorningEnd, "minutes", "[]")) {
    shiftStart = thatMorningStart;
    shiftEnd = thatMorningEnd;
  } else if (
    day.isBetween(thatAfternoonStart, thatAfternoonEnd, "minutes", "[]")
  ) {
    shiftStart = thatAfternoonStart;
    shiftEnd = thatAfternoonEnd;
  } else if (day.isBetween(prevNightStart, prevNightEnd, "minutes", "[]")) {
    shiftStart = prevNightStart;
    shiftEnd = prevNightEnd;
  } else if (day.isBetween(thatNightStart, thatNightEnd, "minutes", "[]")) {
    shiftStart = thatNightStart;
    shiftEnd = thatNightEnd;
  }

  return {
    start: shiftStart.format("YYYY-MM-DD HH:mm:ss"),
    end: shiftEnd.format("YYYY-MM-DD HH:mm:ss")
  };
}

/**
 * Obtiene los limites del turno especificado en el día actual,
 * sin tener en cuenta la hora.
 * @param {*} day
 * @param {*} shift
 */
export function getShiftLimitsFixed(day, shift) {
  let shiftStart = undefined;
  let shiftEnd = undefined;

  switch (shift) {
    case Shifts.Morning:
      shiftStart = moment(day)
        .hour(Shifts.Morning.start.hour)
        .minute(Shifts.Morning.start.minute)
        .second(0);
      shiftEnd = moment(day)
        .hour(Shifts.Morning.end.hour)
        .minute(Shifts.Morning.end.minute)
        .second(0);
      break;
    case Shifts.Afternoon:
      shiftStart = moment(day)
        .hour(Shifts.Afternoon.start.hour)
        .minute(Shifts.Afternoon.start.minute)
        .second(0);
      shiftEnd = moment(day)
        .hour(Shifts.Afternoon.end.hour)
        .minute(Shifts.Afternoon.end.minute)
        .second(0);
      break;
    case Shifts.Night:
      shiftStart = moment(day)
        .hour(Shifts.Night.start.hour)
        .minute(Shifts.Night.start.minute)
        .second(0);
      shiftEnd = moment(day)
        .hour(Shifts.Night.end.hour)
        .minute(Shifts.Night.end.minute)
        .second(0)
        .add(1, "d");
      break;
    default:
      return { shiftStart, shiftEnd };
  }
  return {
    start: shiftStart.format("YYYY-MM-DD HH:mm:ss"),
    end: shiftEnd.format("YYYY-MM-DD HH:mm:ss")
  };
}

/**
 * Obtiene limites del dia especificado
 */
export function getTodayLimits(day) {
  const limits = {
    start: moment(day)
      .hour(Shifts.Morning.start.hour)
      .minute(Shifts.Morning.start.minute)
      .second(0)
      .format("YYYY-MM-DD HH:mm:ss"),
    end: moment(day)
      .add(1, "days")
      .hour(Shifts.Morning.start.hour)
      .minute(Shifts.Morning.start.minute)
      .second(0)
      .format("YYYY-MM-DD HH:mm:ss")
  };
  return limits;
}

/**
 * Obtiene nombre del turno utilizando tiempo de comienzo de turno.
 * @param {*} day
 */
export function getShiftNameByStartDate(startDate) {
  const today = moment(startDate);

  if (
    today.hours() === Shifts.Night.start.hour &&
    today.minutes() === Shifts.Night.start.minute
  ) {
    return "Noche";
  } else if (
    today.hours() === Shifts.Morning.start.hour &&
    today.minutes() === Shifts.Morning.start.minute
  ) {
    return "Mañana";
  } else if (
    today.hours() === Shifts.Afternoon.start.hour &&
    today.minutes() === Shifts.Afternoon.start.minute
  ) {
    return "Tarde";
  } else {
    return "N/A";
  }
}
