import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";
import { Trans } from "@lingui/macro";

const SectorLevel = props =>
  props.tree.map((sector, i) => (
    <Dropdown.Item key={i}>
      <Icon name="dropdown" />
      <span className="text">{sector.sector}</span>
      <Dropdown.Menu>
        <LineLevel lines={sector.lines} />
      </Dropdown.Menu>
    </Dropdown.Item>
  ));

const LineLevel = props =>
  props.lines.map((line, i) => (
    <Dropdown.Item
      as={Link}
      key={i}
      to={`/?line=${line.lineID}`}
      style={{ color: "rgba(0,0,0,.87" }}
    >
      {line.description}
    </Dropdown.Item>
  ));

export default ({ tree }) => {
  if (!tree) return <Dropdown item loading simple disabled />;

  return (
    <Dropdown item text="OEE" simple>
      <Dropdown.Menu>
        <Dropdown.Header>
          <Trans>Sectores</Trans>
        </Dropdown.Header>
        <Dropdown.Divider />
        <SectorLevel tree={tree} />
      </Dropdown.Menu>
    </Dropdown>
  );
};
