import React, { useEffect } from "react";
import queryString from "query-string";
import { UserRoles } from "../../utils/Consts";
import { connect } from "react-redux";
import { NotLine, ForbiddenLine } from "./Screens";
import { Error } from "../../utils/Error";
import { Line } from "./Line";
/**
 * Indicators es la página principal del proyecto OEE.
 * Recibe información de redux y del routing.
 */
const Indicators = ({
  modules,
  location,
  terminal,
  user,
  indexes,
  lastUpdate,
  currentStop,
  navigation
}) => {
  /**
   * Obtención de línea actual, definida como query param.
   * Supervisores navegan libremente. Operadores solo utilizan
   * la definida en terminal.
   */
  let queryLine = queryString.parse(location.search);
  let currentLine = queryLine.line ? queryLine.line : null;
  let terminalLine = terminal.terminal ? terminal.terminal.lineID : null;
  let roleAllowed =
    user.roleID === UserRoles.ADMINISTRATOR ||
    user.roleID === UserRoles.SUPERVISOR ||
    user.roleID === UserRoles.CONSULTANT ||
    user.roleID === UserRoles.OPERATOR;

  useEffect(() => {
    document.title = `OEE++ ${
      currentLine ? "Línea " + currentLine : "Indicadores"
    }`;
  });

  /*Esperamos carga de modulos*/
  if (
    Object.keys(modules).filter(key => modules[key].status === "loading")
      .length > 0
  )
    return "Loading modules...";

  //Estoy paradao en root /
  if (currentLine == null) {
    //Usuario no supervisor/administrador y terminal no asignada.
    if (!roleAllowed && !terminalLine) {
      return <NotLine />;
    }
    //Usuario supervisor/administrador y terminal no asignada.
    if (roleAllowed && !terminalLine) {
      return <NotLine super />;
    }
  }
  //Exploro una lina
  if (!roleAllowed && currentLine) return <ForbiddenLine />;

  //Unifico linea
  currentLine = currentLine == null ? terminalLine : currentLine;

  return (
    <Error line={currentLine}>
      <Line
        line={currentLine}
        terminal={terminal}
        navigation={navigation}
        indexes={indexes}
        currentStop={currentStop}
        user={user}
        lastUpdate={lastUpdate}
        modules={modules}
      />
    </Error>
  );
};

const mapStateToProps = state => ({
  modules: state.modules.registered,
  user: state.authentication.user,
  terminal: state.terminal,
  indexes: state.indicators.indexes,
  lastUpdate: state.indicators.lastUpdate,
  currentStop: state.indicators.currentStop,
  navigation: state.indicators.navigation
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Indicators);
