import React, { useState } from "react";
import { Confirm } from "semantic-ui-react";
import { useInterval } from "../../features/indicators/service";
import { getHighPriorityAlert } from "./../orders/service";

const ORDER_ALERT_MINUTES = 0.5;

export const LineAlert = ({ line }) => {
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(null);

  useInterval(() => {
    getHighPriorityAlert(line)
      .then(r => {
        if (r) {
          setOpen(true);
          setAlert(r);
        } else {
          setOpen(false);
        }
      })
      .catch(r => {
        setOpen(false);
      });
  }, ORDER_ALERT_MINUTES * 60000);

  return (
    <Confirm
      className={"mtto-alert"}
      open={open}
      dimmer="inverted"
      header={"Alerta!"}
      content={alert && alert.message}
      cancelButton={null}
      onConfirm={() => setOpen(false)}
    />
  );
};
