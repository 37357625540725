import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef
} from "react";
import notify from "devextreme/ui/notify";
import {
  Button,
  Icon,
  Modal,
  Grid,
  GridColumn,
  Popup
} from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import DateBox from "devextreme-react/date-box";
import moment from "moment";
import { splitStopAsync } from "./service.js";

const Split = forwardRef(({ onSplit, grid, user }, ref) => {
  //Split state
  const [state, setState] = useState({
    visible: false,
    stop: undefined,
    newStart: undefined,
    newEnd: undefined
  });

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    //Show split modal for selected stop
    split: stop =>
      setState({
        visible: true,
        stop
      })
  }));

  const startRef = useRef();
  const endRef = useRef();

  //User actions
  const confirmSplit = e => {
    //Validator devexpress
    if (!startRef.current.instance.option("isValid")) {
      notify("Fecha de inicio no es válida", "warning", 1500);
      return;
    }
    if (!endRef.current.instance.option("isValid")) {
      notify("Fecha de fin no es válida", "warning", 1500);
      return;
    }

    splitStopAsync(
      state.stop,
      user.userID,
      state.newStart ? state.newStart : start,
      state.newEnd ? state.newEnd : splitEnd,
      r => {
        setState({ ...state, visible: false });
        grid.current.instance.refresh();
        notify("Split creado con éxito", "success", 1500);
      },
      r => {
        notify(r, "error", 5000);
      }
    );
    // DevExpress.validationEngine.validateGroup("loginGroup");
  };

  const cancelSplit = () =>
    setState({
      visible: false,
      stop: undefined
    });

  const { visible, stop } = state;
  const {
    stopID,
    start,
    end,
    splitEnd,
    inProgress,
    sameDay,
    maintenance
  } = getOptions(stop);
  const commonDateBoxProps = {
    width: "100%",
    type: sameDay ? "time" : "datetime",
    displayFormat: sameDay ? "HH:mm" : "d/MM/y HH:mm"
  };

  return (
    <Modal
      open={visible}
      size="mini"
      dimmer="inverted"
      onClose={() => setState({ visible: false, stop: undefined })}
    >
      <Modal.Header as="h2">
        {maintenance && <MttoHint />}
        <Trans>Split de parada nro. {stopID} </Trans>
      </Modal.Header>
      <Modal.Content scrolling>
        <Grid columns={2}>
          <GridColumn>
            <Trans>Fecha de inicio</Trans>
            <DateBox disabled={true} value={start} {...commonDateBoxProps} />
            <br />
            <Trans>Nueva fecha de inicio</Trans>
            <DateBox
              ref={startRef}
              defaultValue={start}
              min={moment(start)
                .seconds(0)
                .milliseconds(0)
                .toISOString()}
              max={state.newEnd ? state.newEnd : splitEnd}
              onValueChanged={e => {
                let startMoment = moment(start);
                let value = moment(e.value)
                  .seconds(startMoment.seconds())
                  .milliseconds(startMoment.millisecond())
                  .toISOString();
                setState({ ...state, newStart: value });
              }}
              {...commonDateBoxProps}
            />
          </GridColumn>
          <GridColumn>
            <Trans>Fecha de fin</Trans>
            <DateBox disabled={true} value={end} {...commonDateBoxProps} />
            <br />
            <Trans>Nueva fecha de fin</Trans>
            <DateBox
              ref={endRef}
              disabled={inProgress}
              defaultValue={splitEnd}
              min={state.newStart ? state.newStart : start}
              max={splitEnd}
              onValueChanged={e => {
                setState({ ...state, newEnd: moment(e.value).toISOString() });
              }}
              {...commonDateBoxProps}
            />
          </GridColumn>
        </Grid>
        <br />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={confirmSplit}>
          <Icon name="checkmark" />
          <Trans>Confirmar</Trans>
        </Button>
        <Button onClick={cancelSplit}>
          <Icon name="delete" />
          <Trans>Cancelar</Trans>
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

const MttoHint = () => (
  <Popup
    position="top right"
    trigger={<Icon color="yellow" name="warning circle"></Icon>}
  >
    <span>
      <Trans>
        La parada seleccionada posee un llamado a mantenimiento. Se restringe el
        rango de split al tiempo de producción.
      </Trans>
    </span>
  </Popup>
);

const getOptions = stop => {
  const { stopID, endDate, startDate, maintenanceReason, declaringTime } =
    stop || {};
  const options = {
    stopID,
    start: undefined,
    end: undefined,
    inProgress: undefined,
    sameDay: undefined,
    maintenance: false,
    splitEnd: undefined
  };

  //Parse stop
  options.start = moment(startDate);

  if (moment(endDate).isValid()) {
    options.end = moment(endDate);
    options.inProgress = false;
  } else {
    options.end = moment();
    options.inProgress = true;
  }

  options.sameDay = options.start.isSame(options.end, "day");

  //Check mtto and limit split
  options.maintenance = maintenanceReason !== null;
  if (!options.inProgress && options.maintenance && !isNaN(declaringTime)) {
    options.splitEnd = options.start.clone().add(declaringTime, "m");
  } else {
    options.splitEnd = options.end;
  }

  return options;
};

export default Split;
