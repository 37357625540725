import React from "react";
import { i18n } from "../i18n/components/ConnectProvider";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { Form, Segment, Button, Header, Modal } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";
import { authHeader } from "../../utils/AuthHeader";
import {
  Column,
  HeaderFilter,
  DataGrid as DxDataGrid,
  FilterRow,
  GroupPanel,
  Scrolling,
  Export,
  ColumnChooser
} from "devextreme-react/data-grid";

const historyDatasource = createStore({
  key: ["lineId", "shiftStart"],
  loadUrl: "/api/history/get",
  onBeforeSend: (_, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

export default function IndexesByShift() {
  return (
    <>
      <Segment padded="very" style={{ margin: 20 }}>
        <Header as="h2">
          <Trans>Índices por turno</Trans>
          <Header.Subheader>
            <Trans>Visualiza los registros de turno en el sistema</Trans>
          </Header.Subheader>
        </Header>
        <HistoryGrid />
      </Segment>
    </>
  );
}

function HistoryGrid() {
  return (
    <DataGrid dataSource={historyDatasource}>
      <Column dataField="lineID" caption={i18n()._(t`Línea`)} />
      <Column dataField="shiftStart" caption={i18n()._(t`Inicio`)} />
      <Column dataField="shiftEnd" caption={i18n()._(t`Fin`)} />
      <Column
        dataField="productionQuantity"
        caption={i18n()._(t`Producción`)}
      />
      <Column dataField="runtime" caption={i18n()._(t`Runtime`)} />
      <Column dataField="utilization" caption={i18n()._(t`Utilización`)} />
      <Column
        allowEditing={false}
        dataField={"staffedTime"}
        caption={i18n()._(t`Staffed Time`)}
      />
      <Column
        allowEditing={false}
        dataField={"discountTime"}
        caption={i18n()._(t`Discount Time`)}
      />
      <Column
        allowEditing={false}
        dataField={"adjustedDiscountTime"}
        caption={i18n()._(t`Discount Time Ajustado`)}
        dataType="number"
      />
      <Column
        allowEditing={false}
        dataField={"staffedTimeCorrect"}
        caption={i18n()._(t`Staffed Time Corr.`)}
      />
      <Column dataField="throughput" caption={i18n()._(t`Throughput`)} />
      <Column dataField="acceptance" caption={i18n()._(t`Aceptación`)} />
      <Column dataField="oee" caption={i18n()._(t`Oee`)} />
      <Column dataField="mtbf" caption={i18n()._(t`MTBF`)} />
      <Column dataField="mttr" caption={i18n()._(t`MTTR`)} />
    </DataGrid>
  );
}

function addRefreshButton(event) {
  event.toolbarOptions.items.unshift({
    location: "after",
    widget: "dxButton",
    options: {
      icon: "refresh",
      onClick: () => event.component.refresh()
    }
  });
}

function DataGrid({ dataSource, children, ...props }) {
  return (
    <DxDataGrid
      dataSource={dataSource}
      columnAutoWidth={true}
      showBorders={true}
      style={{ maxHeight: 500, fontSize: "1rem" }}
      allowColumnReordering={true}
      allowColumnResizing={true}
      rowAlternationEnabled={true}
      remoteOperations={true}
      onToolbarPreparing={e => addRefreshButton(e)}
      {...props}
    >
      <Export enabled fileName="INDICES_TURNOS" />
      <ColumnChooser enabled />
      <FilterRow visible />
      <HeaderFilter visible />
      <GroupPanel visible />
      <Scrolling mode="virtual" />
      {children}
    </DxDataGrid>
  );
}
