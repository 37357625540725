import { userService } from "./service";
import store from "../../app/store";
import { loadNavigationResources } from "../navigation/actions";

export const loginActions = {
  login,
  logout
};

export const loginConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT"
};

function login(username, password) {
  store.dispatch({ type: loginConstants.LOGIN_REQUEST, username });

  userService.login(username, password).then(
    user => {
      store.dispatch({ type: loginConstants.LOGIN_SUCCESS, user });
      loadNavigationResources();
    },
    error => {
      try {
        error = error.toString();
      } catch (e) {
        error = "Error desconocido";
      }
      store.dispatch({ type: loginConstants.LOGIN_FAILURE, error });
    }
  );
}

function logout() {
  userService.logout();
  store.dispatch({ type: loginConstants.LOGOUT });
}
