let locale = localStorage.getItem("locale");
const INITIAL_STATE = locale ? { language: locale } : { language: "es" };

const i18n = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_LANGUAGE": {
      return { language: action.payload };
    }
    default:
      return state;
  }
};

export default i18n;
