import store from "../../app/store";
import { authHeader } from "../../utils/AuthHeader";

export const navigationConstants = {
  NAVIGATION_LOAD_RESOURCES: "NAVIGATION_LOAD_RESOURCES"
};

export const loadNavigationResources = () => {
  fetch(`/api/resources/tree`, { headers: authHeader() })
    .then(response => response.json())
    .then(data =>
      store.dispatch({
        type: navigationConstants.NAVIGATION_LOAD_RESOURCES,
        payload: data
      })
    )
    .catch(error => console.log("Error loading resources", error));
};

//At start
loadNavigationResources();
