import React, { Component } from "react";
import { Segment, Header } from "semantic-ui-react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { Chart } from "devextreme-react/chart";

export class TagsUpdateDiagnostic extends Component {
  //Initial state values
  state = {
    data: [],
    average: 0,
    limitLine: {
      label: { text: `Max 1000 ms.`, font: { color: "red", size: 14 } },
      value: 1000,
      color: "red",
      width: 2
    }
  };
  //Api consumer store
  dataSource = createStore({
    loadUrl: `/api/diagnostics/tagsupdates`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  });

  //Lifecycle hooks to manage timer subscription
  componentDidMount() {
    this.interval = setInterval(() => this.refresh(), 5000);
    this.refresh();
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  //Refresh data from API
  refresh() {
    this.dataSource
      .load()
      .then(data => this.average(data))
      .then(data => this.updateLines(data))
      .then(({ data, average }) => this.setState({ data, average }));
  }

  //Calc average of data
  average(data) {
    const durations = data.map(ev => ev.duration);
    const avg = durations.reduce((p, c) => p + c, 0) / durations.length;
    const average = isNaN(avg) ? 0 : Math.round(avg);
    return { data, average };
  }

  //Update constants lines
  updateLines({ data, average }) {
    //Prevent
    if (!this.chartReference) return;
    //Add avg line
    this.chartReference.instance.option({
      valueAxis: {
        constantLines: [
          {
            label: {
              text: `Avg ${average} ms.`,
              font: { color: "lightsalmon", size: 14 }
            },
            value: average,
            color: "lightsalmon",
            width: 2
          },
          this.state.limitLine
        ]
      }
    });
    return { data, average };
  }

  //where the magic happens
  render() {
    let { data } = this.state;
    return (
      <Segment padded="very">
        <Header
          as="h2"
          content="Tags Update Over Time (ms)"
          subheader="Showing update time."
        />

        <Chart
          ref={ref => {
            this.chartReference = ref;
          }}
          palette="Violet"
          dataSource={data}
          commonSeriesSettings={{
            type: "splinearea",
            argumentField: "dateTime",
            point: { visible: false }
          }}
          commonAxisSettings={{ grid: { visible: true } }}
          series={[{ valueField: "duration", name: "Tags update" }]}
          tooltip={{ enabled: false }}
          legend={{ verticalAlignment: "top", horizontalAlignment: "right" }}
          argumentAxis={{
            label: { format: "HH:mm:ss" },
            argumentType: "datetime",
            minorTick: { visible: true }
          }}
        />
      </Segment>
    );
  }
}
