import React, { Component } from "react";
import TagManager from "./TagManager";
import {
  Column,
  FormItem,
  DataGrid,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  Lookup,
  RangeRule,
  RequiredRule,
  StringLengthRule
} from "devextreme-react/data-grid";
import { Segment, Header } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";
import { UserRoles } from "../../utils/Consts";
import { authHeader } from "../../utils/AuthHeader";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { i18n } from "../i18n/components/ConnectProvider";

export default class ResourcesConfig extends Component {
  render() {
    return (
      <Segment basic padded>
        <Header as="h2">
          <Trans>Configuración de recursos</Trans>
          <Header.Subheader>
            <Trans>Actualiza la información de los recursos del sistema.</Trans>
          </Header.Subheader>
        </Header>
        <ResourcesDataGrid />
      </Segment>
    );
  }
}

ResourcesConfig.getMenuItem = () => <Trans>Recursos</Trans>;
ResourcesConfig.getPermissions = userLevel =>
  userLevel === UserRoles.ADMINISTRATOR;

const url = "/api/Resources";

const dataSource = createStore({
  key: "lineID",
  loadUrl: `${url}/Get`,
  insertUrl: `${url}/PostResources`,
  updateUrl: `${url}/PutResources`,
  deleteUrl: `${url}/DeleteResources`,
  onBeforeSend: (_, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const sectorsSource = createStore({
  key: "Value",
  loadUrl: `/api/sectors/lookup`,
  onBeforeSend: (_, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const ResourcesDataGrid = () => (
  <DataGrid
    key={"lineID"}
    className={"no-overflow"}
    dataSource={dataSource}
    showBorders={true}
    style={{ maxHeight: 500, fontSize: "1rem" }}
    allowColumnReordering={true}
    allowColumnResizing={true}
    rowAlternationEnabled={true}
    remoteOperations={true}
    onEditorPreparing={e => {
      if (e.parentType === "dataRow" && e.dataField === "lineID")
        e.editorOptions.readOnly = !e.row.inserted;
    }}
    onRowUpdating={r => {
      if (typeof r.newData.fixedThroughput !== "undefined")
        r.newData.fixedThroughput = r.newData.fixedThroughput ? 1 : null;
    }}
  >
    <FilterRow visible={true} />
    <HeaderFilter visible={false} />
    <GroupPanel visible={true} />
    <Scrolling mode={"virtual"} />
    <Editing
      mode={"form"}
      allowAdding={true}
      allowDeleting={true}
      allowUpdating={true}
      useIcons={true}
    />
    <Column dataField={"lineID"} caption={i18n()._(t`Línea`)} key>
      <RequiredRule
        message={i18n()._(t`Debe definir el número de línea del recurso.`)}
      />
      <RangeRule
        min={1}
        max={99}
        message={i18n()._(t`Defina una línea de 1 a 99.`)}
      />
    </Column>
    <Column dataField={"sectorID"} caption={i18n()._(t`Sector`)}>
      <RequiredRule message={i18n()._(t`Debe elegir un sector`)} />
      <Lookup
        dataSource={sectorsSource}
        valueExpr={"value"}
        displayExpr={"text"}
      />
    </Column>
    <Column dataField={"description"} caption={i18n()._(t`Descripción`)}>
      <RequiredRule
        message={i18n()._(t`Debe definir una descripción del recurso`)}
      />
      <StringLengthRule
        max={40}
        message={i18n()._(
          t`La descripción debe tener como máximo 40 caracteres.`
        )}
      />
    </Column>
    <Column
      caption={i18n()._(t`Calculation Baliza`)}
      dataField={"calculationBaliza"}
      visible={false}
      editCellComponent={e => (
        <TagManager
          resultType={"boolean"}
          data={e.data.calculationBaliza}
          setValue={e.setValue}
        />
      )}
    ></Column>
    <Column
      caption={i18n()._(t`Calculation Unidades`)}
      dataField={"calculationUnits"}
      visible={false}
      editCellComponent={e => (
        <TagManager
          resultType={"numeric"}
          data={e.data.calculationUnits}
          setValue={e.setValue}
        />
      )}
    ></Column>
    <Column
      caption={i18n()._(t`Calculation Descartes`)}
      dataField={"calculationDiscards"}
      visible={false}
      editCellComponent={e => (
        <TagManager
          resultType={"numeric"}
          data={e.data.calculationDiscards}
          setValue={e.setValue}
        />
      )}
    ></Column>
    <Column
      caption={i18n()._(t`Fijar Throughput`)}
      dataField="calculateThroughput"
      dataType={"boolean"}
      //calculateCellValue={r => r.calculateThroughput != null}
    />
    <Column
      dataField={"enableStopsManager"}
      caption={i18n()._(t`Habilitar Parada Manual`)}
      dataType={"boolean"}
    />
    <Column
      dataField={"enableOrderManager"}
      caption={i18n()._(t`Habilitar Silo/Secuencia Manual`)}
      dataType={"boolean"}
    />
    <Column
      dataField={"enableProductionManager"}
      caption={i18n()._(t`Habilitar Producción manual`)}
      dataType={"boolean"}
    />
    <Column dataField={"updatedBy"} caption={i18n()._(t`Actualizado por`)}>
      <FormItem visible={false} />
    </Column>
    <Column
      dataField={"updateDate"}
      format={"dd/MM/yyyy HH:mm:ss"}
      dataType={"date"}
      caption={i18n()._(t`Ultima actualizacion `)}
    >
      <FormItem visible={false} />
    </Column>
  </DataGrid>
);
