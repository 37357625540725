export const UserRoles = {
  ADMINISTRATOR: 1,
  SUPERVISOR: 2,
  OPERATOR: 3,
  CONSULTANT: 4,

  KeyValuePair: [
    { key: 1, value: `Administrador` },
    { key: 2, value: `Supervisor` },
    { key: 3, value: `Operador` },
    { key: 4, value: `Consulta` }
  ]
};

export const Shifts = {
  All: {
    key: "TO",
    name: "Todos",
    start: { hour: 6, minute: 0 },
    end: { hour: 6, minute: 0 }
  },
  Morning: {
    key: "TM",
    name: "Mañana",
    start: { hour: 6, minute: 0 },
    end: { hour: 14, minute: 0 }
  },
  Afternoon: {
    key: "TT",
    name: "Tarde",
    start: { hour: 14, minute: 0 },
    end: { hour: 22, minute: 0 }
  },
  Night: {
    key: "TN",
    name: "Noche",
    start: { hour: 22, minute: 0 },
    end: { hour: 6, minute: 0 }
  },
  KeyValuePair: [
    { key: "TO", name: "Todos" },
    { key: "TM", name: "Mañana" },
    { key: "TT", name: "Tarde" },
    { key: "TN", name: "Noche" }
  ]
};

export const OrderStatus = {
  Created: 0,
  Ready: 1,
  Finalized: 2
};

export const SystemStops = {
  RootCategory: 1,
  SystemCategory: 2,
  Microstop: 3,
  OPCDisconnected: 4,
  EndOfLot: 5,
  AdjustmentStop: 6,
  PlannedCategory: 7,
  UnplannedCategory: 8
};

export const EmptyOrder = " ";

export const MttoStatus = {
  Waiting: 0,
  Started: 1,
  Finished: 2,
  NoMtto: 4
};

export const BeaconStatus = {
  ON: "#66D18A",
  OFF: "#FF0000",
  MAINTEINANCE: "#FFB414",
  UNKNOWN: "#FFFFFF"
};

export const OrderValidatedStates = [
  { name: "No leído", key: 1 },
  { name: "Leído", key: 2 },
  { name: "Leído con errores", key: 3 }
];

export function getValidationStage(stage) {
  var stageName = OrderValidatedStates.filter(s => s.key === stage);
  return stageName[0] == null ? "Desconocido" : stageName[0].name;
}

export function getShiftInfo(shiftKey) {
  for (let s in Shifts) {
    if (Shifts[s].key === shiftKey) return Shifts[s];
  }
}
