import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { Segment, Header } from "semantic-ui-react";
import {
  Column,
  FormItem,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Editing,
  Lookup,
  RequiredRule
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { UserRoles } from "../../utils/Consts";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

const SectorsConfig = () => {
  //Refresh sectors on bulding crud operation
  const sectorsRef = useRef();
  const refreshSectors = () => sectorsRef.current.refresh();

  return (
    <Segment basic padded>
      <Header as="h2">
        <Trans>Configuración de Sectores y Edificios</Trans>
        <Header.Subheader>
          <Trans>Actualiza la información de los sectores del sistema.</Trans>
        </Header.Subheader>
      </Header>
      <h3>
        <Trans>Edificios</Trans>
      </h3>
      <BuildingsDataGrid rowUpdated={refreshSectors} />
      <h3>
        <Trans>Sectores</Trans>
      </h3>
      <SectorsDataGrid ref={sectorsRef} />
    </Segment>
  );
};

SectorsConfig.getMenuItem = () => <Trans>Sectores</Trans>;
SectorsConfig.getPermissions = userLevel =>
  userLevel === UserRoles.ADMINISTRATOR;

export default SectorsConfig;

const sectorsSource = createStore({
  key: "sectorID",
  loadUrl: `/api/sectors/get`,
  insertUrl: `/api/sectors/post`,
  updateUrl: `/api/sectors/put`,
  deleteUrl: `/api/sectors/delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const buildingsSource = createStore({
  key: "buildingId",
  loadUrl: `/api/buildings/get`,
  insertUrl: `/api/buildings/post`,
  updateUrl: `/api/buildings/put`,
  deleteUrl: `/api/buildings/delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const buildingsLookup = createStore({
  key: "Value",
  loadUrl: `/api/buildings/lookup`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
    ajaxOptions.cache = false;
  }
});

const SectorsDataGrid = forwardRef((props, sectorsRef) => {
  const gridRef = useRef();
  // Expose some imperative methods
  useImperativeHandle(sectorsRef, () => ({
    refresh: () => gridRef.current.instance.refresh()
  }));

  return (
    <DataGrid
      ref={gridRef}
      key={["sectorID"]}
      dataSource={sectorsSource}
      showBorders={true}
      style={{ maxHeight: 500, fontSize: "1rem" }}
      allowColumnReordering={true}
      allowColumnResizing={true}
      rowAlternationEnabled={true}
      remoteOperations={true}
    >
      <FilterRow visible={true} />
      <HeaderFilter visible={false} />
      <Scrolling mode={"virtual"} />
      <Editing
        mode={"form"}
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={false}
        useIcons={true}
      />
      <Column dataField={"sectorID"} visible={false} key>
        <FormItem visible={false} />
      </Column>
      <Column dataField={"sectorName"} caption={i18n()._(t`Nombre Sector`)}>
        <RequiredRule
          message={i18n()._(t`Debe definir un nombre de sector.`)}
        />
      </Column>
      <Column
        dataField={"buildingId"}
        caption={i18n()._(t`Edificio`)}
        headerFilter={{ dataSource: buildingsLookup }}
      >
        <RequiredRule />
        <Lookup
          dataSource={buildingsLookup}
          valueExpr={"value"}
          displayExpr={"text"}
        />
      </Column>
    </DataGrid>
  );
});

const BuildingsDataGrid = ({ rowUpdated }) => (
  <DataGrid
    key={["buildingId"]}
    dataSource={buildingsSource}
    showBorders={true}
    style={{ maxHeight: 200, fontSize: "1rem" }}
    allowColumnReordering={true}
    allowColumnResizing={true}
    rowAlternationEnabled={true}
    remoteOperations={true}
    onRowInserted={rowUpdated}
    onRowRemoved={rowUpdated}
    onRowUpdated={rowUpdated}
  >
    <FilterRow visible={true} />
    <HeaderFilter visible={false} />
    <Scrolling mode={"virtual"} />
    <Editing
      mode={"form"}
      allowAdding={true}
      allowDeleting={true}
      allowUpdating={false}
      useIcons={true}
    />
    <Column dataField={"buildingId"} visible={false} key>
      <FormItem visible={false} />
    </Column>
    <Column dataField={"buildingName"} caption={i18n()._(t`Nombre Edificio`)}>
      <RequiredRule message={i18n()._(t`Debe definir un nombre de sector.`)} />
    </Column>
  </DataGrid>
);
