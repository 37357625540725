import { authHeader } from "../../utils/AuthHeader";
import notify from "devextreme/ui/notify";

function getHeaders() {
  let headers = authHeader();
  headers["Content-Type"] = "application/x-www-form-urlencoded";
  return headers;
}

export function generateReport() {
  //window.location = `/api/reports/completeoaereport`;
  fetch(`/api/reports/completeoaereport`, {
    method: "GET",
    headers: getHeaders()
  })
    .then(r => {
      if (r.status === 200) {
        r.blob().then(blob => {
          if (navigator.appVersion.toString().indexOf(".NET") > 0) {
            window.navigator.msSaveBlob(blob, "OEE-Reportes.xlsx");
          } else {
            var url = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "OEE Reportes.xlsx";
            document.body.appendChild(a); // otherwise it will not work in firefox
            a.click();
            a.remove();
            notify("Se generó correctamente el reporte.", "success", 1500);
          }
        });
      } else {
        throw new Error();
      }
    })
    .catch(() =>
      notify("Ha ocurrido un error. Consulte con su supervisor.", "error", 5000)
    );
}
