import React, { useRef, useEffect, useState } from "react";
import notify from "devextreme/ui/notify";
import { getShiftInfo } from "../../utils/Consts";
import { Bullet } from "devextreme-react/bullet";
import moment from "moment";
import {
  Segment,
  Form as FormReact,
  Header,
  Button,
  Card
} from "semantic-ui-react";
import { SelectBox } from "devextreme-react/select-box";
import { DateBox } from "devextreme-react/date-box";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";
import { Grid, Accordion, Icon } from "semantic-ui-react";
import { authHeader } from "../../utils/AuthHeader";
import { Label } from "semantic-ui-react";
import {
  sectorsDxStore,
  linesDxStore,
  productsDxStore,
  stopTypesDxStoreAsync
} from "./service";
import { Shifts } from "../../utils/Consts";
import { Column, DataGrid, Scrolling } from "devextreme-react/data-grid";
import { getProductionRange } from "../orders/service";

const dataSourcesDefaults = {
  sectors: null,
  lines: null,
  shifts: null,
  stopTypes: null,
  stopLevels: [
    { value: Number.MAX_SAFE_INTEGER, text: "Todos" },
    { value: 1, text: "1" },
    { value: 2, text: "2" },
    { value: 3, text: "3" },
    { value: 4, text: "4" },
    { value: 5, text: "5" },
    { value: 6, text: "6" }
  ],
  products: null
};

const filterDefaults = {
  sector: null,
  sectorName: null,
  line: null,
  lineName: null,
  startDate: new Date(),
  endDate: new Date(),
  shift: null,
  product: null,
  productName: null,
  stopType: null,
  stopTypeName: null,
  stopLevel: dataSourcesDefaults.stopLevels[0].value,
  stopLevelName: dataSourcesDefaults.stopLevels[0].text
};

export function NewSummary() {
  const [filter, setFilter] = useState(null);
  const [accordionOpen, setAccordionOpen] = useState(true);

  return (
    <Segment padded="very" style={{ margin: 20 }}>
      <Header as="h2">
        <Trans>Resumen de paradas</Trans>
        <Header.Subheader>
          <Trans>
            Análisis de paradas por línea, según periodo especificado.
          </Trans>
        </Header.Subheader>
      </Header>
      {filter && <FilterSelection filter={filter} />}
      <FilterAccordion
        title="Seleccionar los filtros"
        open={accordionOpen}
        setOpen={setAccordionOpen}
      >
        <FilterOptions
          setParentFilter={setFilter}
          setAccordionOpen={setAccordionOpen}
        />
      </FilterAccordion>
      {filter && (
        <>
          <OrdersSummary filter={filter} />
          <StopsSummary filter={filter} />
        </>
      )}
    </Segment>
  );
}

function FilterOptions({ setParentFilter, setAccordionOpen }) {
  const [dataSources, setDataSources] = useState(dataSourcesDefaults);
  const [innerFilter, setInnerFilter] = useState(filterDefaults);

  const refs = {
    sector: useRef(null),
    line: useRef(null),
    startDate: useRef(null),
    endDate: useRef(null),
    shift: useRef(null),
    product: useRef(null),
    stopType: useRef(null),
    stopLevel: useRef(null)
  };

  useEffect(() => {
    let newSectors = sectorsDxStore();
    setDataSources({
      ...dataSources,
      sectors: newSectors
    });
  }, []);

  function setState(event) {
    switch (event.source) {
      case "sector":
        setDataSources({
          ...dataSources,
          lines: linesDxStore(event.value),
          products: dataSourcesDefaults.products
        });
        refs.line.current.instance.reset();
        refs.product.current.instance.reset();
        break;
      case "line":
        setDataSources({
          ...dataSources,
          products: productsDxStore(
            event.value,
            innerFilter.startDate,
            innerFilter.endDate,
            innerFilter.shift,
            true
          )
        });
        refs.product.current.instance.reset();
        break;
      case "startDate":
        refs.endDate.current.instance.option("isValid", true);
        let isBeforeEndDate =
          innerFilter.endDate === null ||
          moment(event.value).isSameOrBefore(moment(innerFilter.endDate));
        if (isBeforeEndDate) {
          refs.startDate.current.instance.option("isValid", true);
          setDataSources({
            ...dataSources,
            products: productsDxStore(
              innerFilter.line,
              event.value,
              innerFilter.endDate,
              innerFilter.shift,
              true
            )
          });
        } else {
          refs.startDate.current.instance.option("isValid", false);
          setDataSources({
            ...dataSources,
            products: dataSourcesDefaults.products
          });
        }
        refs.product.current.instance.reset();
        break;
      case "endDate":
        refs.startDate.current.instance.option("isValid", true);
        let isAfterStartDate =
          innerFilter.startDate === null ||
          moment(event.value).isSameOrAfter(moment(innerFilter.startDate));
        if (isAfterStartDate) {
          refs.endDate.current.instance.option("isValid", isAfterStartDate);
          setDataSources({
            ...dataSources,
            products: productsDxStore(
              innerFilter.line,
              innerFilter.startDate,
              event.value,
              innerFilter.shift,
              true
            )
          });
        } else {
          refs.endDate.current.instance.option("isValid", false);
          setDataSources({
            ...dataSources,
            products: dataSourcesDefaults.products
          });
        }
        refs.product.current.instance.reset();
        break;
      case "shift":
        setDataSources({
          ...dataSources,
          products: productsDxStore(
            innerFilter.line,
            innerFilter.startDate,
            innerFilter.endDate,
            event.value,
            true
          )
        });
        refs.product.current.instance.reset();
        break;
      case "product":
        stopTypesDxStoreAsync(true).then(newStopTypes => {
          setDataSources({
            ...dataSources,
            stopTypes: newStopTypes
          });
        });
        break;
      case "stopType":
        break;
      case "stopLevel":
        break;
      default:
        break;
    }

    updateInnerFilter();
  }

  function updateInnerFilter() {
    let newSector = refs.sector.current.instance.option("selectedItem");
    let newLine = refs.line.current.instance.option("selectedItem");
    let newStartDate = refs.startDate.current.instance.option("value");
    let newEndDate = refs.endDate.current.instance.option("value");
    let newShift = refs.shift.current.instance.option("selectedItem");
    let newProduct = refs.product.current.instance.option("selectedItem");
    let newStopType = refs.stopType.current.instance.option("selectedItem");
    let newStopLevel = refs.stopLevel.current.instance.option("selectedItem");

    setInnerFilter({
      sector: newSector && newSector.value,
      sectorName: newSector && newSector.text,
      line: newLine && newLine.value,
      lineName: newLine && newLine.text,
      startDate: newStartDate,
      endDate: newEndDate,
      shift: newShift && newShift.key,
      product: newProduct && newProduct.value,
      productName: newProduct && newProduct.text,
      stopType: newStopType && newStopType.value,
      stopTypeName: newStopType && newStopType.text,
      stopLevel: newStopLevel && newStopLevel.value,
      stopLevelName: newStopLevel && newStopLevel.text
    });
  }

  function applyFilter() {
    if (setAccordionOpen != null) setAccordionOpen(false);

    let shiftInfo = getShiftInfo(innerFilter.shift);
    let newStartDate = moment(innerFilter.startDate)
      .hour(shiftInfo.start.hour)
      .minute(shiftInfo.start.minute)
      .second(0)
      .millisecond(0);
    let newEndDate = moment(innerFilter.endDate)
      .hour(shiftInfo.end.hour)
      .minute(shiftInfo.end.minute)
      .second(0)
      .millisecond(0);

    if (
      shiftInfo.end.hour < shiftInfo.start.hour ||
      (shiftInfo.end.hour === shiftInfo.start.hour &&
        shiftInfo.end.minute <= shiftInfo.start.minute)
    )
      newEndDate.add(1, "day");

    setParentFilter({
      sector: innerFilter.sector,
      sectorName: innerFilter.sectorName,
      line: innerFilter.line,
      lineName: innerFilter.lineName,
      startDate: newStartDate,
      endDate: newEndDate,
      product: innerFilter.product,
      productName: innerFilter.productName,
      stopType: innerFilter.stopType,
      stopTypeName: innerFilter.stopTypeName,
      stopLevel: innerFilter.stopLevel,
      stopLevelName: innerFilter.stopLevelName
    });
  }

  return (
    <FormReact>
      <Grid stackable divided="vertically">
        <Grid.Row>
          <Grid.Column width={5}>
            1. <Trans>Sector</Trans>
            <SelectBox
              ref={refs.sector}
              dataSource={dataSources.sectors}
              onValueChanged={e => setState({ ...e, source: "sector" })}
              valueExpr="value"
              displayExpr="text"
            />
          </Grid.Column>
          <Grid.Column width={5}>
            2. <Trans>Línea</Trans>
            <SelectBox
              ref={refs.line}
              disabled={innerFilter.sector === null}
              dataSource={dataSources.lines}
              onValueChanged={e => setState({ ...e, source: "line" })}
              valueExpr="value"
              displayExpr="text"
            />
          </Grid.Column>
          <Grid.Column width={3}>
            3. <Trans>Desde</Trans>
            <DateBox
              ref={refs.startDate}
              width="100%"
              displayFormat="dd/MM/yyyy"
              value={innerFilter.startDate}
              disabled={innerFilter.line === null}
              placeholder={i18n()._(t`Seleccionar...`)}
              onValueChanged={e => setState({ ...e, source: "startDate" })}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            4. <Trans>Hasta</Trans>
            <DateBox
              ref={refs.endDate}
              width="100%"
              displayFormat="dd/MM/yyyy"
              value={innerFilter.endDate}
              disabled={innerFilter.line === null}
              placeholder={i18n()._(t`Seleccionar...`)}
              onValueChanged={e => setState({ ...e, source: "endDate" })}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            5. <Trans>Turno</Trans>
            <SelectBox
              ref={refs.shift}
              dataSource={Shifts.KeyValuePair}
              disabled={innerFilter.line === null}
              valueExpr="key"
              displayExpr="name"
              onValueChanged={e => setState({ ...e, source: "shift" })}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            6. <Trans>Producto</Trans>
            <SelectBox
              ref={refs.product}
              disabled={innerFilter.shift === null}
              dataSource={dataSources.products}
              valueExpr="value"
              displayExpr="text"
              onValueChanged={e => setState({ ...e, source: "product" })}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            7. <Trans>Tipo de Parada</Trans>
            <SelectBox
              ref={refs.stopType}
              dataSource={dataSources.stopTypes}
              disabled={innerFilter.product === null}
              valueExpr="value"
              displayExpr="text"
              onValueChanged={e => setState({ ...e, source: "stopType" })}
            />
          </Grid.Column>
          <Grid.Column width={2}>
            8. <Trans>Nivel</Trans>
            <SelectBox
              ref={refs.stopLevel}
              value={innerFilter.stopLevel}
              valueExpr="value"
              displayExpr="text"
              dataSource={dataSources.stopLevels}
              disabled={innerFilter.stopType === null}
              placeholder={i18n()._(t`Seleccionar...`)}
              onValueChanged={e => setState({ ...e, source: "stopLevel" })}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column floated="right" width={5}>
            <Button
              disabled={!allHaveValues(innerFilter)}
              positive
              fluid
              floated="right"
              icon="checkmark"
              type="submit"
              content={i18n()._(t`Aplicar`)}
              onClick={() => applyFilter()}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormReact>
  );
}

function getHeaders() {
  let headers = authHeader();
  headers["Content-Type"] = "application/x-www-form-urlencoded";
  return headers;
}

function CustomLabel({ title, value }) {
  return value ? (
    <Label horizontal color="teal">{`${title}: ${value}`}</Label>
  ) : null;
}

function FilterSelection({ filter }) {
  console.log({ filter });
  return (
    <div>
      <CustomLabel title="Sector" value={filter.sectorName} />
      <CustomLabel title="Línea" value={filter.lineName} />
      <CustomLabel
        title="Desde"
        value={filter.startDate.format("D/M/YYYY - HH:mm")}
      />
      <CustomLabel
        title="Hasta"
        value={filter.endDate.format("D/M/YYYY - HH:mm")}
      />
      <CustomLabel title="Producto" value={filter.productName} />
      <CustomLabel title="Parada" value={filter.stopTypeName} />
      <CustomLabel title="Nivel" value={filter.stopLevelName} />
    </div>
  );
}

function FilterAccordion({ open, setOpen, title, children }) {
  return (
    <Card fluid color="blue">
      <Accordion fluid styled>
        <Accordion.Title
          active={open}
          onClick={() => setOpen(!open)}
          style={{ backgroundColor: "#1c425726" }}
        >
          <Icon name="filter" />
          {title}
          <Icon
            name={`chevron ${open ? "down" : "right"}`}
            style={{ float: "right" }}
          />
        </Accordion.Title>
        <Accordion.Content active={open}>{children}</Accordion.Content>
      </Accordion>
    </Card>
  );
}

function OrdersSummary({ filter }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (filter == null) return;

    setLoading(true);

    getProductionRange(
      filter.line,
      filter.startDate.format(),
      filter.endDate.format(),
      filter.product
    ).then(data => {
      setData(data);
      setLoading(false);
    });
  }, [filter]);

  return (
    <>
      <Header as="h3">
        <Trans>Silo/Secuencia</Trans>
      </Header>
      <Segment basic loading={loading}>
        <DataGrid
          dataSource={data}
          showBorders={false}
          style={{ maxHeight: 500, fontSize: "1rem" }}
          allowColumnResizing={true}
          grouping={{ autoExpandAll: false }}
          summary={{
            totalItems: [
              {
                column: "packs",
                summaryType: "sum",
                alignByColumn: true,
                displayFormat: "{0}"
              },
              {
                column: "wrappers",
                summaryType: "sum",
                alignByColumn: true,
                displayFormat: "{0}"
              },
              {
                column: "units",
                summaryType: "sum",
                alignByColumn: true,
                displayFormat: "{0}"
              }
            ]
          }}
        >
          <Scrolling mode={"virtual"} />
          <Column
            dataField={"lot"}
            minWidth={120}
            defaultSortOrder="desc"
            caption={i18n()._(t`Lote`)}
          />
          <Column dataField={"productCode"} caption={i18n()._(t`Código`)} />
          <Column dataField={"productName"} caption={i18n()._(t`Nombre`)} />
          <Column dataField={"packs"} caption={i18n()._(t`Bultos`)} />
          <Column dataField={"wrappers"} caption={i18n()._(t`Paquetes`)} />
          <Column dataField={"units"} caption={i18n()._(t`Cantidad`)} />
          <Column dataField={"wrapperType"} caption={i18n()._(t`Unidad`)} />
        </DataGrid>
      </Segment>
    </>
  );
}

function StopsSummary({ filter }) {
  const [stops, setStops] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (filter == null) return;

    setLoading(true);

    let formData = new FormData();
    if (filter.line != null) formData.append("line", filter.line);
    if (filter.product != null) formData.append("product", filter.product);
    if (filter.startDate != null)
      formData.append("startDate", filter.startDate.format());
    if (filter.endDate != null)
      formData.append("endDate", filter.endDate.format());
    if (filter.stopType != null && filter.stopType > 0)
      formData.append("root", filter.stopType);

    fetch(`/api/stops/getStopsSummary?${new URLSearchParams(formData)}`, {
      headers: getHeaders(),
      method: "GET"
    })
      .then(response => response.json())
      .then(data => {
        data = data.map(stop => {
          if (moment(stop.startDate).isBefore(filter.startDate))
            stop.startDate = filter.startDate.format();

          if (moment(stop.endDate).isAfter(filter.endDate))
            stop.endDate = filter.endDate.format();

          return stop;
        });

        let group = data.reduce((acc, cur) => {
          let { start, end } = {
            start: moment(cur.startDate),
            end: moment(cur.endDate)
          };
          let match = acc.filter(
            x =>
              x.path ===
              cur.path
                .split("/")
                .slice(0, filter.stopLevel)
                .join("/")
          )[0];

          if (match) {
            match.duration += moment.duration(end.diff(start)).asSeconds();
            match.stopCount++;
          } else {
            acc.push({
              path: cur.path
                .split("/")
                .slice(0, filter.stopLevel)
                .join("/"),
              duration: moment.duration(end.diff(start)).asSeconds(),
              stopCount: 1
            });
          }
          return acc;
        }, []);

        let totalStopTime = group.reduce((acc, cur) => acc + cur.duration, 0);

        if (totalStopTime !== 0) {
          group = group.map(s => {
            s.percent = (100 * s.duration) / totalStopTime;
            s.duration = s.duration / 60;
            return s;
          });
        }

        setStops(group);
        setLoading(false);
      })
      .catch(err => {
        notify(
          i18n()._(t`Ha ocurrido un error, por favor vuelva a intentarlo`),
          "error",
          5000
        );
        console.error(err);
      });
  }, [filter]);

  return (
    <>
      <Header as="h3">
        <Trans>Paradas</Trans>
      </Header>
      <Segment loading={loading} basic>
        <DataGrid
          dataSource={stops}
          showBorders={false}
          style={{ maxHeight: 500, fontSize: "1rem" }}
          allowColumnResizing={true}
          summary={{
            totalItems: [
              {
                column: "duration",
                summaryType: "sum",
                alignByColumn: true,
                displayFormat: "{0}",
                valueFormat: { type: "decimal", precision: 2 }
              },
              {
                column: "stopCount",
                summaryType: "sum",
                alignByColumn: true,
                displayFormat: "{0}"
              }
            ]
          }}
        >
          <Scrolling mode={"virtual"} />
          <Column dataField="path" minWidth={120} caption="Ruta" />
          <Column
            dataField="duration"
            defaultSortOrder="desc"
            customizeText={t => t.value.toFixed(2)}
            caption={i18n()._(t`Minutos`)}
          />
          <Column dataField="stopCount" caption={i18n()._(t`Cantidad`)} />
          <Column
            dataField="percent"
            caption="Porcentaje"
            customizeText={t => `${t.value.toFixed(2)}%`}
            alignment="right"
          />
          <Column
            dataField={"percent"}
            caption={i18n()._(t`Grafico`)}
            minWidth={100}
            allowResizing={false}
            alignment={"center"}
            cssClass={"bullet pareto"}
            cellComponent={r => <ProgressBarPareto percent={r.data.percent} />}
          />
        </DataGrid>
      </Segment>
    </>
  );
}

function ProgressBarPareto({ percent }) {
  return (
    <Bullet
      showTarget={false}
      margin={{ top: 0, left: 0, right: 0 }}
      showZeroLevel={false}
      value={percent}
      color="#2185d0"
      width="100%"
      startScaleValue={0}
      endScaleValue={100}
      tooltip={{ enabled: false }}
    />
  );
}

function allHaveValues(obj) {
  if (obj == null) return false;

  for (let p in obj) if (obj[p] == null) return false;

  return true;
}
