import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import {
  Segment,
  Progress,
  Header,
  Grid,
  Label,
  Icon,
  Popup
} from "semantic-ui-react";
import { getLinesOverview } from "./service";
import { Trans } from "@lingui/macro";
import { MttoStatus } from "../../utils/Consts";
import ReactMinimalPieChart from "react-minimal-pie-chart";
import StopIndicator from "../stops/components/StopIndicator";
import { useInterval } from "../../utils/IntervalHook";
import { getLineTargets } from "../orders/service.js";

const OverviewPage = ({ location }) => {
  //Obtención del nombre de sector y lineas a hacer resumen.
  let querySectors = queryString.parse(location.search);
  let sectorName = querySectors.sector ? querySectors.sector : "Undefined";
  let sectorLines = querySectors.lines
    ? querySectors.lines.split(",").map(val => parseInt(val, 10))
    : [];

  //Get data
  const [lines, setLines] = useState([]);

  useInterval(
    () =>
      getLinesOverview(sectorLines)
        .then(lines => setLines(lines))
        .catch(error => console.log("Error cargando indicadores", error)),
    10000,
    [sectorName]
  );

  return <OverviewLayout sectorName={sectorName} lines={lines} />;
};

const OverviewLayout = ({ lines, sectorName }) => {
  const [columns, setColumns] = useState(3);
  const changeColumns = () => setColumns(columns === 5 ? 3 : columns + 1);
  // - Redoxon TA Orange TAEF TUBE 3x10 BR AUB GX
  return (
    <Segment
      basic
      style={layoutStyles.outerSegment}
      loading={!lines || lines.length === 0}
    >
      <Segment basic clearing style={layoutStyles.innerSegment}>
        <Header as="h3" floated="left" style={layoutStyles.headers}>
          <Trans>{`Resumen ${sectorName}`}</Trans>
        </Header>
        <Header as="h3" floated="right">
          <Label as="a" onClick={changeColumns}>
            <Icon name="grid layout" />
            {columns}
          </Label>
        </Header>
      </Segment>
      <Grid columns={columns} doubling padded={false}>
        {lines.map((overview, index) => (
          <Grid.Column key={index}>
            <OverviewDetail overview={overview} />
          </Grid.Column>
        ))}
      </Grid>
    </Segment>
  );
};

const OverviewDetail = ({ overview }) => {
  const [targets, setTargets] = useState([]);
  const [target, setTarget] = useState(null);

  //Requested data
  const { orderData, callData, stopData, line } = overview || {};
  const {
    lot = " ",
    oee = 0,
    throughput = 0,
    utilization = 0,
    productionQuantity = 0,
    desiredProductionQuantity = 0,
    productName = ""
  } = orderData || {};

  const inactiveLine = typeof orderData === "undefined";

  //Maintenance information
  let mttoClasses = "";
  let mttoReason = "";
  if (callData) {
    if (callData.status === MttoStatus.Waiting) mttoClasses = "waiting";
    if (callData.status === MttoStatus.Started) mttoClasses = "started";
    mttoReason = callData.callReason;
  }

  useEffect(() => {
    getLineTargets().then(tgs => setTargets(tgs));
  }, []);
  useEffect(() => {
    let filt = targets.filter(x => x.lineID === parseInt(line));
    if (filt.length > 0) {
      setTarget(filt[0]);
    }
  }, [line, targets]);

  return (
    <Segment
      disabled={inactiveLine}
      style={indicatorStyles.outerSegmnet}
      className={`overview mtto ${mttoClasses}`}
    >
      <Header style={indicatorStyles.header} as="h5">
        {callData && (
          <Popup
            trigger={
              <Icon size="small" color="red" name="warning circle"></Icon>
            }
          >
            {mttoReason}
          </Popup>
        )}
        <Link
          to={`/?line=${line}`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <Trans>{`Línea ${line}`}</Trans>
        </Link>
      </Header>
      <Link
        to={`/?line=${line}`}
        style={{ textDecoration: "none", color: "black" }}
      >
        <Trans>{`${productName}`}</Trans>
      </Link>
      <div style={indicatorStyles.verticalContent}>
        <div style={indicatorStyles.horizontalContent}>
          <ReactMinimalPieChart
            totalValue={100}
            style={indicatorStyles.pieChart}
            data={[
              {
                color:
                  target && oee >= target.targetOEE / 100
                    ? "#31aca6"
                    : "#ec5453",
                value: oee * 100
              }
            ]}
            {...defaultPieChartProps}
            label={({ color, data, style, y, dataIndex, ...props }) => (
              <>
                <text
                  y={35}
                  style={{ fonFamily: "sans-serif", fontSize: "14px" }}
                  fill={color}
                  dominantBaseline="middle"
                  {...props}
                  key={1}
                >
                  OEE
                </text>
                <text
                  y={60}
                  style={style}
                  fill={color}
                  dominantBaseline="middle"
                  {...props}
                  key={2}
                >{`${
                  data[0].percentage > 999
                    ? "999+"
                    : data[0].percentage.toFixed(0)
                }%`}</text>
              </>
            )}
          />
          <ReactMinimalPieChart
            totalValue={100}
            style={indicatorStyles.pieChartMin}
            data={[{ color: "#31ACA6", value: utilization * 100 }]}
            {...defaultPieChartProps}
            label={({ color, data, style, y, dataIndex, ...props }) => (
              <>
                <text
                  y={35}
                  style={{ fonFamily: "sans-serif", fontSize: "14px" }}
                  fill={color}
                  dominantBaseline="middle"
                  {...props}
                  key={1}
                >
                  Utilization
                </text>
                <text
                  y={60}
                  style={style}
                  fill={"#31ACA6"}
                  dominantBaseline="middle"
                  {...props}
                  key={2}
                >{`${
                  data[0].percentage > 999
                    ? "999+"
                    : data[0].percentage.toFixed(0)
                }%`}</text>
              </>
            )}
          />
          <ReactMinimalPieChart
            totalValue={100}
            style={indicatorStyles.pieChartMin}
            data={[{ color: "#48A7F4", value: throughput * 100 }]}
            {...defaultPieChartProps}
            label={({ color, data, style, y, dataIndex, ...props }) => (
              <>
                <text
                  y={35}
                  style={{ fonFamily: "sans-serif", fontSize: "14px" }}
                  fill={color}
                  dominantBaseline="middle"
                  {...props}
                  key={1}
                >
                  Throughput
                </text>
                <text
                  y={60}
                  style={style}
                  fill={"#48A7F4"}
                  dominantBaseline="middle"
                  {...props}
                  key={2}
                >{`${
                  data[0].percentage > 999
                    ? "999+"
                    : data[0].percentage.toFixed(0)
                }%`}</text>
              </>
            )}
          />

          <StopIndicator
            style={indicatorStyles.pieChartMin}
            stopData={stopData}
            callData={callData}
          />
        </div>
      </div>
      <div style={{ flex: "0 1 auto" }}>
        <span>
          <Trans>
            Cantidad {productionQuantity} de {desiredProductionQuantity}
          </Trans>
        </span>
        <Progress
          className={"indicator"}
          precision={0}
          value={productionQuantity}
          total={desiredProductionQuantity}
          progress
          size={"small"}
          color={"blue"}
        />
      </div>
    </Segment>
  );
};

//#region Styles & default props
const layoutStyles = {
  outerSegment: { paddingTop: 0, minHeight: 400 },
  innerSegment: { margin: 0 },
  headers: { margin: 0 }
};

const indicatorStyles = {
  outerSegmnet: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  header: { flex: "0 1 auto", marginBottom: 0 },
  verticalContent: {
    flex: "1 1 auto",
    height: "100%",
    padding: "0px",
    overflow: "hidden"
  },
  horizontalContent: {
    display: "flex",
    justifyContent: "space-around",
    height: "100%"
  },
  pieChart: { flex: "0 1 auto", height: "100%", width: "100%", padding: 5 },
  pieChartMin: { flex: "0 1 auto", height: "100%", width: "80%", padding: 5 }
};

const defaultPieChartProps = {
  animate: false,
  animationDuration: 500,
  animationEasing: "ease-out",
  cx: 50,
  cy: 50,
  labelPosition: 0,
  labelStyle: { fontFamily: "sans-serif", fontSize: "25px" },
  background: "#E5E5E5",
  lengthAngle: 360,
  lineWidth: 10,
  paddingAngle: 0,
  radius: 50,
  ratio: 1,
  rounded: false,
  startAngle: -90
};
//#endregion

export default OverviewPage;
