import React from "react";
import { Segment, Button, Icon, Grid, GridColumn } from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import { SystemStops } from "../../../utils/Consts";

export const Explorer = ({ tree, currentLine }) => {
  try {
    tree = activeNodesSingle(tree);
  } catch (ex) {}

  //Get selected data
  let { current, children } = tree || {};
  let { reason, isCategory } = current || {};

  let placeholder;
  let content;
  if (isCategory === true && currentLine !== -1) {
    placeholder = false;
    content = children.map(child => {
      let enabledForLine = [];
      try {
        enabledForLine = JSON.parse(child.enabledForLine);
      } catch {}
      if (enabledForLine.includes(currentLine)) {
        return <NormalStop color={child.color}>{child.reason}</NormalStop>;
      } else {
        return <HiddenStop>{child.reason}</HiddenStop>;
      }
    });
  } else {
    reason = "...";
    placeholder = true;
    content =
      currentLine === -1 ? (
        <Trans>Seleccione una línea</Trans>
      ) : (
        <Trans>Seleccione una categoría</Trans>
      );
  }

  return (
    <Segment.Group>
      <Segment>{reason}</Segment>
      <Segment
        style={{ marginTop: 0 }}
        textAlign="center"
        placeholder={placeholder}
      >
        <Grid centered padded={false}>
          {content}
        </Grid>
      </Segment>
    </Segment.Group>
  );
};

const hiddenStop = {
  border: "dotted 1px lightgray",
  backgroundColor: "white",
  color: "lightgray"
};

const HiddenStop = ({ children, color }) => (
  <GridColumn mobile={"8"} tablet={"5"} computer={"4"} style={{ padding: 5 }}>
    <Button size="tiny" fluid style={{ ...hiddenStop }}>
      <Icon name="eye slash outline" />
      {children}
    </Button>
  </GridColumn>
);
const NormalStop = ({ children, color }) => (
  <GridColumn mobile={"8"} tablet={"5"} computer={"4"} style={{ padding: 5 }}>
    <Button
      size="tiny"
      fluid
      style={{ color: "white", backgroundColor: color }}
    >
      {children}
    </Button>
  </GridColumn>
);

const activeNodesSingle = data => {
  let tree = [...data];
  //Filter selected
  let selectedNode = tree.find(node => node.selected);

  //1. Prevent empty selection.
  if (typeof selectedNode === "undefined") {
    throw new Error("Empty selection");
  }
  //2. Prevent select system nodes.
  if (selectedNode.reasonID === SystemStops.SystemCategory) {
    throw new Error(`Not user node exception`);
  }
  //Return tree filtered
  return {
    current: selectedNode,
    children: tree.filter(node => node.parentID === selectedNode.reasonID)
  };
};
