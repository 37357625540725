import React, { Component, useState } from "react";
import { Segment, Header } from "semantic-ui-react";
import {
  Column,
  FormItem,
  DataGrid,
  FilterRow,
  HeaderFilter,
  EmailRule,
  GroupPanel,
  Scrolling,
  Editing,
  Lookup,
  RequiredRule,
  StringLengthRule
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { UserRoles } from "../../utils/Consts";
import Tabs from "devextreme-react/tabs";
import { DateBox } from "devextreme-react";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";
import moment from "moment";

export default class GroupsConfig extends Component {
  render() {
    return (
      <Segment basic padded>
        <Header as="h2">
          <Trans>Configuración de grupos de notificación</Trans>
          <Header.Subheader>
            <Trans>
              Actualiza la información de los grupos de notificación del
              sistema.
            </Trans>
          </Header.Subheader>
        </Header>
        <GroupsDataGrid />
      </Segment>
    );
  }
}

GroupsConfig.getMenuItem = () => <Trans>Grupo Notificación</Trans>;
GroupsConfig.getPermissions = userLevel =>
  userLevel === UserRoles.ADMINISTRATOR;

//#region Datasources
const urlGroups = "/api/notifications/groups";
const groupsDataSource = createStore({
  key: "groupID",
  loadUrl: `${urlGroups}`,
  insertUrl: `${urlGroups}`,
  updateUrl: `${urlGroups}`,
  deleteUrl: `${urlGroups}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const urlSchedule = "/api/notifications/schedules";
const scheduleDataSource = group => ({
  store: createStore({
    key: "scheduleID",
    loadUrl: `${urlSchedule}`,
    insertUrl: `${urlSchedule}`,
    updateUrl: `${urlSchedule}`,
    deleteUrl: `${urlSchedule}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  }),
  filter: [["groupID", "=", group]]
});

const urlTargets = "/api/notifications/targets";
const targetsDataSource = group => ({
  store: createStore({
    key: "targetID",
    loadUrl: `${urlTargets}`,
    insertUrl: `${urlTargets}`,
    updateUrl: `${urlTargets}`,
    deleteUrl: `${urlTargets}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  }),
  filter: [["groupID", "=", group]]
});

const urlWatchers = "/api/notifications/watchers";
const watchersDataSource = group => ({
  store: createStore({
    key: "watcherID",
    loadUrl: `${urlWatchers}`,
    insertUrl: `${urlWatchers}`,
    updateUrl: `${urlWatchers}`,
    deleteUrl: `${urlWatchers}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  }),
  filter: [["groupID", "=", group]]
});

const sectorsSource = createStore({
  key: "Value",
  loadUrl: `/api/sectors/lookup`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const buildingsSource = createStore({
  key: "Value",
  loadUrl: `/api/buildings/lookup`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const getFilteredSectors = options => {
  return {
    store: sectorsSource,
    filter: options.data ? ["buildingId", "=", options.data.buildingId] : null
  };
};

const resourcesSource = createStore({
  key: "Value",
  loadUrl: `/api/Resources/LookupResources`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const getFilteredResources = options => {
  return {
    store: resourcesSource,
    filter: options.data ? ["sectorID", "=", options.data.sectorID] : null
  };
};

//#endregion

//#region Notifications Grid

class GroupsDataGrid extends React.Component {
  render() {
    return (
      <DataGrid
        dataSource={groupsDataSource}
        showBorders={true}
        style={{ maxHeight: 500, fontSize: "1rem" }}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        remoteOperations={true}
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={false} />
        <GroupPanel visible={true} />
        <Scrolling mode={"virtual"} />
        <Editing
          mode={"form"}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
          useIcons={true}
        />
        <Column
          dataField={"groupID"}
          caption={i18n()._(t`Group`)}
          key
          visible={false}
        >
          <FormItem visible={false} />
        </Column>
        <Column dataField={"name"} caption={i18n()._(t`Nombre`)}>
          <RequiredRule
            message={i18n()._(t`Debe definir un nombre de grupo.`)}
          />
          <StringLengthRule
            max={20}
            message={i18n()._(t`El nombre no debe superar los 20 caracteres.`)}
          />
        </Column>
        <Column dataField={"description"} caption={i18n()._(t`Descripción`)}>
          <StringLengthRule
            max={100}
            message={i18n()._(
              t`La descripción debe tener un máximo de 100 caracteres.`
            )}
          />
        </Column>
        <Column dataField={"email"} caption={i18n()._(t`Email`)}>
          <EmailRule
            message={i18n()._(t`Debe ingresar un formato de email válido.`)}
          />
        </Column>
        <Column
          dataField={"enabled"}
          dataType={"boolean"}
          caption={i18n()._(t`Habilitada`)}
        ></Column>
        <Column dataField={"updatedBy"} caption={i18n()._(t`Actualizado  por`)}>
          <FormItem visible={false} />
        </Column>
        <Column
          dataField={"updateDate"}
          dataType={"date"}
          format={"dd/MM/yyyy HH:mm:ss"}
          caption={i18n()._(t`Ultima actualización`)}
        >
          <FormItem visible={false} />
        </Column>
        <Column
          caption={i18n()._(t`Horarios`)}
          visible={false}
          editCellComponent={e => <GroupDetail data={e.data} />}
        >
          <FormItem colSpan={2} label={{ visible: false }} />
        </Column>
      </DataGrid>
    );
  }
}

const GroupDetail = ({ data = {} }) => {
  const { groupID } = data;

  const TARGET_TAB = {
    id: 0,
    text: "Horarios",
    icon: "event",
    content: "User tab content"
  };
  const SCHEDULE_TAB = {
    id: 1,
    text: "Eventos",
    icon: "bulletlist",
    content: "User tab content"
  };
  const WATCHER_TAB = {
    id: 2,
    text: "Notificaciones",
    icon: "message",
    content: "User tab content"
  };
  const tabs = [TARGET_TAB, SCHEDULE_TAB, WATCHER_TAB];
  const [selected, setSelected] = useState(0);

  return (
    <>
      <Tabs
        dataSource={tabs}
        selectedIndex={selected}
        style={{ marginBottom: 5 }}
        onItemClick={({ itemIndex }) => setSelected(itemIndex)}
      />
      {selected === TARGET_TAB.id && <Schedules groupID={groupID} />}
      {selected === SCHEDULE_TAB.id && <Targets groupID={groupID} />}
      {selected === WATCHER_TAB.id && <Watchers groupID={groupID} />}
    </>
  );
};
//#endregion

//#region Schedules

const Schedules = ({ groupID }) => {
  return (
    <>
      <DataGrid
        disabled={groupID == null}
        onEditorPreparing={({ editorElement, dataField, ...props }) => {
          if (dataField === "startTime") {
            editorElement.classList.add("dx-datetime-only");
          } else if (dataField === "endTime") {
            editorElement.classList.add("dx-datetime-only");
          }
        }}
        onInitNewRow={e => {
          e.data.groupID = groupID;
        }}
        key={"scheduleID"}
        dataSource={scheduleDataSource(groupID)}
        showBorders={true}
        style={{ maxHeight: 300, fontSize: "1rem" }}
        remoteOperations={true}
      >
        <Editing
          mode={"cell"}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
          useIcons={true}
        />
        <Column
          dataField={"startTime"}
          caption={i18n()._(t`Desde`)}
          dataType={"datetime"}
          format={"HH:mm"}
          editCellComponent={({ value, setValue }) => (
            <DateBoxEditor value={value} setValue={setValue} />
          )}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={"endTime"}
          caption={i18n()._(t`Hasta`)}
          dataType={"datetime"}
          format={"HH:mm"}
          editCellComponent={({ value, setValue }) => (
            <DateBoxEditor value={value} setValue={setValue} />
          )}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={"monday"}
          caption={i18n()._(t`Lunes`)}
          dataType={"boolean"}
        />
        <Column
          dataField={"tuesday"}
          caption={i18n()._(t`Martes`)}
          dataType={"boolean"}
        />
        <Column
          dataField={"wednesday"}
          caption={i18n()._(t`Miercoles`)}
          dataType={"boolean"}
        />
        <Column
          dataField={"thursday"}
          caption={i18n()._(t`Jueves`)}
          dataType={"boolean"}
        />
        <Column
          dataField={"friday"}
          caption={i18n()._(t`Viernes`)}
          dataType={"boolean"}
        />
        <Column
          dataField={"saturday"}
          caption={i18n()._(t`Sábado`)}
          dataType={"boolean"}
        />
        <Column
          dataField={"sunday"}
          caption={i18n()._(t`Domingo`)}
          dataType={"boolean"}
        />
      </DataGrid>
    </>
  );
};

const DateBoxEditor = ({ value, setValue }) => (
  <DateBox
    type={"datetime"}
    width={"100%"}
    format={"HH:mm"}
    defaultValue={value}
    onValueChanged={e => setValue(moment(e.value).format("HH:mm"))}
    displayFormat={"HH:mm"}
    onOpened={({ component }) => {
      const popup = component._popup.content();
      popup.classList.add("dx-datetime-only");
    }}
  />
);
//#endregion

//#region Targets
const GetChannels = () => {
  return [
    //{ value: 0, text: "Email" },
    { value: 1, text: "Telegram" }
  ];
};

const GetTypes = () => {
  return [
    { value: 0, text: "Paradas" },
    { value: 1, text: "Mantenimiento" }
    //{ value: 2, text: "Administración" }
  ];
};

const Targets = ({ groupID }) => {
  return (
    <>
      <DataGrid
        disabled={groupID == null}
        onInitNewRow={e => {
          e.data.groupID = groupID;
        }}
        key={"targetID"}
        dataSource={targetsDataSource(groupID)}
        showBorders={true}
        style={{ maxHeight: 300, fontSize: "1rem" }}
        remoteOperations={true}
      >
        <Editing
          mode={"cell"}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
          useIcons={true}
        />
        <Column dataField={"type"} caption={i18n()._(t`Evento`)}>
          <Lookup
            dataSource={GetTypes()}
            valueExpr={"value"}
            displayExpr={"text"}
          />
          <RequiredRule />
        </Column>
        <Column dataField={"channel"} caption={i18n()._(t`Canal`)}>
          <Lookup
            dataSource={GetChannels()}
            valueExpr={"value"}
            displayExpr={"text"}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={"notifyAfterMinutes"}
          caption={i18n()._(t`Tiempo de notif (min).`)}
        >
          <RequiredRule />
        </Column>
      </DataGrid>
    </>
  );
};

//#endregion

//#region Watchers
class Watchers extends React.Component {
  buildingChanged(rowData, value) {
    rowData.sectorID = null;
    rowData.lineID = null;
    this.defaultSetCellValue(rowData, value);
  }
  sectorChanged(rowData, value) {
    rowData.lineID = null;
    this.defaultSetCellValue(rowData, value);
  }

  render() {
    const groupID = this.props.groupID;
    return (
      <DataGrid
        disabled={groupID == null}
        onInitNewRow={e => {
          e.data.groupID = groupID;
        }}
        onEditorPreparing={e => {
          if (e.parentType === "dataRow") {
            if (e.dataField === "sectorID") {
              e.editorOptions.disabled =
                typeof e.row.data.buildingId !== "number";
            } else if (e.dataField === "lineID") {
              e.editorOptions.disabled =
                typeof e.row.data.sectorID !== "number";
            }
          }
        }}
        key={"watcherID"}
        dataSource={watchersDataSource(groupID)}
        showBorders={true}
        style={{ maxHeight: 300, fontSize: "1rem" }}
        remoteOperations={true}
      >
        <Editing
          mode={"cell"}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
          useIcons={true}
        />
        <Column
          setCellValue={this.buildingChanged}
          dataField={"buildingId"}
          caption={i18n()._(t`Escuchar edificio`)}
        >
          <Lookup
            dataSource={buildingsSource}
            valueExpr={"value"}
            displayExpr={"text"}
            allowClearing
          />
        </Column>
        <Column
          setCellValue={this.sectorChanged}
          dataField={"sectorID"}
          caption={i18n()._(t`Escuchar sector`)}
        >
          <Lookup
            dataSource={getFilteredSectors}
            valueExpr={"value"}
            displayExpr={"text"}
            allowClearing
          />
        </Column>
        <Column dataField={"lineID"} caption={i18n()._(t`Escuchar línea`)}>
          <Lookup
            dataSource={getFilteredResources}
            valueExpr={"value"}
            displayExpr={"text"}
            allowClearing
          />
        </Column>
      </DataGrid>
    );
  }
}

//#endregion
