import React from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import { Icon, Button, Dropdown } from "semantic-ui-react";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { UserRoles } from "../../../utils/Consts";

const mapStateToProps = state => {
  return {
    user: state.authentication.user
  };
};

//Helper para mostrar pantalla dividida en este panel de configuración
const SplitLayout = ({ children }) => (
  <Grid padded={false}>
    <Grid.Row>
      <Grid.Column mobile={16} tablet={16} computer={10} largeScreen={8}>
        {children[0]}
      </Grid.Column>
      <Grid.Column mobile={16} tablet={16} computer={6} largeScreen={8}>
        {children[1]}
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

SplitLayout.First = ({ children }) => <div>{children}</div>;
SplitLayout.Second = ({ children }) => <div>{children}</div>;

//Menu de comandos
const CommandMenu = connect(mapStateToProps)(props => {
  const { resources, lineName, treeMode } = props.data;
  const {
    allowEdit,
    allowDelete,
    allowChangeVisibility,
    allowAdd
  } = props.options;
  const {
    changeLine,
    addNode,
    addCategory,
    editNode,
    deleteNode,
    enableNode,
    hideNode,
    changeMode
  } = props.actions;

  return (
    <I18n>
      {({ i18n }) => (
        <Button.Group basic>
          <Dropdown
            disabled={props.user.roleID !== UserRoles.SUPERVISOR || !treeMode}
            as={Button}
            options={resources}
            scrolling
            text={lineName}
            onChange={(e, { value }) => changeLine(resources[value])}
          />
          <Button
            icon="add"
            onClick={addNode}
            disabled={
              props.user.roleID !== UserRoles.SUPERVISOR ||
              (treeMode && !allowAdd)
            }
            data-tooltip={i18n._(t`Agregar parada`)}
            data-position="bottom center"
          />
          <Button
            style={{
              paddingLeft: "10px",
              paddingRight: "10px"
            }}
            onClick={addCategory || !treeMode}
            disabled={
              props.user.roleID !== UserRoles.SUPERVISOR ||
              (treeMode && !allowAdd)
            }
            data-tooltip={i18n._(t`Agregar categoría`)}
            data-position="bottom center"
          >
            <Icon.Group>
              <Icon name="folder outline" />
              <Icon corner="bottom left" name="add" />
            </Icon.Group>
          </Button>
          <Button
            icon="edit outline"
            disabled={
              props.user.roleID !== UserRoles.SUPERVISOR ||
              (treeMode && !allowEdit)
            }
            onClick={editNode}
            data-tooltip={i18n._(t`Editar`)}
            data-position="bottom center"
          />
          <Button
            icon="trash alternate outline"
            onClick={deleteNode}
            disabled={
              props.user.roleID !== UserRoles.SUPERVISOR ||
              (treeMode && !allowDelete)
            }
            data-tooltip={i18n._(t`Eliminar`)}
            data-position="bottom center"
          />
          <Button
            icon="eye"
            disabled={
              props.user.roleID !== UserRoles.SUPERVISOR ||
              !(treeMode && allowChangeVisibility)
            }
            onClick={enableNode}
            data-tooltip={i18n._(t`Hacer visible para línea: ${lineName}`)}
            data-position="bottom center"
          />
          <Button
            icon="eye slash"
            disabled={
              props.user.roleID !== UserRoles.SUPERVISOR ||
              !(treeMode && allowChangeVisibility)
            }
            onClick={hideNode}
            data-tooltip={i18n._(t`Ocultar para línea: ${lineName}`)}
            data-position="bottom center"
          />
          <Button
            icon={treeMode ? "table" : "sitemap"}
            onClick={() => changeMode(!treeMode)}
            data-tooltip={i18n._(t`Cambiar modo`)}
            data-position="bottom center"
          />
        </Button.Group>
      )}
    </I18n>
  );
});

/**
 * Resources ready to dropdown.
 */
export function loadResources(resourcesLookupDataSource) {
  return resourcesLookupDataSource.load().then(data => {
    let lines = data.map((e, i) => ({
      lineID: e.value,
      lineName: e.text,
      text: e.text,
      value: i
    }));
    lines.push({ text: "Todas", value: -1 });
    return lines;
  });
}

export { SplitLayout, CommandMenu };
