import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Segment, Button, Header } from "semantic-ui-react";
import {
  Column,
  HeaderFilter,
  DataGrid,
  FilterRow,
  GroupPanel,
  Scrolling,
  Export,
  ColumnChooser,
  FilterPanel
} from "devextreme-react/data-grid";
import { UserRoles } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { RecognizeModal } from "./Recognize";
import moment from "moment";
import Split from "./Split";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

//#region Page
const StopsPage = ({ auth, terminal }) => {
  useEffect(() => {
    document.title = "OEE++ Paradas";
  }, []);

  return (
    <Segment padded="very" style={{ margin: 20 }}>
      <Header as="h2">
        <Trans>Listado de paradas</Trans>
        <Header.Subheader>
          <Trans>Visualiza las paradas registradas en el sistema</Trans>
        </Header.Subheader>
      </Header>
      <Stops user={auth.user} terminal={terminal.terminal} />
    </Segment>
  );
};
//#endregion

//#region Grid
const Stops = ({ user, terminal }) => {
  if (!user) return null;

  //Refs to external components
  const recognizeRef = useRef();
  const splitRef = useRef();
  const gridRef = useRef();

  //User actions
  const splitStop = stop => splitRef.current.split(stop);
  const recognizeClick = (action, stop) =>
    recognizeRef.current.show(stop, action);

  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const filterValue = params.get("line") == null ? null : [
    ["LineID", "=", params.get("line")],
    "AND",
    ["Order", "=", params.get("order")],
    "AND",
    ["StopID", "=", params.get("stopId")]
  ];

  return (
    <>
      <RecognizeModal
        ref={recognizeRef}
        recognized={() => gridRef.current.instance.refresh()}
      />
      <Split user={user} grid={gridRef} ref={splitRef} />
      <DataGrid
        ref={gridRef}
        key={["order", "lineID", "stopID"]}
        dataSource={dataSource}
        showBorders={true}
        style={{ maxHeight: 500, fontSize: "1rem" }}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        remoteOperations={true}
        columnAutoWidth={true}
        defaultFilterValue={filterValue}
        onToolbarPreparing={e => customToolbar(e)}
      >
        <Export enabled allowExportSelectedData={false} fileName="OEE_PARADA" />
        <ColumnChooser enabled />
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} />
        <Scrolling mode={"virtual"} />
        <Column
          caption={i18n()._(t`Silo`)}
          dataField={"order"}
          calculateCellValue={d => d.order.split("-")[0]}
        />
        <Column
          caption={i18n()._(t`Secuencia`)}
          dataField={"order"}
          calculateCellValue={d => d.order.split("-")[1]}
        />
        <Column caption={i18n()._(t`Línea`)} width={70} dataField={"lineID"} />
        <Column caption={i18n()._(t`ID`)} width={70} dataField={"stopID"} />
        <Column caption={i18n()._(t`Split Nro.`)} dataField={"split"} />
        <Column caption={i18n()._(t`Equipo`)} dataField={"equipmentName"} />
        <Column caption={i18n()._(t`Motivo`)} dataField={"reason"} />
        <Column
          caption={i18n()._(t`Desde`)}
          dataField={"startDate"}
          dataType={"datetime"}
          customizeText={c => c.valueText}
          format={"dd/MM/yyyy HH:mm:ss"}
          defaultSortOrder="desc"
        />
        <Column
          caption={i18n()._(t`Hasta`)}
          dataField={"endDate"}
          dataType={"datetime"}
          customizeText={c => c.valueText}
          format={"dd/MM/yyyy HH:mm:ss"}
        />
        <Column
          caption={i18n()._(t`Duración (mins)`)}
          dataType="number"
          calculateCellValue={c => calculateDuration(c)}
        />
        <Column
          caption={i18n()._(t`Reconocida`)}
          dataField={"recognized"}
          dataType={"boolean"}
          trueText={i18n()._(t`Reconocida`)}
          falseText={i18n()._(t`No reconocida`)}
        />
        <Column caption={i18n()._(t`Observación`)} dataField={"observation"} />
        <Column caption={i18n()._(t`Raíz`)} dataField={"root"} />
        <Column caption={i18n()._(t`Camino`)} dataField={"path"} />
        <Column
          caption={i18n()._(t`Motivo MTTO`)}
          dataType="string"
          dataField={"maintenanceReason"}
        />
        <Column
          caption={i18n()._(t`T. TPM + Prod`)}
          dataType="number"
          dataField={"declaringTime"}
        />
        <Column
          caption={i18n()._(t`T. Espera`)}
          dataType="number"
          dataField={"waitingTime"}
        />
        <Column
          caption={i18n()._(t`T. Mantenimiento`)}
          dataType="number"
          dataField={"maintenanceTime"}
        />
        <Column
          caption={i18n()._(t`Actualizado por`)}
          dataField={"updatedBy"}
          defaultVisible={false}
        />
        <Column
          caption={i18n()._(t`Ultima actualización `)}
          dataField={"updateDate"}
          dataType={"datetime"}
          customizeText={c => c.valueText}
          defaultVisible={false}
          format={"yyyy-MM-ddTHH:mm:ss"}
        />
        <Column
          visible={
            user.roleID === UserRoles.SUPERVISOR ||
            user.roleID === UserRoles.OPERATOR
          }
          fixed
          fixedPosition={"right"}
          alignment={"center"}
          cellComponent={r => (
            <GridOptions
              onClick={(a, v) => recognizeClick(a, v)}
              role={user.roleID}
              row={r}
            />
          )}
        />
        <Column
          visible={user.roleID === UserRoles.SUPERVISOR}
          fixed
          fixedPosition={"right"}
          alignment={"center"}
          cellComponent={rowInfo => (
            <SplitOptions
              onClick={stop => splitStop(stop)}
              role={user.roleID}
              row={rowInfo}
            />
          )}
        />
      </DataGrid>
    </>
  );
};

const customToolbar = ({ component, toolbarOptions }) => {
  toolbarOptions.items.unshift({
    location: "after",
    widget: "dxButton",
    options: {
      icon: "refresh",
      onClick: function() {
        component.refresh();
      }
    }
  });
};

const calculateDuration = ({ startDate, endDate }) => {
  let durationInSeconds =
    endDate !== null && moment(endDate).isValid()
      ? moment(endDate).diff(moment(startDate), "seconds")
      : moment().diff(moment(startDate), "seconds");

  // Convierto segundos a minutos
  return (durationInSeconds / 60).toFixed(2);
};

const url = "/api/stops";

const dataSource = createStore({
  key: ["order", "lineID", "stopID"],
  loadUrl: `${url}/get`,
  insertUrl: `${url}/post`,
  updateUrl: `${url}/put`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: (_, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const GridOptions = ({ row, role, onClick }) => {
  if (row.data.recognized) {
    if (role === UserRoles.SUPERVISOR || role === UserRoles.OPERATOR) {
      return (
        <Button
          onClick={() => onClick("modify", row.data)}
          size="mini"
          color="green"
        >
          Modificar
        </Button>
      );
    } else {
      return null;
    }
  }

  if (role === UserRoles.SUPERVISOR || role === UserRoles.OPERATOR)
    return (
      <Button
        onClick={() => onClick("recognize", row.data)}
        size="mini"
        color="red"
      >
        <Trans>Reconocer</Trans>
      </Button>
    );
  return null;
};

const SplitOptions = ({ row, role, onClick }) => {
  return role === UserRoles.SUPERVISOR || role === UserRoles.OPERATOR ? (
    <Button onClick={() => onClick(row.data)} size="mini" color="blue">
      <Trans>Split</Trans>
    </Button>
  ) : null;
};
//#endregion

const mapStateToProps = state => ({
  auth: state.authentication,
  terminal: state.terminal
});

export default connect(mapStateToProps)(StopsPage);
