import { useSelector } from "react-redux";
import { getShiftNameByStartDate } from "../../../utils/ShiftHelper";

export const useShiftIndexes = () => {
  const { start } = useSelector(state => state.indicators.navigation) || {};
  const { oee, utilization, throughput, acceptance, mtbf, mttr } =
    useSelector(state => state.indicators.historyIndexes) || {};

  return {
    shift: getShiftNameByStartDate(start),
    oeeShift: oee ? (oee * 100).toFixed(0) : 0,
    throughputShift: throughput ? (throughput * 100).toFixed(0) : 0,
    acceptanceShift: acceptance ? (acceptance * 100).toFixed(0) : 0,
    utilizationShift: utilization ? (utilization * 100).toFixed(0) : 0,
    mtbfShift: mtbf,
    mttrShift: mttr
  };
};
