import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Form, Segment, Button, Header, Modal } from "semantic-ui-react";
import {
  Column,
  HeaderFilter,
  DataGrid,
  FilterRow,
  GroupPanel,
  Scrolling,
  Export,
  ColumnChooser
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { UserRoles } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { Switch } from "devextreme-react/switch";
import { UserSign } from "../../features/login/UserSign";
import {
  recalculateAllOrders,
  adjustDiscountTimeAsync,
  validateOrderAsync
} from "./service.js";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

const Orders = props => {
  return (
    <>
      <Segment padded="very" style={{ margin: 20 }}>
        <Header as="h2">
          <Trans>Índices por Silo/Secuencia</Trans>
          <Header.Subheader>
            <Trans>
              Visualiza los registros de silo/secuencia en el sistema
            </Trans>
          </Header.Subheader>
        </Header>

        <OrdersGrid user={props.auth.user} />
      </Segment>
    </>
  );
};

const url = "/api/oeeindex";

const dataSource = createStore({
  key: "order",
  loadUrl: `${url}/get`,
  updateUrl: `${url}/put`,
  onBeforeSend: (_, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

class OrdersGrid extends Component {
  refGrid = React.createRef();

  toolbarPreparing({ component, toolbarOptions }) {
    toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: () => component.refresh()
      }
    });
  }

  refresh() {
    this.refGrid.current.instance.refresh();
  }

  render() {
    return (
      <>
        <DataGrid
          key={"order"}
          ref={this.refGrid}
          columnAutoWidth={true}
          dataSource={dataSource}
          showBorders={true}
          style={{ maxHeight: 500, fontSize: "1rem" }}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          remoteOperations={true}
          onToolbarPreparing={e => this.toolbarPreparing(e)}
        >
          <Export
            enabled
            allowExportSelectedData={false}
            fileName="OEE_ORDENES"
          />
          <ColumnChooser enabled />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <GroupPanel visible={true} />
          <Scrolling mode={"virtual"} />
          <Column
            dataField={"order"}
            caption={i18n()._(t`Silo`)}
            calculateCellValue={d => d.order.split("-")[0]}
            allowEditing={false}
            key
          />
          <Column
            dataField={"order"}
            caption={i18n()._(t`Secuencia`)}
            calculateCellValue={d => d.order.split("-")[1]}
            allowEditing={false}
            key
          />
          <Column
            allowEditing={false}
            dataField={"lineID"}
            caption={i18n()._(t`Línea`)}
            width={70}
          />
          <Column
            allowEditing={false}
            dataField={"lot"}
            caption={i18n()._(t`Lote`)}
            width={70}
          />
          <Column
            allowEditing={false}
            dataField={"validation"}
            caption={i18n()._(t`Validación`)}
            alignment={"center"}
            cellComponent={row => (
              <Switch
                disabled={
                  this.props.user.roleID !== UserRoles.SUPERVISOR &&
                  this.props.user.roleID !== UserRoles.OPERATOR
                }
                switchedOnText="SI"
                switchedOffText="NO"
                defaultValue={row.data.validation}
                onValueChanged={evt =>
                  validateOrderAsync(
                    row.data.order,
                    evt.value,
                    this.props.user.userID,
                    r => {
                      let text = evt.value
                        ? i18n()._(t`Orden validada con éxito`)
                        : i18n()._(t`Orden desvalidada con éxito`);

                      notify(text, "success", 1500);
                      row.data.validation = evt.value;
                    },
                    r => {
                      notify(r, "error", 5000);
                    }
                  )
                }
              />
            )}
          />
          <Column
            allowEditing={false}
            dataField={"productCode"}
            caption={i18n()._(t`Producto`)}
          />
          <Column
            allowEditing={false}
            dataField={"productName"}
            caption={i18n()._(t`Nombre`)}
          />
          <Column
            allowEditing={false}
            dataField={"startDate"}
            dataType={"datetime"}
            customizeText={c => c.valueText}
            format={"dd/MM/yyyy HH:mm:ss"}
            caption={i18n()._(t`F. Inicio`)}
          />
          <Column
            allowEditing={false}
            dataField={"endDate"}
            dataType={"datetime"}
            customizeText={c => c.valueText}
            format={"dd/MM/yyyy HH:mm:ss"}
            caption={i18n()._(t`F. Fin`)}
          />
          <Column
            allowEditing={false}
            dataField={"desiredProductionQuantity"}
            caption={i18n()._(t`Cant. Prod. Deseada`)}
          />
          <Column
            allowEditing={false}
            dataField={"productionQuantity"}
            caption={i18n()._(t`Cant. Producción`)}
          />
          <Column
            allowEditing={false}
            dataField={"desiredProductionSpeed"}
            caption={i18n()._(t`Vel. Prod. Deseada`)}
          />
          <Column
            allowEditing={false}
            dataField={"productionSpeed"}
            caption={i18n()._(t`Vel. Producción`)}
          />
          <Column
            allowEditing={false}
            dataField={"throughput"}
            caption={i18n()._(t`Throughput`)}
            format="#0.###"
          />
          <Column
            allowEditing={false}
            dataField={"acceptance"}
            caption={i18n()._(t`Acceptance`)}
          />
          <Column
            allowEditing={false}
            dataField={"oee"}
            caption={i18n()._(t`OEE`)}
            format="#0.#"
          />
          <Column
            allowEditing={false}
            dataField={"runtime"}
            caption={i18n()._(t`Runtime`)}
          />
          <Column
            allowEditing={false}
            dataField={"utilization"}
            caption={i18n()._(t`Utilization`)}
            format="#0.###"
          />
          <Column
            allowEditing={false}
            dataField={"staffedTime"}
            caption={i18n()._(t`Staffed Time`)}
          />
          <Column
            allowEditing={false}
            dataField={"discountTime"}
            caption={i18n()._(t`Discount Time`)}
          />
          <Column
            allowEditing={false}
            dataField={"adjustedDiscountTime"}
            caption={i18n()._(t`Discount Time Ajustado`)}
            dataType="number"
          />
          <Column
            allowEditing={false}
            dataField={"staffedTimeCorrect"}
            caption={i18n()._(t`Staffed Time Corr.`)}
          />
          <Column
            allowEditing={false}
            dataField={"performance"}
            caption={i18n()._(t`Performance`)}
            format="#0.####"
          />
          <Column
            allowEditing={false}
            dataField={"updatedBy"}
            caption={i18n()._(t`Actualizado por`)}
            defaultVisible={false}
          />
          <Column
            allowEditing={false}
            dataField={"updateDate"}
            dataType={"datetime"}
            customizeText={c => c.valueText}
            format={"dd/MM/yyyy HH:mm:ss"}
            defaultVisible={false}
            caption={i18n()._(t`Ultima actualización `)}
          />
          <Column
            allowEditing={false}
            dataField={"createdBy"}
            caption={i18n()._(t`Creado por`)}
            defaultVisible={false}
          />
          <Column
            allowEditing={false}
            dataField={"createDate"}
            dataType={"datetime"}
            customizeText={c => c.valueText}
            format={"dd/MM/yyyy HH:mm:ss"}
            defaultVisible={false}
            caption={i18n()._(t`Fecha creación `)}
            sortOrder="desc"
          />
          <Column
            allowEditing={false}
            dataField={"status"}
            caption={i18n()._(t`Estado`)}
            defaultVisible={false}
          />
          <Column
            allowEditing={false}
            visible={this.props.user.roleID === UserRoles.SUPERVISOR}
            fixed
            fixedPosition={"right"}
            alignment={"center"}
            cellComponent={row => (
              <AdjustModal
                row={row}
                username={this.props.user.userID}
                gridRefresh={() => this.refresh()}
              />
            )}
          />
        </DataGrid>
        <RecalculateModal username={this.props.user.userID} />
      </>
    );
  }
}

const RecalculateModal = ({ username }) => {
  const initialData = {
    open: false,
    username: username,
    password: "",
    reason: ""
  };

  const [data, setData] = useState(initialData);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "25px 0 0 0"
        }}
      >
        <div>
          <Button onClick={() => setData({ ...data, open: true })} color="red">
            <Trans>Recalcular todas las ordenes</Trans>
          </Button>
        </div>
      </div>
      <Modal
        size={"tiny"}
        dimmer={"inverted"}
        open={data.open}
        onClose={() => setData({ ...initialData, open: false })}
      >
        <Modal.Header as="h2">
          <Trans>Recalcular todas las ordenes</Trans>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <UserSign
              data={data}
              setData={setData}
              user={username}
              reason={false}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={i18n()._(t`Cancelar`)}
            onClick={() => setData({ ...initialData, open: false })}
          />
          <Button
            content={i18n()._(t`Aplicar`)}
            onClick={() => {
              notify("Esto podría demorar un momento...", "info");
              recalculateAllOrders(data.username, data.password)
                .then(orders =>
                  notify(`Success: ${orders} orders recalculated`, "success")
                )
                .catch(err => {
                  notify(err, "error", 5000);
                  console.error(err);
                });
              setData({ ...data, open: false });
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const AdjustModal = ({ row, username, gridRefresh }) => {
  const initialData = {
    open: false,
    adjustedDiscountTime: row.data.adjustedDiscountTime
      ? row.data.adjustedDiscountTime
      : 0,
    username: username,
    password: "",
    reason: ""
  };

  const [data, setData] = useState(initialData);

  return (
    <>
      <Button
        onClick={() => setData({ ...data, open: true })}
        size="mini"
        color="blue"
        disabled={row.data.endDate ? false : true}
      >
        <Trans>Ajustar</Trans>
      </Button>
      <Modal
        size={"tiny"}
        dimmer={"inverted"}
        open={data.open}
        onClose={() => setData({ ...initialData, open: false })}
      >
        <Modal.Header as="h2">
          <Trans>Ajustar tiempo de descuento</Trans>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Minutos:</label>
              <input
                value={data.adjustedDiscountTime}
                onChange={e =>
                  setData({ ...data, adjustedDiscountTime: e.target.value })
                }
                type="number"
              />
            </Form.Field>
            <UserSign
              data={data}
              setData={setData}
              user={username}
              reason={false}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={i18n()._(t`Cancelar`)}
            onClick={() => setData({ ...initialData, open: false })}
          />
          <Button
            content={i18n()._(t`Aplicar`)}
            onClick={() => {
              adjustDiscountTimeAsync(
                row.data.order,
                data.adjustedDiscountTime,
                data.username,
                data.password,
                () => {
                  notify(
                    i18n()._(t`Silo/Secuencia ${row.data.order} ajustada`),
                    "success",
                    1500
                  );
                  row.data.adjustedDiscountTime = data.adjustedDiscountTime;
                  gridRefresh();
                  setData({ ...data, open: false });
                },
                r => notify(r, "error", 5000)
              );
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication
  };
};

export default connect(mapStateToProps)(Orders);
