import React, { PureComponent } from "react";
import { Bullet } from "devextreme-react/bullet";
import { Column, DataGrid, Scrolling } from "devextreme-react/data-grid";
import {
  getStopsAndOrdersInRange,
  getStopsCurrentOrder,
  getParetoFromStopsInRange
} from "./service";
import { Segment } from "semantic-ui-react";
import { t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

/**
 * Componente para visualizar información de paradas en forma de pareto.
 * Si no se especifica comienzo y fin, se utiliza la información actual
 * de la linea asignada.
 */
class ParetoStops extends PureComponent {
  state = { stops: [], loading: false };

  //Update state cuando cambian las props.
  static getDerivedStateFromProps(nextProps, prevState) {
    let { line, lastUpdate, startDate, endDate, limit } = nextProps;
    return { line, lastUpdate, startDate, endDate, limit };
  }

  componentDidMount() {
    this.loadStopsData();
  }

  componentDidUpdate(prevProps, prevState) {
    //Si hay cambios en los filtros, actualiza
    if (
      prevState.line !== this.state.line ||
      prevState.lastUpdate !== this.state.lastUpdate ||
      prevState.limit !== this.state.limit ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.loadStopsData();
    }
  }

  loadStopsData() {
    let { line, startDate, endDate } = this.state;

    //loading
    this.setState({ loading: true });
    if (startDate && endDate) {
      getStopsAndOrdersInRange(line, startDate, endDate, false)
        .then(getParetoFromStopsInRange)
        .then(stops => this.setState({ stops: stops, loading: false }))
        .catch(() => this.setState({ stops: [], loading: false }));
    } else {
      getStopsCurrentOrder(line)
        .then(getParetoFromStopsInRange)
        .then(stops => {
          this.setState({ stops: stops, loading: false });
        })
        .catch(() => this.setState({ stops: [], loading: false }));
    }
  }

  render() {
    let { stops, limit } = this.state;
    let maxHeight = 500;

    //Si se define limite, trunco pareto.
    if (typeof limit == "number") {
      stops = stops.slice(0, limit);
      maxHeight = 170;
    }

    return (
      <>
        <Segment loading={this.state.loading} basic>
          <DataGrid
            dataSource={stops}
            showBorders={false}
            style={{ maxHeight: maxHeight, fontSize: "1rem" }}
            allowColumnResizing={true}
            summary={{
              totalItems: [
                {
                  column: "duration",
                  summaryType: "sum",
                  displayFormat: "Total: {0}min",
                  valueFormat: "fixedPoint"
                }
              ]
            }}
          >
            <Scrolling mode={"virtual"} />
            <Column
              dataField={"reason"}
              minWidth={120}
              caption={i18n()._(t`Motivo`)}
            />
            <Column
              dataField={"duration"}
              customizeText={t => t.value.toFixed(1).replace(/[.,]00$/, "")}
              caption={i18n()._(t`Minutos`)}
            />
            <Column dataField={"quantity"} caption={i18n()._(t`Cantidad`)} />
            <Column
              dataField={"percent"}
              caption={"%"}
              customizeText={t => t.value.toFixed(1).replace(/[.,]00$/, "")}
              alignment={"right"}
            />
            <Column
              dataField={"percent"}
              caption={i18n()._(t`Grafico`)}
              minWidth={100}
              allowResizing={false}
              alignment={"center"}
              cssClass={"bullet pareto"}
              cellComponent={r => (
                <ProgressBarPareto
                  percent={r.data.percent}
                  color={r.data.color}
                  useColors={this.props.useColors}
                />
              )}
            />
          </DataGrid>
        </Segment>
      </>
    );
  }
}

const ProgressBarPareto = ({ percent, color, useColors }) => {
  let bulletColor = "#2185d0";
  if (useColors) {
    bulletColor = color ? color : "#bf3333";
  }

  return (
    <Bullet
      showTarget={false}
      margin={{ top: 0, left: 0, right: 0 }}
      showZeroLevel={false}
      value={percent}
      color={bulletColor}
      width={"100%"}
      startScaleValue={0}
      endScaleValue={100}
      tooltip={{ enabled: false }}
    />
  );
};

export const Pareto = { Stops: ParetoStops };
