import React, { useState, useEffect } from "react";
import { I18n } from "@lingui/react";
import { useSelector } from "react-redux";

//Utilidad parar traducir strings
export const i18n = () => {
  return window.i18n;
};

export const ConnectProvider = props => {
  //Todo: mover logica de cambio de lenguage a otro sector dentro del context de routing
  // Asi se puede utilizar redirect y la carga es rapida.
  const selectedLanguage = useSelector(state => state.i18n.language);
  const [prevLanguage, setPrevLanguage] = useState(selectedLanguage);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    if (prevLanguage !== selectedLanguage) {
      setPrevLanguage(selectedLanguage);
      setShouldRefresh(true);
    }
  }, [selectedLanguage]);

  //refresh on lang change
  if (shouldRefresh) {
    window.location.reload();
  }

  const exposeI18n = ({ i18n }) => {
    window.i18n = i18n;
    return null;
  };

  //Lingui provider
  return (
    <>
      <I18n>{exposeI18n}</I18n>
      {props.children}
    </>
  );
};
