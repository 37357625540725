import { useEffect } from "react";
import { connect } from "react-redux";
import { useInterval } from "../features/indicators/service";
import { loginActions } from "../features/login/actions";
import { authHeader } from "../utils/AuthHeader";
import { t } from "@lingui/macro";
import { i18n } from "../features/i18n/components/ConnectProvider";

/// Interval in minutes
const JWT_VALIDATION_INTERVAL = 1;

const mapStateToProps = state => ({
  modules: state.modules.registered,
  user: state.authentication.user
});

/// Checks wether the JWT is still valid
export const JwtValidator = connect(mapStateToProps)(state => {
  useEffect(() => {
    validateToken(state.user, false);
  }, []);

  useInterval(() => {
    validateToken(state.user, true);
  }, JWT_VALIDATION_INTERVAL * 60000);

  return null;
});

const validateToken = (user, notify) => {
  if (!user) return;

  fetch(`/api/users/isvalid`, {
    headers: getHeaders(),
    method: "POST"
  }).then(r => {
    if (r.status === 401) {
      if (notify) alert(i18n()._(t`Sesión expirada, vuelva a ingresar`));
      loginActions.logout();
    }
  });
};

function getHeaders() {
  //Common values
  let headers = authHeader();
  headers["Content-Type"] = "application/x-www-form-urlencoded";

  return headers;
}
