import React, { Component } from "react";
import { Segment, Header } from "semantic-ui-react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { Column, DataGrid, MasterDetail } from "devextreme-react/data-grid";

export class OpcServersDiagnostic extends Component {
  state = { servers: [], tags: [] };
  //Api consumer store
  dataSource = createStore({
    loadUrl: `/api/diagnostics/opcservers`,
    key: "opcServerID",
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
    }
  });

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.dataSource
      .load()
      .then(data => ({
        tags: data,
        servers: this.removeDuplicates(data, "opcServerID")
      }))
      .then(({ servers, tags }) => this.setState({ servers, tags }));
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  contentReady(e) {
    if (!e.component.getSelectedRowKeys().length) {
      e.component.selectRowsByIndexes(0);
    }
  }
  selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  toolbarPreparing({ component, toolbarOptions }) {
    toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: () => {
          this.loadData();
        }
      }
    });
  }

  //where the magic happens
  render() {
    let { servers, tags } = this.state;

    return (
      <Segment padded="very">
        <Header
          as="h2"
          content="OPC Servers Status"
          subheader="Showing current tags and status."
        />
        <DataGrid
          id={"grid-container"}
          dataSource={servers}
          keyExpr={"opcServerID"}
          onSelectionChanged={this.selectionChanged}
          onToolbarPreparing={e => this.toolbarPreparing(e)}
          onContentReady={this.contentReady}
          showBorders={true}
          selection={{ mode: "single" }}
        >
          <Column dataField={"opcServerID"} caption={"ID"} />
          <Column dataField={"vendorInfo"} caption={"Vendor info"} />
          <Column dataField={"productVersion"} caption={"Product Version"} />
          <Column
            dataField={"startTime"}
            caption={"Start Time"}
            dataType={"datetime"}
          />
          <Column
            dataField={"lastUpdateTime"}
            caption={"Last Update"}
            dataType={"datetime"}
          />
          <Column dataField={"serverState"} caption={"Server Status"} />
          <Column dataField={"statusInfo"} caption={"Information"} />
          <MasterDetail
            render={({ data }) => (
              <DetailTemplate dataSource={tags} data={data} />
            )}
          />
        </DataGrid>
      </Segment>
    );
  }
}

const filterTags = (datasource, key) => {
  return {
    store: datasource,
    filter: ["opcServerID", "=", key]
  };
};

const DetailTemplate = ({ data, dataSource }) => {
  return (
    <DataGrid
      id={"grid-container"}
      dataSource={filterTags(dataSource, data.opcServerID)}
      showBorders={true}
      columnAutoWidth={true}
    >
      <Column dataField={"tagName"} caption={"Name"} />
      <Column dataField={"url"} caption={"URL"} />
      <Column dataField={"goodQuality"} caption={"Good Quality?"} />
      <Column dataField={"data"} caption={"Data"} />
    </DataGrid>
  );
};
