import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Header } from "semantic-ui-react";
import {
  Column,
  DataGrid,
  FormItem,
  FilterRow,
  StringLengthRule,
  RangeRule,
  RequiredRule,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing
} from "devextreme-react/data-grid";
import { UserRoles } from "../../utils/Consts";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { authHeader } from "../../utils/AuthHeader";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";

export default class ProductsConfig extends Component {
  render = () => (
    <Segment basic padded>
      <Header as="h2">
        <Trans>Configuración de Productos</Trans>
        <Header.Subheader>
          <Trans>Actualiza la información de los productos del sistema.</Trans>
        </Header.Subheader>
      </Header>
      <ProductsGrid />
    </Segment>
  );
}

ProductsConfig.getMenuItem = () => <Trans>Productos</Trans>;
ProductsConfig.getPermissions = userLevel =>
  userLevel === UserRoles.SUPERVISOR || userLevel === UserRoles.ADMINISTRATOR;

const url = "/api/products";

const dataSource = createStore({
  key: "code",
  loadUrl: `${url}/get`,
  insertUrl: `${url}/post`,
  updateUrl: `${url}/put`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = authHeader();
  }
});

const mapStateToProps = state => {
  return {
    user: state.authentication.user
  };
};

const ProductsGrid = connect(mapStateToProps)(({ user }) => (
  <DataGrid
    key={"code"}
    dataSource={dataSource}
    showBorders={true}
    style={{ maxHeight: 500, fontSize: "1rem" }}
    allowColumnReordering={true}
    allowColumnResizing={true}
    rowAlternationEnabled={true}
    remoteOperations={true}
    onEditorPreparing={e => {
      //Key insertAllow editNotAllow
      if (e.parentType === "dataRow" && e.dataField === "code")
        e.editorOptions.readOnly = !e.row.inserted;
    }}
  >
    <FilterRow visible={true} />
    <HeaderFilter visible={false} />
    <GroupPanel visible={true} />
    <Scrolling mode={"virtual"} />
    <Editing
      mode={"form"}
      allowAdding={user.roleID === UserRoles.SUPERVISOR}
      allowDeleting={user.roleID === UserRoles.SUPERVISOR}
      allowUpdating={user.roleID === UserRoles.SUPERVISOR}
      useIcons={true}
    />
    <Column dataField={"code"} caption={i18n()._(t`Código`)} key>
      <RequiredRule type={"required"} />
      <StringLengthRule max={18} ignoreEmptyValue={false} />
    </Column>
    <Column dataField={"name"} caption={i18n()._(t`Nombre`)}>
      <RequiredRule
        type={"required"}
        message={i18n()._(t`Debe especificar un nombre para el producto`)}
      />
      <StringLengthRule
        max={40}
        ignoreEmptyValue={false}
        message={i18n()._(t`Máximo 40 caracteres`)}
      />
    </Column>
    <Column
      dataField={"wrappersPerPackage"}
      caption={i18n()._(t`Paquetes por bulto`)}
    >
      <RequiredRule
        type={"required"}
        message={i18n()._(t`Debe especificar un factor para el producto`)}
      />
      <RangeRule
        min={1}
        max={2147438647}
        ignoreEmptyValue={false}
        message={i18n()._(t`El factor debe ser un número mayor o igual a 1`)}
      />
    </Column>
    <Column
      dataField={"unitsPerWrapper"}
      caption={i18n()._(t`Unidades por paquete`)}
    >
      <RequiredRule
        type={"required"}
        message={i18n()._(t`Debe especificar un factor para el producto`)}
      />
      <RangeRule
        min={1}
        max={2147438647}
        ignoreEmptyValue={false}
        message={i18n()._(t`El factor debe ser un número mayor o igual a 1`)}
      />
    </Column>
    <Column dataField={"unit"} caption={i18n()._(t`Unidad`)}>
      <StringLengthRule max={10} message={i18n()._(t`Máximo 10 caracteres`)} />
    </Column>
    <Column dataField={"performance"} caption={i18n()._(t`Rendimiento`)}>
      <RangeRule
        min={0}
        max={1}
        ignoreEmptyValue={true}
        message={i18n()._(t`El rendimiento debe ser un numero entre 0 y 1`)}
      />
    </Column>
    <Column dataField={"updatedBy"} caption={i18n()._(t`Actualizado por`)}>
      <FormItem visible={false} />
    </Column>
    <Column
      dataField={"updateDate"}
      dataType={"date"}
      format={"dd/MM/yyyy HH:mm:ss"}
      caption={i18n()._(t`Ultima actualización `)}
    >
      <FormItem visible={false} />
    </Column>
  </DataGrid>
));
