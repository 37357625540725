export default {
  lg: [
    { w: 3, h: 9, x: 0, y: 0, i: "oee", moved: false, static: false },
    { w: 6, h: 4, x: 3, y: 5, i: "progress", moved: false, static: false },
    { w: 4, h: 7, x: 0, y: 9, i: "order", moved: false, static: false },
    { w: 3, h: 9, x: 9, y: 0, i: "stopsmanager", moved: false, static: false },
    { w: 8, h: 7, x: 4, y: 9, i: "stops", moved: false, static: false },
    { w: 2, h: 5, x: 3, y: 0, i: "utilization", moved: false, static: false },
    { w: 2, h: 5, x: 5, y: 0, i: "throughput", moved: false, static: false },
    { w: 2, h: 5, x: 7, y: 0, i: "stoptime", moved: false, static: false },
    { w: 2, h: 5, x: 7, y: 0, i: "acceptance", moved: false, static: false }
  ],
  md: [
    { w: 3, h: 8, x: 0, y: 0, i: "oee", moved: false, static: false },
    { w: 6, h: 3, x: 3, y: 5, i: "progress", moved: false, static: false },
    { w: 4, h: 8, x: 0, y: 8, i: "order", moved: false, static: false },
    { w: 3, h: 8, x: 9, y: 0, i: "stopsmanager", moved: false, static: false },
    { w: 8, h: 8, x: 4, y: 8, i: "stops", moved: false, static: false },
    { w: 2, h: 5, x: 3, y: 0, i: "utilization", moved: false, static: false },
    { w: 2, h: 5, x: 5, y: 0, i: "throughput", moved: false, static: false },
    { w: 2, h: 5, x: 7, y: 0, i: "stoptime", moved: false, static: false },
    { w: 2, h: 5, x: 7, y: 0, i: "acceptance", moved: false, static: false }
  ],
  sm: [
    { w: 3, h: 8, x: 0, y: 0, i: "oee", moved: false, static: false },
    { w: 6, h: 3, x: 0, y: 13, i: "progress", moved: false, static: false },
    { w: 6, h: 11, x: 0, y: 16, i: "order", moved: false, static: false },
    { w: 3, h: 8, x: 3, y: 0, i: "stopsmanager", moved: false, static: false },
    { w: 6, h: 8, x: 0, y: 27, i: "stops", moved: false, static: false },
    { w: 2, h: 5, x: 0, y: 8, i: "utilization", moved: false, static: false },
    { w: 2, h: 5, x: 2, y: 8, i: "throughput", moved: false, static: false },
    { w: 2, h: 5, x: 4, y: 8, i: "stoptime", moved: false, static: false },
    { w: 2, h: 5, x: 4, y: 8, i: "acceptance", moved: false, static: false }
  ]
};
