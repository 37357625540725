import { authHeader } from "../utils/AuthHeader";

export default class TelegramUser {
  userID = undefined;
  token = undefined;
  chatId = undefined;
}

// Crea un usuario de telegram y notifica por correo al usuario
export function postTelegramUser(userID, onSuccess, onError) {
  return fetch("/api/telegramUsers", {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify(userID)
  })
    .then(response => {
      console.log("respons", response);
      if (response.ok) onSuccess();
      else onError();
    })
    .catch(error => {
      onError();
    });
}

// Crea usuarios de telegram a partir de usuarios de sistema que tengan correo y no tengan usuario de telegram
export function postAllTelegramUsers(onSuccess, onError) {
  return fetch("/api/telegramUsers/all", {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    },
    method: "POST"
  })
    .then(response => {
      console.log("respons", response);
      if (response.ok) onSuccess();
      else onError();
    })
    .catch(error => {
      onError();
    });
}
