import { devToolsEnhancer } from "redux-devtools-extension";
import { createStore } from "redux";
import { combineReducers } from "redux";

//Reducers
import modules from "../features/modules-manager/reducers";
import authentication from "../features/login/reducers";
import i18n from "../features/i18n/reducers";
import terminal from "../features/terminal/reducers";
import indicators from "../features/indicators/reducers";
import navigation from "../features/navigation/reducers";

var rootReducer = combineReducers({
  modules,
  authentication,
  i18n,
  terminal,
  indicators,
  navigation
});

export default createStore(
  rootReducer,
  devToolsEnhancer()
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
);
