import React, { Fragment } from "react";
import ReactMinimalPieChart from "react-minimal-pie-chart";
import { MttoStatus } from "../../../utils/Consts";
import moment from "moment";

/**
 *
 * @param {object=} stopData Objeto con información del inicio de parada { startDate }
 * @param {object=} callData Objeto con información sobre la llamada a mantenimiento
 * { status, declarationDate, callDate, startDate, endDate}
 */
const StopIndicator = ({ stopData, callData, style, showTitle = true }) => {
  const {
    maxValue,
    humanDuration,
    declaringTime,
    waitingTime,
    maintenanceTime
  } = calculateCurrentMttoTime(stopData, callData);

  const textVisibility = showTitle ? "visible" : "hidden";
  const valueY = showTitle ? 60 : 50;
  let i = 0;

  return (
    <ReactMinimalPieChart
      style={style}
      totalValue={maxValue}
      data={[
        { color: "#98423c", key: 1, value: declaringTime },
        { color: "#EC5453", key: 2, value: waitingTime },
        { color: "#FFA726", key: 3, value: maintenanceTime }
      ]}
      {...defaultPieChartProps}
      label={({ color, data, style, key, y, dataIndex, ...props }) => (
        <Fragment key={i++}>
          <text
            key={1}
            y={35}
            visibility={textVisibility}
            style={{
              fonFamily: "sans-serif",
              fontSize: "14px"
            }}
            fill={"#EC5453"}
            dominantBaseline="middle"
            {...props}
          >
            Stop
          </text>
          <text
            key={2}
            y={valueY}
            style={style}
            fill={"#EC5453"}
            dominantBaseline="middle"
            {...props}
          >
            {humanDuration}
          </text>
        </Fragment>
      )}
    />
  );
};

//#region Calculations

/**
 *
 * @param {object=} stopData Objeto con información del inicio de parada { startDate }
 * @param {object=} callData Objeto con información sobre la llamada a mantenimiento
 * { status, declarationDate, callDate, startDate, endDate}
 */
function calculateCurrentMttoTime(stopData, callData) {
  let { startDate } = stopData || {};
  let declaringTime = 0,
    waitingTime = 0,
    maintenanceTime = 0,
    maxValue = 0;
  let humanDuration = "";

  //Line sttoped
  if (stopData) {
    let totalDuration = moment.duration(moment().diff(moment(startDate)));
    let declarationDuration = totalDuration;
    let waitingDuration = moment.duration();
    let maintenanceDuration = moment.duration();

    //Mtto delcarado, se descompone duración
    if (callData) {
      //Uso duración ya registrada
      declarationDuration = moment.duration(
        moment(callData.declarationDate).diff(moment(callData.callDate))
      );

      //Uso waiting duration registrada o bien comparo contra actualidad
      if (callData.status === MttoStatus.Waiting) {
        waitingDuration = moment.duration(
          moment().diff(moment(callData.callDate))
        );
      } else {
        waitingDuration = moment.duration(
          moment(callData.callDate).diff(moment(callData.startDate))
        );
      }

      //Uso maintenance duration  registrada o bien comparo contra actualidad
      if (callData.status === MttoStatus.Started) {
        maintenanceDuration = moment.duration(
          moment().diff(moment(callData.startDate))
        );
      } else if (callData.status === MttoStatus.Finished) {
        maintenanceDuration = moment.duration(
          moment(callData.endDate).diff(moment(callData.startDate))
        );
      }
    }

    //Magnitud Segundos
    if (totalDuration.asSeconds() < 60) {
      humanDuration = `${Math.round(totalDuration.asSeconds())}s`;
      declaringTime = Math.abs(declarationDuration.asSeconds());
      waitingTime = Math.abs(waitingDuration.asSeconds());
      maintenanceTime = Math.abs(maintenanceDuration.asSeconds());
      maxValue = 60;
    }
    //Magnitud Minutos
    else if (totalDuration.asMinutes() < 60) {
      humanDuration = `${Math.round(totalDuration.asMinutes())}m`;
      declaringTime = Math.abs(declarationDuration.asMinutes());
      waitingTime = Math.abs(waitingDuration.asMinutes());
      maintenanceTime = Math.abs(maintenanceDuration.asMinutes());
      maxValue = 60;
    }
    //Magnitud horas
    else if (totalDuration.asHours() < 24) {
      humanDuration = `${Math.round(totalDuration.asHours())}h`;
      declaringTime = Math.abs(declarationDuration.asHours());
      waitingTime = Math.abs(waitingDuration.asHours());
      maintenanceTime = Math.abs(maintenanceDuration.asHours());
      maxValue = 24;
    }
    //Magnitud dias
    else if (totalDuration.asDays() < 99) {
      humanDuration = `${Math.round(totalDuration.asDays())}d`;
      declaringTime = Math.abs(declarationDuration.asDays());
      waitingTime = Math.abs(waitingDuration.asDays());
      maintenanceTime = Math.abs(maintenanceDuration.asDays());
      maxValue = 99;
    }
    //Limit
    else {
      humanDuration = `+99d`;
      declaringTime = 99;
      waitingTime = 0;
      maintenanceTime = 0;
      maxValue = 99;
    }
  }
  return {
    maxValue,
    humanDuration,
    declaringTime,
    waitingTime,
    maintenanceTime
  };
}

//#endregion

//#region Defaut props
const defaultPieChartProps = {
  animate: false,
  animationDuration: 500,
  animationEasing: "ease-out",
  cx: 50,
  cy: 50,
  labelPosition: 0,
  labelStyle: { fontFamily: "sans-serif", fontSize: "25px" },
  background: "#E5E5E5",
  lengthAngle: 360,
  lineWidth: 10,
  paddingAngle: 0,
  radius: 50,
  ratio: 1,
  rounded: false,
  startAngle: -90
};
//#endregion

export default StopIndicator;
