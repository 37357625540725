import "../features/modules-manager/loader";
import React from "react";
import Navigation from "../features/navigation/Navigation";
import Configuration from "../features/configuration/Configuration";
import Login from "../features/login/Login";
import Indicators from "../features/indicators/Indicators";
import Terminal from "../features/terminal/Terminal";
import Setup from "../features/setup/Setup";
import Stops from "../features/stops/Stops";
import Orders from "../features/orders/Orders";
import Overview from "../features/indicators/Overview";
import { Summary } from "../features/stops/Summary";
import { NewSummary } from "../features/stops/NewSummary";
import Log from "../features/log/Log";
import Diagnostics from "../features/diagnostics/Diagnostics";
import OrdersValidate from "../features/orders/OrdersValidate";
import ShiftIndexes from "../features/indexes/ShiftIndexes.jsx";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { connect } from "react-redux";
import { resolveNavigationPages } from "../features/modules-manager/resolver";
import I18nProvider from "../features/i18n/I18nProvider";
import { JwtValidator } from "../services/token-validation-service";
import CacheBuster, { refreshCacheAndReload } from "../utils/CacheBuster";

const DashboardRoute = ({ modules, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps =>
        localStorage.getItem("user") ? (
          <div>
            <header>{<Navigation modules={modules} />}</header>
            <div id="main">
              <Component {...matchProps} />
            </div>
          </div>
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
};

const SetupRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => <Component {...matchProps} />} />
  );
};

const LoginLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => <Component {...matchProps} />} />
  );
};

const App = ({ user, modules }) => {
  const hookNavigationRoutes = resolveNavigationPages(
    user,
    modules
  ).map((e, i) => (
    <DashboardRoute
      exact
      key={i}
      path={e.path}
      component={e.component}
      index={i}
    />
  ));

  return (
    <CacheBuster>
      {({ loading, isLatestVersion }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <>
            <JwtValidator />
            <Router>
              <Switch>
                <I18nProvider>
                  <LoginLayoutRoute
                    exact
                    path="/login"
                    component={() => <Login />}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/"
                    component={Indicators}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/terminal"
                    component={() => <Terminal />}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/paradas/listado"
                    component={() => <Stops />}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/paradas/resumen"
                    component={() => <Summary />}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/paradas/resumen2"
                    component={() => <NewSummary />}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/indexes/order"
                    component={() => <Orders />}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/indexes/shift"
                    component={() => <ShiftIndexes />}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/ordenes/validacion"
                    component={() => <OrdersValidate />}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/resumen"
                    component={Overview}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/logs"
                    component={Log}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/diagnostics"
                    component={Diagnostics}
                  />
                  <DashboardRoute
                    modules={modules}
                    exact
                    path="/configuracion"
                    component={() => <Configuration />}
                  />
                  <SetupRoute exact path="/setup" component={() => <Setup />} />
                  {hookNavigationRoutes}
                </I18nProvider>
              </Switch>
            </Router>
          </>
        );
      }}
    </CacheBuster>
  );
};

const mapStateToProps = state => ({
  modules: state.modules.registered,
  user: state.authentication.user
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
