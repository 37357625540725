import React from "react";
import { Form, Input } from "semantic-ui-react";
import moment from "moment";
import { t } from "@lingui/macro";
import { i18n } from "../i18n/components/ConnectProvider";
import "./styles.css";

export const InputMini = ({ label, value = "", width, ...props }) => (
  <Form.Field width={width}>
    <Input label={label} value={value} size="mini" fluid {...props} />
  </Form.Field>
);

/**
 * Visualización de los datos de la orden. Solo lectura.
 */
export default ({
  productCode = " ",
  productName = " ",
  startDate = " ",
  order = " "
}) => {
  let sDate =
    startDate && startDate.replace(/\s/g, "").length > 0
      ? moment(startDate).format("DD/MM/YYYY HH:mm")
      : "";
  const [silo, sequence] = order.split("-");
  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <InputMini label={i18n()._(t`Producto`)} value={productName} />
          <InputMini label={i18n()._(t`Código`)} value={productCode} />
        </Form.Group>
        <Form.Group>
          <InputMini label={i18n()._(t`Silo`)} value={silo} width={4} />
          <InputMini
            label={i18n()._(t`Secuencia`)}
            value={sequence}
            width={5}
          />
          <InputMini label={i18n()._(t`F. Inicio`)} value={sDate} width={7} />
        </Form.Group>
      </Form>
    </>
  );
};
