import store from "../../app/store";
import { getOEEIndex, getMaintenanceData } from "./service";

export const indicatorsActions = {
  setLine,
  update,
  startWatcher
};

export const indicatorsConstants = {
  INDICATORS_SETLINE: "INDICATORS_SETLINE",
  INDICATORS_UPDATE: "INDICATORS_UPDATE",
  INDICATORS_UPDATE_HISTORY: "INDICATORS_UPDATE_HISTORY",
  INDICATORS_SET_CURRENT_STOP: "INDICATORS_SET_CURRENT_STOP",
  INDICATORS_SET_NAVIGATION: "INDICATORS_SET_NAVIGATION",
  INDICATORS_START_OBSERVER: "INDICATORS_START_OBSERVER"
};

/**
 * Si hubo cambio de linea, dispara la acción.
 */
function setLine(line) {
  let previousLine = store.getState().indicators.line;
  if (previousLine !== line) {
    store.dispatch({
      type: indicatorsConstants.INDICATORS_SETLINE,
      payload: line
    });
    update();
  }
}

function update() {
  let state = store.getState();
  let { line } = state.indicators;

  if (line) {
    getOEEIndex(line)
      .then(indexes =>
        store.dispatch({
          type: indicatorsConstants.INDICATORS_UPDATE,
          payload: indexes
        })
      )
      .catch(error => console.log("Fatal error"));

    getMaintenanceData(line)
      .then(stop =>
        store.dispatch({
          type: indicatorsConstants.INDICATORS_SET_CURRENT_STOP,
          payload: stop
        })
      )
      .catch(error => console.log("Fatal error"));
  }
}

//Actualiza indicadores después del tiempo definido en terminal.
//Los pasos son:
//Primera vez, se suscribe al store indicando que la subscripción está hecha en indicators.prepared.
//Verifica cambios en el store, si ya se tiene información de la terminal, inicia interval, indicando el
//inicio en indicators.watching.
var unsubscribe = null;
function startWatcher() {
  if (store.getState().indicators.watching === false) {
    unsubscribe = store.subscribe(() => {
      let terminal = store.getState().terminal;

      //Get refresh time
      let refreshTime =
        terminal &&
        terminal.globalParameters &&
        terminal.globalParameters.gridRefreshTime
          ? terminal.globalParameters.gridRefreshTime * 1000
          : null;

      if (refreshTime != null) {
        setInterval(() => {
          update();
        }, refreshTime);
        unsubscribe();
      }
    });

    //Indica que ya esta en marcha
    store.dispatch({ type: indicatorsConstants.INDICATORS_START_OBSERVER });
  }
}
